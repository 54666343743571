<template>
  <v-container>
  <loader :isLoading="isLoading"></loader>
  <landing-create :content="content"></landing-create>
  <v-card>
    <v-row>
      <v-col
        cols="12"
        md="12"
        xl="12"
        class="role-section">
        <div style="background-color: rd">
          <role-form :role-model="roleModel" @onSubmit="onSubmit"></role-form>
        </div>
      </v-col>
    </v-row>
  </v-card>
  </v-container>
</template>

<script>
import RoleForm from './RoleForm.vue';
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import services from '../../../services';
import Loader from '../../../components/utilities/Loader.vue';

const { controlPanelRegistry } = services;
const { roleService } = controlPanelRegistry;

export default {
  name: 'RoleCreate',
  components: {
    LandingCreate,
    RoleForm,
    Loader,
  },
  methods: {
    async onSubmit(data) {
      try {
        this.isLoading = true;
        const created = await roleService.create({ role: data });
        console.log(created, 'lmao');
        this.$snackbar.show({
          showClose: Boolean,
          text: 'Successfully created a role!',
          timeout: 4000,
          closeColor: 'white',
          color: 'green',
        });
        this.isLoading = false;
        this.$router.replace({ name: 'role.list' });
      } catch (e) {
        const result = e.response.data;
        this.isLoading = false;

        if (result?.messages?.length) {
          const { message } = result.messages[0];
          this.$snackbar.show({
            showClose: Boolean,
            text: message,
            timeout: 4000,
            closeColor: 'white',
            color: 'red',
          });
        }

        console.log(e.response);
      }
    },
  },
  data() {
    return {
      roleDrawer: false,
      headerTitle: 'Create Role',
      content: {
        pageTitle: 'Create Role',
        enableBackButton: true,
      },
      isLoading: false,
      roleModel: {
        permissions: [],
      },
    };
  },
};
</script>
<style scoped>

.role-form-subheader {
  padding-left: 20px;
}
.role-section{
  padding: 40px;
}
.create-permission-button {
  color: #6E0D1C;
  margin-right: 10px;
  letter-spacing: 0;
}
</style>
