import SidebarStandard from '../../components/sidebars/SidebarStandard.vue';

export default {
  computed: {
    sidebarContent() {
      return {
        title: {
          description: 'Access Management',
          icon: 'mdi-shield-account',
          link: 'user.list',
        },
        items: [
          {
            title: 'Permissions',
            icon: 'mdi-security',
            link: 'permission.list',
          },
          {
            title: 'Roles',
            icon: 'mdi-account-group',
            link: 'role.list',
          },
          // {
          //   title: 'Departments',
          //   icon: 'mdi-office-building',
          //   link: 'DepartmentList',
          // },
          {
            title: 'Users',
            icon: 'mdi-account-plus',
            link: 'user.list',
          },
          // {
          //   title: 'Databases',
          //   icon: 'mdi-database',
          //   link: 'DatabaseList',
          // },
        ],
      };
    },
  },
  components: {
    SidebarStandard,
  },
};
