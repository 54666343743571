<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <case-form
      v-if="caseNumber"
      :isLoading="isLoading"
      :onSubmit="onSubmit"
      :caseNumber="caseNumber"
    ></case-form>
  </v-container>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import CaseForm from './CaseForm.vue';
import services from '../../../services';

const { medicalRegistry } = services;
const { caseService } = medicalRegistry;

export default {
  name: 'case-update',
  components: {
    LandingCreate,
    CaseForm,
  },
  data: () => ({
    isLoading: false,
    content: {
      pageTitle: 'Case Update',
      enableBackButton: true,
    },
    caseNumber: null,
  }),
  async created() {
    const { id } = this.$route.params;
    this.caseNumber = await caseService.getOne({ id });
  },
  methods: {
    async onSubmit(data) {
      this.isLoading = true;
      try {
        await caseService.update({ caseNumber: data });

        this.$snackbar.show({
          showClose: Boolean,
          text: 'Successfully updated Case Number!',
          timeout: 4000,
          closeColor: 'white',
          color: 'green',
        });

        this.$router.push({ name: 'case.list' });
      } catch (e) {
        console.error(e);
      }
      this.isLoading = false;
    },
  },
};
</script>
