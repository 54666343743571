import MutualTransferAgreementService from './mutual-transfer-agreement.service';
import CaseSpecimenTransactionService from './case-specimen-transaction.service';
import SpecimenStateService from './specimen-state.service';
import SpecimenTypeService from './specimen-type.service';
import CaseSpecimenService from './case-specimen.service';
import CaseService from './case.service';

export default {
  biobankRegistry: ({ axios }) => ({
    mutualTransferAgreementService: new MutualTransferAgreementService({ axios }),
    caseSpecimenTransactionService: new CaseSpecimenTransactionService({ axios }),
    specimenStateService: new SpecimenStateService({ axios }),
    specimenTypeService: new SpecimenTypeService({ axios }),
    caseSpecimenService: new CaseSpecimenService({ axios }),
    caseService: new CaseService({ axios }),
  }),
};
