import Vue from 'vue';

import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: null,
  },
  mutations: {
    SET_LOGGED_IN_USER(state, user) {
      state.user = user;
    },
  },
  actions: {
    setLoggedInUser({ commit }, user) {
      commit('SET_LOGGED_IN_USER', user);
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
  },
});

export default store;
