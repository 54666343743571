<template>
  <draggable
    class="dragArea"
    :data-display="display"
    :list="questionsMetadata.widgets"
    :move="checkMove"
    draggable=".draggable"
    :component-data="getComponentData()"
    :group="{ name: 'question' }"
    :force-fallback="true"
    :scroll-sensitivity="200"
  >
    <div
      class="draggable"
      v-for="(question, i) in questionsMetadata.widgets"
      :key="question.name + i"
    >
      <v-card v-if="question.display === 'section'" style="margin-bottom: 25px;">
        <v-tabs>
          <v-tab>Section</v-tab>
          <v-tab>Condition</v-tab>
          <v-tab-item>
            <v-card-text style="margin-bottom: -38px;">
              <widget-component
                :widgetMetadata="question"
              />
            </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="onClickRemove(question)">Remove</v-btn>
                <v-btn color="blue" text @click="onClickAdd(question)">Add</v-btn>
              </v-card-actions>
            <template v-if="question.widgets">
              <nested-question
                :display="'display'"
                :questionsMetadata="{
                  questionRawList: questionsMetadata.questionRawList,
                  questionList: questionsMetadata.questionList,
                  widgets: question.widgets,
                  parentWidget: question,
                }"
              />
              <!-- <nested-question :display="display" :questions="question.widgets" /> -->
            </template>
          </v-tab-item>
        </v-tabs>
      </v-card>
      <v-card v-if="question.display === 'question'" style="margin-bottom: 25px;">
        <v-tabs>
          <v-tab>Question</v-tab>
          <v-tab>Condition</v-tab>
          <v-tab-item>
            <v-card>
              <v-card-text>
                <div class="text--primary">
                  <widget-component
                    :widgetMetadata="question"
                  />
                  <widget-component
                    style="margin-top: -10px;"
                    :widgetMetadata="question.conditionalWidgets.isRequired"
                  />
                  <v-card>
                    <v-card-title class="text-overline"><h4>Preview</h4></v-card-title>
                    <v-card-text>
                      <widget-component
                        :widgetMetadata="question.preview"
                      />
                    </v-card-text>
                  </v-card>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="onClickRemove(question)">Remove</v-btn>
                <v-btn
                  v-if="question.parent?.display !== 'section'"
                  @click="onClickGroup(question)"
                  color="orange"
                  text
                >
                Group
                </v-btn>
                <v-btn color="blue" text @click="onClickAdd(question)">Add</v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </div>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';
// import DisplayQuestionName from './DisplayQuestionName.vue';
import WidgetComponent from '../../../../components/ui/WidgetComponent.vue';
// import services from '../../../../services';

import FormService from '../../../../services/medical_registry/form.service';

export default {
  name: 'nested-question',
  components: {
    // DisplayQuestionName,
    WidgetComponent,
    draggable,
  },
  props: {
    display: String,
    questionsMetadata: {
      questionRawList: Array,
      questionList: Array,
      widgets: Array,
      parentWidget: [Array, Object],
    },
  },
  data: () => ({
    text: '',
    choices: [],
    testing: {},
    updateIndexTimer: '',
    widgetMetadata: {
      type: 'select',
      name: 'selectedQuestion',
      label: 'Question',
      placeholder: 'Enter question',
      multiple: false,
      rules: [
        (v) => !!v || 'Question is required',
      ],
      items: [
        {
          label: 'Short answer',
          code: 'shortAnswer',
          icon: 'mdi-text-short',
        },
        {
          label: 'Paragraph',
          code: 'paragraph',
          icon: 'mdi-text-long',
        },
        {
          label: 'Multiple choice',
          code: 'multipleChoice',
          icon: 'mdi-radiobox-marked',
        },
        {
          label: 'Checkboxes',
          code: 'checkboxes',
          icon: 'mdi-checkbox-marked',
        },
        {
          label: 'Dropdown',
          code: 'dropdown',
          icon: 'mdi-arrow-down-drop-circle',
        },
        {
          label: 'File upload',
          code: 'fileUpload',
          icon: 'mdi-cloud-upload',
        },
        {
          label: 'Date',
          code: 'date',
          icon: 'mdi-calendar',
        },
        {
          label: 'Time',
          code: 'time',
          icon: 'mdi-clock-outline',
        },
      ],
      value: '',
    },
  }),
  async created() {
    // this.widgetMetadata.items = await questionService.getList();
    // this.widgetMetadata.items = this.widgetMetadata.items.map((c) => {
    //   const { type, _id: code, name: label } = c;
    //   const icon = FormService.getQuestionTypeIcon(type);
    //   return {
    //     label,
    //     code,
    //     icon,
    //   };
    // });
  },
  watch: {
    // IMPORTANT!!!! -- READ IF YOU FORGET THE CODE
    // update widget parent every time the array changes
    // this is called many times depends on the groups you have in the widgets
    // it's called one time for everything and 2 times if you have 2 group widgets
    // eslint-disable-next-line object-shorthand
    'questionsMetadata.widgets'(newValue) {
      const goToWidgets = (widget, index, parent) => {
        const refWidget = widget;
        refWidget.index = index;
        refWidget.parent = parent;
        if (refWidget.display === 'section') {
          refWidget.widgets.forEach(
            (_widget, _index) => goToWidgets(_widget, _index, widget),
          );
        }
      };

      newValue.forEach((widget, index) => {
        goToWidgets(widget, index, this.questionsMetadata.parentWidget);
      });
    },
  },
  methods: {
    getQuestionParent(_question) {
      if (_question.parent?.display === 'section') {
        return _question.parent.widgets;
      }
      return _question.parent;
    },
    getWidgetQuestion({ question, index }) {
      const { parent, display } = question;
      if (display === 'question') {
        return FormService.getWidgetQuestion({
          questions: this.questionsMetadata.questionRawList,
          selections: this.questionsMetadata.questionList,
          parent,
          index,
        });
      } if (display === 'section') {
        return FormService.getWidgetSection({
          index,
          parent,
        });
      }
      throw new Error('Question type unsupported');
    },
    // update the index of then next child
    adjustQuestionIndices(index, qParent) {
      // eslint-disable-next-line no-plusplus
      for (let i = index; i < qParent.length; i++) {
        const nextWidget = qParent[i];
        if (nextWidget) {
          nextWidget.index = i;
        }
      }
    },
    onClickAdd(question) {
      const index = question.index + 1;
      const widget = this.getWidgetQuestion({
        question,
        index,
      });
      const qParent = this.getQuestionParent(question);
      qParent.splice(index, 0, widget);
      this.adjustQuestionIndices(index + 1, qParent);
    },
    onClickRemove(question) {
      const { index } = question;
      const qParent = this.getQuestionParent(question);
      qParent.splice(index, 1);
      this.adjustQuestionIndices(index, qParent);
    },
    onClickGroup(question) {
      const refQuestion = question;
      const { index } = refQuestion;
      const qParent = this.getQuestionParent(refQuestion);

      const sectionWidget = FormService.getWidgetSection({
        index,
        parent: qParent,
      });
      refQuestion.index = 0;
      refQuestion.parent = sectionWidget;
      sectionWidget.widgets.push(refQuestion);
      qParent.splice(index, 1);
      qParent.splice(index, 0, sectionWidget);
    },
    // not a reliable callback to update index of widgets
    handleChange() {
    },
    inputChanged(value) {
      this.activeNames = value;
    },
    getComponentData() {
      return {
        on: {
          change: this.handleChange,
          input: this.inputChanged,
        },
        attrs: {
          wrap: true,
        },
        props: {
          value: this.activeNames,
        },
      };
    },
    checkMove(evt) {
      const { draggedContext } = evt;
      const { element } = draggedContext;
      const { display } = element;
      if (display === 'section') {
        const { relatedContext } = evt;
        const { component } = relatedContext;
        const { $attrs } = component;
        const dataTesting = $attrs['data-display'];
        if (dataTesting === 'display') {
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style scoped>
.dragArea {
  padding: 10px;
  min-height: 100px;
  /* outline: 1px dashed; */
}
</style>
