<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <list-component
      :service="service"
      :tableMetaData="tableMetaData"
    ></list-component>
  </v-container>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import ListComponent from '../../../components/ListComponent.vue';
import services from '../../../services';

const { medicalRegistry } = services;
const { caseService, protocolNumberService } = medicalRegistry;

export default {
  name: 'case-list',
  components: {
    LandingCreate,
    ListComponent,
  },
  data() {
    return {
      service: caseService,
      content: {
        pageTitle: 'Case',
        commands: [
          {
            link: 'case.create',
            icon: 'mdi-plus',
            buttonName: 'Create Case',
          },
        ],
        exportData: {
          active: true,
          isNavOpen: false,
          icon: 'mdi-file-export',
          buttonName: 'EXPORT DATA',
          title: 'Protocol Number - Specimens',
          headers: [
            { text: 'Protocol Number', value: 'protocolNumber' },
            { text: 'ID', value: '_id' },
          ],
          tableData: [],
          getListRemotely: async () => {
            const protocolNumbers = await protocolNumberService.getList();

            this.content.exportData.tableData = protocolNumbers;
          },
          onExportData: async ({ items }) => {
            const ids = items.map(({ _id }) => _id);
            const { data, filename } = await protocolNumberService
              .getFormResponsesAsXL({ items: ids });

            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            console.log(filename, 'lmaoooo');
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
          },
        },
      },
      tableMetaData: {
        tableFilters: {
          selections: [],
          dates: [
            {
              text: 'Date created',
              column: 'dateCreated',
            },
          ],
        },
        tableHeaders: [
          {
            text: 'Case Number',
            value: 'caseNumber',
          },
          {
            text: 'Created by',
            value: '_metadata.created.user.fullName',
          },
          {
            text: 'Date created',
            value: '_metadata.created.date',
          },
          {
            text: 'Actions',
            value: 'actions',
          },
        ],
        actions: {
          view: false,
        },
        URLName: 'case',
      },
    };
  }, // <-- end of return
};
</script>
