var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {}, [_c('v-col', {
    staticStyle: {
      "background-color": "ed"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "rounded-sm",
    staticStyle: {
      "padding": "0px 5px"
    }
  }, [_c('v-card-title', [_c('v-text-field', {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('v-data-table', {
    attrs: {
      "headers": _vm.tablemetadata.tableHeaders,
      "items": _vm.tablecontent.tableContents.rows,
      "item-key": "roleId",
      "items-per-page": 5,
      "search": _vm.search
    },
    scopedSlots: _vm._u([{
      key: "item.option",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('a', {
          staticClass: "descendant linkable",
          attrs: {
            "href": ""
          }
        }, _vm._l(_vm.tablemetadata.tableActions, function (action) {
          return _c('div', {
            key: action.icon
          }, [_c('router-link', {
            staticClass: "create-link",
            attrs: {
              "to": {
                name: action.route,
                params: {
                  id: item._id
                }
              }
            }
          }, [_c('v-btn', {
            staticClass: "table-action-button",
            attrs: {
              "color": "#f0ad4e"
            }
          }, [_c('v-icon', {
            attrs: {
              "text": ""
            }
          }, [_vm._v(" " + _vm._s(action.icon) + " ")]), _vm._v(" " + _vm._s(action.text) + " ")], 1), _vm._v("     ")], 1)], 1);
        }), 0)];
      }
    }])
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }