var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('v-navigation-drawer', {
    staticClass: "sidebar",
    staticStyle: {
      "z-index": "3"
    },
    attrs: {
      "mini-variant": _vm.mini,
      "fixed": "",
      "expand-on-hover": "",
      "permanent": "",
      "height": "120%",
      "color": "#F8EEE7"
    },
    on: {
      "update:miniVariant": function ($event) {
        _vm.mini = $event;
      },
      "update:mini-variant": function ($event) {
        _vm.mini = $event;
      }
    },
    model: {
      value: _vm.drawer,
      callback: function ($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-list', [_c('v-list-item', {
    staticClass: "first-list-item"
  }, [_c('v-list-item-icon', [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.contents.title.icon) + " ")])], 1), _c('v-list-item-content', {
    staticClass: "first-list-content"
  }, [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.contents.title.description) + " ")])], 1)], 1), _c('v-divider'), _vm._l(_vm.contents.items, function (item) {
    return _c('v-list-item', {
      key: item.title,
      staticClass: "next-list-item",
      attrs: {
        "dense": "",
        "to": {
          name: item.link
        },
        "exact": "",
        "link": ""
      }
    }, [_c('v-list-item-icon', [_c('v-icon', {
      attrs: {
        "dense": ""
      }
    }, [_vm._v(_vm._s(item.icon))])], 1), _c('v-list-item-content', {
      staticClass: "next-list-content"
    }, [_c('v-list-item-title', [_vm._v(_vm._s(item.title))])], 1)], 1);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }