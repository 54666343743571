<template>
  <div>
    <v-app-bar app clipped-left color="#6E0D1C" class="main-app-bar" dark dense>
      &nbsp;
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link class="nav-link" :to="{ name: 'HomePage' }" exact>
          <strong>{{ navbar.title }}</strong>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu bottom rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar color="primary" size="35">
              <v-icon>mdi-account-circle-outline</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-card max-width="270px" style="padding: 0px 0px">
          <v-list-item two-line>
            <v-list-item-avatar>
              <!-- <img src="https://randomuser.me/api/portraits/women/81.jpg"> -->
              <v-icon large>mdi-account-circle</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                >{{ user.firstName }} {{ user.lastName }}</v-list-item-title
              >
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list dense>
            <v-list-item :to="{ name: 'UserAccount' }" :key="1" link>
              <v-list-item-icon>
                <v-icon>mdi-shield-account-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Edit Account</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{ name: 'ChangePassword' }" :key="1" link>
              <v-list-item-icon>
                <v-icon>mdi-lock-reset</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Change Password</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="onLogout" :key="2" link>
              <v-list-item-icon>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Sign out</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <h1>&nbsp;</h1>
    </v-app-bar>
    <v-overlay :value="drawer" z-index="4"> </v-overlay>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      class="main-drawer"
      :style="{ top: $vuetify.application.top + 'px', zIndex: 4, width: 293 }"
      height="140%"
      color="#F8EEE7"
    >
      <v-list>
        <v-list-item
          class="app-list-item"
          v-for="item in items"
          :key="item.title"
          @click.stop="drawer = !drawer"
          :to="{name: item.link}"
          exact
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import services from '../../services';

const { authService } = services;

export default {
  name: 'HeaderApp',
  watch: {
    // to, from
    $route(to) {
      const { path } = to;
      const name = path.split('/')[1];
      switch (name) {
        case 'home':
          this.navbar.title = 'Biobank Data Management Platform';
          break;
        case 'medical':
          this.navbar.title = 'Medical Registry';
          break;
        case 'biobank':
          this.navbar.title = 'Biobank Registry';
          break;
        case 'access':
          this.navbar.title = 'Control Panel';
          break;
        default:
          break;
      }
    },
  },
  data() {
    return {
      drawer: false,
      meh: '',
      navbar: {
        title: 'Biobank Data Management Platform',
      },
      items: [
        {
          title: 'Home',
          icon: 'mdi-home-outline',
          link: 'HomePage',
        },
        {
          title: 'Medical Registry',
          icon: 'mdi-heart-pulse',
          link: 'question.list',
        },
        {
          title: 'Biobank Registry',
          icon: 'mdi-flask-outline',
          link: 'specimen-state.list',
        },
        {
          title: 'Control Panel',
          icon: 'mdi-security',
          link: 'user.list',
        },
      ],
      mini: true,
    };
  },
  methods: {
    onLogout() {
      authService.doLogout();
      this.$store.dispatch('setLoggedInUser', null);
      this.$router.push('/');
    },
  },
  computed: {
    ...mapGetters(['user']),
  },
};
</script>
<style>
</style>

<style scoped>
.main-app-bar {
  height: 648px;
  background-color: #6e0d1c;
}
.home-sidebar {
  /* display: flex ; */
  overflow: hidden;
  background: aqua;
  margin-left: -80px;
}
.app-list-item {
  padding: 0 21px;
  height: 20px;
}
.main-drawer {
  width: 293px;
}
.nav-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  vertical-align: middle;
}
</style>
