export default function auth({ next, store }) {
  const user = store.getters.user ? store.getters.user : JSON.parse(localStorage.user);

  if (user && !user.isVerified) {
    return next({
      name: 'LoginOtpPage',
    });
  }

  return next();
}
