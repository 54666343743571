<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <consent-document-form
      :onSubmit="onSubmit"
      :isLoading="isLoading"
    ></consent-document-form>
  </v-container>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import ConsentDocumentForm from './ConsentDocumentForm.vue';

import services from '../../../services';

const { medicalRegistry } = services;
const { consentDocumentService } = medicalRegistry;

export default {
  name: 'ConsentDocumentCreate',
  components: {
    ConsentDocumentForm,
    LandingCreate,
  },
  data: () => ({
    isLoading: false,
    content: {
      pageTitle: 'Consent Document Create',
      enableBackButton: true,
    },
  }),
  methods: {
    async onSubmit(data) {
      try {
        const consentDocumentData = {
          name: data.name,
          description: data.description,
        };
        this.isLoading = true;

        const consentDocument = await consentDocumentService
          .create({ consentDocument: consentDocumentData });

        const { _id } = consentDocument;

        const formData = new FormData();
        formData.append('attachment', data.attachment[0]);
        await consentDocumentService.upload({ id: _id, formData });

        this.isLoading = false;

        this.$snackbar.showOk({ text: 'Successfully created consent document!' });

        this.$router.push({ name: 'consent-document.list' });
      } catch (e) {
        this.isLoading = false;
        this.$snackbar.showBad({ text: 'Something weng wrong!' });
        console.error(e);
      }
    },
  },
};
</script>
