var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-app-bar', {
    staticClass: "main-app-bar",
    attrs: {
      "app": "",
      "clipped-left": "",
      "color": "#6E0D1C",
      "dark": "",
      "dense": ""
    }
  }, [_vm._v("   "), _c('v-app-bar-nav-icon', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.drawer = !_vm.drawer;
      }
    }
  }), _c('v-toolbar-title', [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": {
        name: 'HomePage'
      },
      "exact": ""
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.navbar.title))])])], 1), _c('v-spacer'), _c('v-menu', {
    attrs: {
      "bottom": "",
      "rounded": "",
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "icon": "",
            "x-large": ""
          }
        }, on), [_c('v-avatar', {
          attrs: {
            "color": "primary",
            "size": "35"
          }
        }, [_c('v-icon', [_vm._v("mdi-account-circle-outline")])], 1)], 1)];
      }
    }])
  }, [_c('v-card', {
    staticStyle: {
      "padding": "0px 0px"
    },
    attrs: {
      "max-width": "270px"
    }
  }, [_c('v-list-item', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item-avatar', [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-account-circle")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.user.firstName) + " " + _vm._s(_vm.user.lastName))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.user.email))])], 1)], 1), _c('v-divider'), _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    key: 1,
    attrs: {
      "to": {
        name: 'UserAccount'
      },
      "link": ""
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-shield-account-outline")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Edit Account")])], 1)], 1), _c('v-list-item', {
    key: 1,
    attrs: {
      "to": {
        name: 'ChangePassword'
      },
      "link": ""
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-lock-reset")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Change Password")])], 1)], 1), _c('v-list-item', {
    key: 2,
    attrs: {
      "link": ""
    },
    on: {
      "click": _vm.onLogout
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-logout-variant")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Sign out")])], 1)], 1)], 1)], 1)], 1), _c('h1', [_vm._v(" ")])], 1), _c('v-overlay', {
    attrs: {
      "value": _vm.drawer,
      "z-index": "4"
    }
  }), _c('v-navigation-drawer', {
    staticClass: "main-drawer",
    style: {
      top: _vm.$vuetify.application.top + 'px',
      zIndex: 4,
      width: 293
    },
    attrs: {
      "fixed": "",
      "height": "140%",
      "color": "#F8EEE7"
    },
    model: {
      value: _vm.drawer,
      callback: function ($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-list', _vm._l(_vm.items, function (item) {
    return _c('v-list-item', {
      key: item.title,
      staticClass: "app-list-item",
      attrs: {
        "to": {
          name: item.link
        },
        "exact": "",
        "link": ""
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          _vm.drawer = !_vm.drawer;
        }
      }
    }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(item.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.title))])], 1)], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }