<template>
  <div>
    <v-card>
      <v-card-title>{{ form.name }}</v-card-title>
      <!-- add v-form here for when user submits you can emit the data for parent -->
      <v-card-text>
        <nested-display-form :formMetaData="{ questionList: selectedQuestions }">
        </nested-display-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import NestedDisplayForm from './NestedDisplayForm.vue';

import FormService from '../../../../services/medical_registry/form.service';
import services from '../../../../services';

const { medicalRegistry } = services;
const { questionService } = medicalRegistry;

export default {
  name: 'display-form',
  components: {
    NestedDisplayForm,
  },
  data: () => ({
    questionList: [],
    form: {
      _id: '629f02a83bc97de85e66378c',
      name: 'General Information',
      questions: [
        {
          _id: '629efa643bc97de85e663669',
          isRequired: true,
          name: 'Sex',
          type: 'checkbox',
          display: 'question',
        },
        {
          _id: '629efa413bc97de85e663660',
          isRequired: true,
          name: 'Age',
          type: 'number',
          display: 'question',
        },
        {
          title: 'Group A',
          display: 'section',
          questions: [
            {
              _id: '629efa733bc97de85e663672',
              isRequired: true,
              name: 'Height',
              type: 'number',
              display: 'question',
            },
            {
              _id: '629efa813bc97de85e66367b',
              isRequired: true,
              name: 'Weight',
              type: 'number',
              display: 'question',
            },
            {
              _id: '629efa9d3bc97de85e663684',
              isRequired: true,
              name: 'BMI',
              type: 'number',
              display: 'question',
            },
          ],
        },
        {
          _id: '629efaae3bc97de85e66368d',
          isRequired: true,
          name: 'Date of Birth',
          type: 'date',
          display: 'question',
        },
        {
          _id: '629efac43bc97de85e663696',
          isRequired: true,
          name: 'Place of Birth',
          type: 'text',
          display: 'question',
        },
        {
          _id: '629efadc3bc97de85e66369f',
          isRequired: true,
          name: 'Occupation',
          type: 'text',
          display: 'question',
        },
        {
          _id: '629f02563bc97de85e66377d',
          isRequired: true,
          name: 'Marital Status',
          type: 'checkbox',
          display: 'question',
        },
      ],
    },
    selectedQuestions: [],
    formData: {
      widgets: [],
    },
  }),
  async created() {
    this.questionList = await questionService.getList();
    const mapQuestion = (question, index, parent) => {
      const { display } = question;
      if (display === 'question') {
        const selectedQuestion = this.questionList.find((q) => {
          const { _id } = q;
          const { _id: questionId } = question;
          return _id === questionId;
        });
        return {
          index,
          parent,
          display,
          ...FormService.getValidWidgetJSON(selectedQuestion),
        };
      } if (display === 'section') {
        const { questions, title } = question;
        const sectionWidget = {
          index,
          parent,
          display,
          title,
          name: `section${index}`,
        };
        return {
          ...sectionWidget,
          widgets: questions.map(
            (_question, _index) => mapQuestion(_question, _index, sectionWidget),
          ),
        };
      }
      return null;
    };
    this.selectedQuestions = this.form.questions.map(
      (question, index) => mapQuestion(question, index, this.form.questions),
    );
    console.log(this.selectedQuestions, 'questions');
  },
};
</script>
