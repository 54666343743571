var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('landing-create', {
    attrs: {
      "content": _vm.createPermissionContent
    }
  }), _vm.permission ? _c('permission-form', {
    attrs: {
      "isLoading": _vm.isLoading,
      "permission": _vm.permission,
      "onSubmit": _vm.onSubmit
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }