class OrganizationService {
  constructor() {
    this.data = [
      {
        label: 'University of the Philippines - Philippine General Hospital',
        code: '62d3a3770000000000147fbf',
      },
      {
        label: 'University of the Philippines - Diliman',
        code: '62d3a37e0000000000147fc0',
      },
    ];
  }

  async getList() {
    return new Promise((res) => {
      res(this.data);
    });
  }

  getOne({ id }) {
    return new Promise((res) => {
      const org = this.data.find((datum) => datum.id === id);
      res(org);
    });
  }
}

export default OrganizationService;
