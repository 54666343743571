var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('landing-create', {
    attrs: {
      "content": _vm.content
    }
  }), _c('v-card', [_c('v-row', [_c('v-col', {
    staticClass: "role-section"
  }, [_c('div', {
    staticStyle: {
      "background-color": "rd"
    }
  }, [_c('user-form', {
    on: {
      "onSubmit": _vm.onSubmit
    }
  })], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }