class RoleService {
  constructor({ axios }) {
    this.axios = axios;
  }

  async getList({ query = '', dates = [] } = {}) {
    const result = await this.axios.get('/api/roles', {
      params: {
        query,
        ...(Object.keys(dates).length > 0 && dates),
      },
    });
    const { data } = result;
    return data;
  }

  async getOne({ id }) {
    const { data } = await this.axios.get(`/api/roles/${id}`);
    return data;
  }

  async create({ role }) {
    const { data } = await this.axios.post('/api/roles', role);
    return data;
  }

  async update({ role }) {
    const { data } = await this.axios.put('/api/roles', role);
    return data;
  }
}

export default RoleService;
