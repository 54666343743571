var render = function () {
  var _vm$content, _vm$content$exportDat, _vm$content$exportDat2, _vm$content$exportDat3, _vm$content2, _vm$content2$exportDa;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-app-bar', {
    staticClass: "subheader-app-bar",
    staticStyle: {
      "margin-top": "49px",
      "z-index": "2"
    },
    attrs: {
      "app": "",
      "color": "white",
      "dense": "",
      "flat": ""
    }
  }, [_vm.content.enableBackButton ? _c('go-back-button', {
    staticStyle: {
      "margin-right": "20px"
    }
  }) : _vm._e(), _c('v-toolbar-title', {
    staticClass: "create-title"
  }, [_c('h5', [_vm._v(" " + _vm._s(_vm.content.pageTitle) + " ")])]), _vm._l(_vm.content.commands, function (item, index) {
    return _c('div', {
      key: index
    }, [_c('v-btn', {
      staticClass: "create-button",
      attrs: {
        "text": "",
        "color": "primary"
      }
    }, [_c('router-link', {
      staticClass: "create-link",
      attrs: {
        "to": {
          name: item.link
        }
      }
    }, [_c('h4', [_c('v-icon', [_vm._v(_vm._s(item.icon))]), _vm._v(" " + _vm._s(item.buttonName) + " ")], 1)])], 1)], 1);
  }), (_vm$content = _vm.content) !== null && _vm$content !== void 0 && (_vm$content$exportDat = _vm$content.exportData) !== null && _vm$content$exportDat !== void 0 && _vm$content$exportDat.active ? [_c('v-list-item', [_c('v-btn', {
    staticClass: "ml-auto",
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.onClickShowExport();
      }
    }
  }, [_c('h4', [_c('v-icon', [_vm._v(_vm._s((_vm$content$exportDat2 = _vm.content.exportData) === null || _vm$content$exportDat2 === void 0 ? void 0 : _vm$content$exportDat2.icon))]), _vm._v(" " + _vm._s((_vm$content$exportDat3 = _vm.content.exportData) === null || _vm$content$exportDat3 === void 0 ? void 0 : _vm$content$exportDat3.buttonName) + " ")], 1)])], 1)] : _vm._e()], 2), (_vm$content2 = _vm.content) !== null && _vm$content2 !== void 0 && (_vm$content2$exportDa = _vm$content2.exportData) !== null && _vm$content2$exportDa !== void 0 && _vm$content2$exportDa.active ? [_c('side-bar-export', {
    attrs: {
      "exportData": _vm.content.exportData,
      "title": _vm.content.exportData.title
    }
  })] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }