import QuestionMain from '../views/medical/question/QuestionMain.vue';
import QuestionList from '../views/medical/question/QuestionList.vue';
import QuestionCreate from '../views/medical/question/QuestionCreate.vue';
import QuestionUpdate from '../views/medical/question/QuestionUpdate.vue';

import FormMain from '../views/medical/form/FormMain.vue';
import FormList from '../views/medical/form/FormList.vue';
import FormCreate from '../views/medical/form/FormCreate.vue';
import FormUpdate from '../views/medical/form/FormUpdate.vue';

import DisplayForm from '../views/medical/form/display/DisplayForm.vue';

import ConsentDocumentMain from '../views/medical/consent_document/ConsentDocumentMain.vue';
import ConsentDocumentList from '../views/medical/consent_document/ConsentDocumentList.vue';
import ConsentDocumentView from '../views/medical/consent_document/ConsentDocumentView.vue';
import ConsentDocumentCreate from '../views/medical/consent_document/ConsentDocumentCreate.vue';
import ConsentDocumentUpdate from '../views/medical/consent_document/ConsentDocumentUpdate.vue';

import ProtocolNumberMain from '../views/medical/protocol_number/ProtocolNumberMain.vue';
import ProtocolNumberList from '../views/medical/protocol_number/ProtocolNumberList.vue';
import ProtocolNumberCreate from '../views/medical/protocol_number/ProtocolNumberCreate.vue';
import ProtocolNumberUpdate from '../views/medical/protocol_number/ProtocolNumberUpdate.vue';
import ProtocolNumberView from '../views/medical/protocol_number/ProtocolNumberView.vue';

import CaseMain from '../views/medical/case/CaseMain.vue';
import CaseList from '../views/medical/case/CaseList.vue';
import CaseCreate from '../views/medical/case/CaseCreate.vue';
import CaseUpdate from '../views/medical/case/CaseUpdate.vue';
import CaseView from '../views/medical/case/CaseView.vue';

export default {
  routes: [{
    path: 'question',
    name: 'question',
    component: QuestionMain,
    children: [
      {
        path: 'list',
        name: 'question.list',
        component: QuestionList,
      },
      {
        path: 'create',
        name: 'question.create',
        component: QuestionCreate,
      }, {
        path: 'update/:id',
        name: 'question.edit',
        component: QuestionUpdate,
      },
    ],
  }, {
    path: 'form',
    name: 'form',
    component: FormMain,
    children: [
      {
        path: 'preview',
        name: 'form.preview',
        component: DisplayForm,
      },
      {
        path: 'list',
        name: 'form.list',
        component: FormList,
      },
      {
        path: 'create',
        name: 'form.create',
        component: FormCreate,
      },
      {
        path: 'update/:id',
        name: 'form.edit',
        component: FormUpdate,
      },
    ],
  }, {
    path: 'consent-document',
    name: 'consent-document',
    component: ConsentDocumentMain,
    children: [
      {
        path: 'list',
        name: 'consent-document.list',
        component: ConsentDocumentList,
      },
      {
        path: 'create',
        name: 'consent-document.create',
        component: ConsentDocumentCreate,
      },
      {
        path: 'update/:id',
        name: 'consent-document.edit',
        component: ConsentDocumentUpdate,
      },
      {
        path: 'view/:id',
        name: 'consent-document.view',
        component: ConsentDocumentView,
      },
    ],
  }, {
    path: 'protocol-number',
    name: 'protocol-number',
    component: ProtocolNumberMain,
    children: [
      {
        path: 'list',
        name: 'protocol-number.list',
        component: ProtocolNumberList,
      },
      {
        path: 'create',
        name: 'protocol-number.create',
        component: ProtocolNumberCreate,
      },
      {
        path: 'update/:id',
        name: 'protocol-number.edit',
        component: ProtocolNumberUpdate,
      },
      {
        path: 'view/:id',
        name: 'protocol-number.view',
        component: ProtocolNumberView,
      },
    ],
  }, {
    path: 'case',
    name: 'case',
    component: CaseMain,
    children: [
      {
        path: 'list',
        name: 'case.list',
        component: CaseList,
      },
      {
        path: 'create',
        name: 'case.create',
        component: CaseCreate,
      },
      {
        path: 'update/:id',
        name: 'case.edit',
        component: CaseUpdate,
      },
      {
        path: 'view/:id',
        name: 'case.view',
        component: CaseView,
      },
    ],
  }],
};
