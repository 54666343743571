<template>
  <div>
    <landing-create :content="content"></landing-create>
    <list-component
      :service="service"
      :tableMetaData="tableMetaData"
    ></list-component>
  </div>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import ListComponent from '../../../components/ListComponent.vue';
import services from '../../../services';

const { biobankRegistry, medicalRegistry } = services;
const { caseService } = biobankRegistry;
const { protocolNumberService } = medicalRegistry;

export default {
  name: 'case-list',
  components: {
    ListComponent,
    LandingCreate,
  },
  data() {
    return {
      service: caseService,
      tableMetaData: {
        tableFilters: {
          selections: [],
          dates: [
            {
              text: 'Date created',
              column: 'dateCreated',
            },
            {
              text: 'Date updated',
              column: 'dateUpdated',
            },
          ],
        },
        tableHeaders: [
          {
            text: 'Case Number',
            value: 'caseNumber',
          },
          {
            text: 'Created by',
            value: '_metadata.created.user.fullName',
          },
          {
            text: 'Date created',
            value: '_metadata.created.date',
          },
          {
            text: 'Actions',
            value: 'actions',
          },
        ],
        URLName: 'bcase',
      },
      content: {
        pageTitle: 'Case',
        commands: [
          // {
          //   link: 'bcase.create',
          //   icon: 'mdi-plus-box',
          //   buttonName: 'Create',
          // },
        ],
        // enable export function
        exportData: {
          // enable this export by setting the active to true
          active: true,
          isNavOpen: false,
          icon: 'mdi-file-export',
          buttonName: 'EXPORT DATA',
          title: 'Protocol Number - Specimens',
          headers: [
            { text: 'Protocol Number', value: 'protocolNumber' },
            { text: 'ID', value: '_id' },
          ],
          getListRemotely: async () => {
            const protocolNumbers = await protocolNumberService.getList();

            this.content.exportData.tableData = protocolNumbers;
          },
          onExportData: async ({ items }) => {
            const ids = items.map(({ _id }) => _id);
            const { data, filename } = await protocolNumberService.getSpecimensAsXL({ items: ids });

            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            console.log(filename, 'lmaoooo');
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
          },
          tableData: [],
        },
      },
    };
  },
};
</script>
