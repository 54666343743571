var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "generic-container"
  }, [_c('page-header', {
    attrs: {
      "headerTitle": _vm.headerTitle
    }
  }), _c('v-card', [_c('v-row', {}, [_c('v-col', {
    staticClass: "role-section",
    attrs: {
      "cols": "6",
      "md": "4",
      "xl": "3"
    }
  }, [_c('form', {
    staticStyle: {
      "background-color": "rd"
    }
  }, [_c('v-row', [_c('h2', {
    staticClass: "role-form-subheader"
  }, [_vm._v("General Information")])]), _c('br'), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "First name",
      "value": "Philip John",
      "required": ""
    }
  }), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "value": "Sales",
      "label": "Last name",
      "required": ""
    }
  }), _c('br'), _c('v-btn', {
    staticClass: "mr-4 primary"
  }, [_vm._v(" Submit ")])], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }