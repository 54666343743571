<template>
  <v-navigation-drawer
    fixed
    width="650px"
    v-model="exportData.isNavOpen"
    temporary
    right
  >
    <v-col cols="12">
      <v-card class="mx-auto">
        <v-card-title>
          Export Data
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="exportData?.headers"
          :items="exportData?.tableData"
          :search="search"
          :items-per-page="15"
          :item-value="item => item._id"
          item-key="_id"
          show-select
          class="elevation-1"
          v-model="selectedItems"
        >
        </v-data-table>
      </v-card>
      <v-btn
          block
          color="primary"
          style="margin-top: 20px"
          :disabled="selectedItems.length === 0"
          @click="generateExport"
        >
          GENERATE REPORT
      </v-btn>
    </v-col>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'SidebarExport',
  props: {
    title: String,
    exportData: Object,
  },
  data: () => ({
    search: '',
    selectedItems: [],
  }),
  created() {
    // console.log(this.exportData, 'exportdata');
  },
  methods: {
    generateExport() {
      this.exportData.onExportData({ items: this.selectedItems });
    },
  },
};
</script>
