<template>
  <div>
    <landing-create :content="createRoleContent"></landing-create>
    <landing-table-action
      :tablemetadata="tableMetaData"
      :tablecontent="tableContent"></landing-table-action>
  </div>
</template>

<script>
import LandingCreate from '../../components/layouts/LandingCreate.vue';
import LandingTableAction from '../../components/layouts/LandingTableAction.vue';

export default {
  name: 'DepartmentList',
  components: {
    LandingTableAction,
    LandingCreate,
  },
  data() {
    return {
      drawer: true,
      mini: true,
      search: '',
      createRoleContent: {
        pageTitle: 'Departments',
        commands: [
          {
            link: 'DepartmentCreate',
            icon: 'mdi-plus-box',
            buttonName: 'Create Department',
          },
        ],
      },
      tableMetaData: {
        tableActions: [
          {
            text: 'Update',
            icon: 'mdi-application-edit-outline',
            route: 'UserList',
          },
          {
            text: 'View',
            icon: 'mdi-eye',
            route: 'UserCreate',
          },
        ],
        tableHeaders: [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Description', value: 'description' },
          { text: '', value: 'option' },
        ],
      },
      tableContent: {
        tableContents: {
          rows: [
            {
              departmentId: '1',
              name: 'Department of Laboratories',
              description: 'Department of Pathology and Laboratory',
            },
            {
              departmentId: '2',
              name: 'General Surgery Department',
              description: 'Philippine General Hospital',
            },
            {
              departmentId: '3',
              name: 'Institute of Biology',
              description: 'Institute of Biology',
            },
            {
              departmentId: '4',
              name: 'Obstetric Gynecology',
              description: 'Obstetric Gynecology',
            },
          ],
        },
      },
      /*
      tableActions: [
        {
          text: 'Data Entry',
          icon: 'mdi-application-edit-outline',
          route: 'UserList',
        },
        {
          text: 'View',
          icon: 'mdi-eye',
          route: 'UserCreate',
        },
      ],
      tableHeaders: [
        {
          text: 'Title',
          align: 'start',
          sortable: true,
          value: 'title',
        },
        { text: 'Module', value: 'module' },
        { text: 'Status', value: 'status' },
        { text: 'Permissions', value: 'permission' },
        { text: '', value: 'option' },
      ],
      tableContents: {
        rows: [
          {
            roleId: '1',
            title: 'Terminology Server Owner',
            module: 'Terminology Server',
            status: 'Enabled',
            permission: '3',
          },
          {
            roleId: '2',
            title: 'Terminology Server Editor',
            module: 'Terminology Server',
            status: 'Enabled',
            permission: '33',
          },
        ],
      },
      */
    };
  },
};
</script>
<style scoped>
.create-action-button {
  /*color: white;*/
  border: none;
  background: none;
  outline: none;
  letter-spacing: 0;
}
.create-link {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  display: flex;
  vertical-align: middle;
}
</style>
