import Vue from 'vue';
import IdleVue from 'idle-vue';
import router from './router';
import vuetify from './plugins/vuetify';
import store from './vuex';

import App from './App.vue';

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1800000, // 30 minutes
  startAtIdle: true,
});

Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  render: (h) => h(App),
  router,
}).$mount('#app');
