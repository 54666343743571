<template>
  <v-container class="home-page">
    <v-row class="text-center">
      <v-col cols="12">
        <v-row class="selection-outer-container">
          <v-col
            cols="12"
            md="4"
            v-for="item in items"
            :key="item.title">

            <v-card
              class="mx-auto my-12 v-btn selection-container"
              width="300"
              height="300"
              :to="{name: item.link}"
            >
              <div class="selection-body">
                <v-icon :style="{'font-size': '151px'}" class="mb-5">
                  {{ item.icon }}
                </v-icon>
                <p>{{ item.title }}</p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'HomePage',
  data() {
    return {
      alert: true,
      drawer: true,
      items: [
        {
          title: 'Medical Registry', icon: 'mdi-heart-pulse', link: 'question.list', permissions: ['Medical Registry'],
        },
        {
          title: 'Biobank Registry', icon: 'mdi-flask-outline', link: 'specimen-state.list', permissions: ['Medical Registry'],
        },
        {
          title: 'Control Panel', icon: 'mdi-security', link: 'user.list', permissions: ['Medical Registry'],
        },
      ],
      mini: true,
    };
  },
};
</script>
<style scoped>

.home-page {
  height: 100%;
  overflow: hidden auto;
  background: url(../assets/up-pgh-biobank.png) no-repeat 91% 91%;
  background-attachment: fixed;
  overflow: hidden;
}

.selection-container {
  position: relative;
  cursor: pointer;
}

.selection-body {
  margin: 0;
  position: absolute;
  top: 45%;
  left: 35%;
  -ms-transform: translateY(-45%) translateX(-24%);
  transform: translateY(-45%) translateX(-24%);
}

.selection-outer-container {
  padding-left: 10%;
  padding-right: 10%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .selection-outer-container {
    padding-left: unset;
    padding-right: unset;
  }
}

</style>
