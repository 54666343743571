<template>
  <v-text-field v-if="['text', 'number'].includes(widgetMetadata.type)"
    v-model="widgetMetadata.value"
    :name="widgetMetadata.name"
    :counter="widgetMetadata.counter"
    :label="widgetMetadata.label"
    :placeholder="widgetMetadata.placeholder"
    :maxlength="widgetMetadata.maxlength"
    :rules="!widgetMetadata.readonly ? widgetMetadata.rules : []"
    :type="widgetMetadata.type"
    :min="widgetMetadata.min"
    :max="widgetMetadata.max"
    :prefix="widgetMetadata.prefix"
    :suffix="widgetMetadata.suffix"
    :disabled="widgetMetadata.readonly"
    @change="onTextChange"
    outlined
    dense
    clearable
  >
  </v-text-field>
  <v-textarea v-else-if="widgetMetadata.type === 'textarea'"
    v-model="widgetMetadata.value"
    :name="widgetMetadata.name"
    :counter="widgetMetadata.counter"
    :label="widgetMetadata.label"
    :placeholder="widgetMetadata.placeholder"
    :maxlength="widgetMetadata.maxlength"
    :rules="!widgetMetadata.readonly ? widgetMetadata.rules : []"
    :readonly="widgetMetadata.readonly"
    outlined
    dense
    clearable
  >
  </v-textarea>
  <v-switch v-else-if="widgetMetadata.type === 'switch'"
    v-model="widgetMetadata.value"
    :name="widgetMetadata.name"
    :label="widgetMetadata.label"
  >
  </v-switch>
  <v-card
    outlined
    class="pa-2"
    style="margin: 0px 0px 20px 0px"
    v-else-if="widgetMetadata.type === 'checkbox'"
  >
    <label style="color: rgba(0, 0, 0, 0.6)">
      {{ widgetMetadata.label }}
      <small v-if="widgetMetadata.placeholder">- {{ widgetMetadata.placeholder }}</small>
    </label>
    <div v-for="(item, i) in widgetMetadata.items" :key="'cb' + i">
      <v-checkbox
        v-model="widgetMetadata.value"
        :label="item.label"
        :value="item.code"
        :readonly="widgetMetadata.readonly"
      >
      </v-checkbox>
    </div>
  </v-card>
  <v-autocomplete v-else-if="widgetMetadata.type === 'select'"
    chips
    v-model="widgetMetadata.value"
    :name="widgetMetadata.name"
    :label="widgetMetadata.label"
    :placeholder="widgetMetadata.placeholder"
    :items="widgetMetadata.items"
    :filter="customFilter"
    @change="(code) => selectChange(code)"
    :multiple="widgetMetadata.multiple"
    :readonly="widgetMetadata.readonly"
    :rules="!widgetMetadata.readonly ? widgetMetadata.rules : []"
    item-value="code"
    outlined
    :dense="!widgetMetadata.dense"
    clearable
  >
    <template v-slot:selection="{ item }">
      <v-chip v-if="widgetMetadata.chip">
        <v-icon v-if="item.icon">{{ item.icon }}</v-icon> {{ item.label }}
      </v-chip>
      <template v-else>
        <v-icon v-if="item.icon">{{ item.icon }}</v-icon> {{ item.label }}<br>
      </template>
    </template>
    <template v-slot:item="{ item, attrs }">
      <span>
        <label v-if="widgetMetadata.multiple">
          <v-icon v-if="attrs['aria-selected'] === 'true'">mdi-checkbox-marked</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
        </label>
        <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
        {{ item.label }}
      </span>
    </template>
  </v-autocomplete>
  <span v-else-if="widgetMetadata.type === 'file'">
    <v-file-input
      chips
      show-size
      truncate-length="50"
      v-model="widgetMetadata.value"
      :name="widgetMetadata.name"
      :label="widgetMetadata.label"
      :placeholder="widgetMetadata.placeholder"
      :disabled="widgetMetadata.readonly"
      @change="onFileChange"
      multiple
      :rules="!widgetMetadata.readonly && this.widgetMetadata?.maxNumberOfFiles > 0
        ? [
          v => (v &&  v.length >= this.widgetMetadata.maxNumberOfFiles) ||
            `At least ${this.widgetMetadata.maxNumberOfFiles} attachment(s) is required`,
        ]
        : []"
      :accept="widgetMetadata.accept"
      clearable
    >
    </v-file-input>
    <template v-if="widgetMetadata.imageURL">
      <img
        :src="widgetMetadata.imageURL"
        style="max-width: 800px; max-height: 400px;"
      />
    </template>
  </span>
  <div v-else-if="['date', 'time'].includes(widgetMetadata.type)">
    <section style="margin: 20px 0px 20px 0px;">
      <h4>{{ widgetMetadata.label }}</h4>
      <date-picker
        style="width: 100%;"
        v-model="widgetMetadata.value"
        :type="widgetMetadata.type"
        :placeholder="widgetMetadata.placeholder"
        :range="widgetMetadata.range"
        :disabled="widgetMetadata.readonly"
      >
      </date-picker>
    </section>
  </div>
  <!-- <v-menu v-else-if="widgetMetadata.type === 'date'"
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="widgetMetadata.label"
        :placeholder="widgetMetadata.placeholder"
        prepend-icon="mdi-calendar"
        readonly
        clearable
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :range="widgetMetadata.range"
      no-title
      scrollable
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="menu = false"
      >
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.menu.save(date)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu> -->
  <v-btn v-else-if="widgetMetadata.type === 'button'"
    depressed
    :color="widgetMetadata.color"
    :type="widgetMetadata.btnType"
    :class="widgetMetadata.class"
    clearable
    :dark="widgetMetadata.dark"
    @click="onClick"
  >
  {{ widgetMetadata.label }}
  </v-btn>
  <div v-else>
    <template v-if="widgetMetadata.type">
      Not supported: {{ widgetMetadata.type }}
    </template>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
  components: {
    DatePicker,
  },
  props: {
    widgetMetadata: {
      type: Object,
      default: () => ({
        type: '',
        name: '',
        label: '',
        btnType: '',
        dateType: '',
        min: null,
        max: null,
        items: [],
        rules: null,
        maxlength: null,
        customFilter: null,
        selectChange: null,
        maxNumberOfFiles: 1,
        counter: null,
        multiple: false,
        readonly: false,
        accept: null,
        range: false,
        value: null,
      }),
    },
  },
  data: () => ({
    date: null,
    menu: false,
    selectOldValue: null,
    cacheImageURL: null,
  }),
  watch: {
    date() {
      console.log(this.date, 'date lmao');
    },
    // eslint-disable-next-line object-shorthand
    'widgetMetadata.value'(value) {
      if (this.widgetMetadata.type === 'date' && this.widgetMetadata.range) {
        console.log(value, 'date range');
      }
    },
  },
  created() {
    // keep track of old value of a select widget
    // can be reused by other widgets i think
    this.selectOldValue = this.widgetMetadata.value;
    if (this.widgetMetadata.type === 'date' && this.widgetMetadata.range) {
      console.log(this.widgetMetadata, 'value');
      // this.widgetMetadata.value = [new Date(), new Date()];
    }

    this.cacheImageURL = this.widgetMetadata.imageURL;
  },
  methods: {
    onClick() {
      if (this.widgetMetadata.onClick) {
        this.widgetMetadata.onClick();
      }
    },
    selectChange(code) {
      if (this.widgetMetadata.selectChange) {
        this.widgetMetadata.selectChange(code, this.selectOldValue);
      }
      if (this.selectOldValue !== code) {
        this.selectOldValue = code;
      }
    },
    onTextChange(value) {
      if (this.widgetMetadata.onChange) {
        this.widgetMetadata.onChange(value);
      }
    },
    customFilter(item, queryText) {
      if (this.widgetMetadata.customFilter) {
        return this.widgetMetadata.customFilter(item, queryText);
      }
      const label = item.label.toLowerCase();
      const searchText = queryText.toLowerCase();
      return label.indexOf(searchText) > -1;
    },
    onFileChange(e) {
      this.widgetMetadata.onFileChange(e, this.cacheImageURL);
    },
    test(v) {
      console.log(v);
      return false;
    },
  },
};
</script>
