import SidebarStandard from '../../components/sidebars/SidebarStandard.vue';

export default {
  computed: {
    sidebarContent() {
      return {
        title: {
          description: 'Medical Registry',
          icon: 'mdi-heart-pulse',
          link: 'MedicalCaseList',
        },
        items: [
          {
            title: 'Questions',
            icon: 'mdi-help-box-outline',
            link: 'question.list',
          },
          {
            title: 'Form Builder',
            icon: 'mdi-table-cog',
            link: 'form.list',
          },
          {
            title: 'Consent Document',
            icon: 'mdi-file-sign',
            link: 'consent-document.list',
          },
          {
            title: 'Protocol Number',
            icon: 'mdi-file-document-outline',
            link: 'protocol-number.list',
          },
          {
            title: 'Case',
            icon: 'mdi-hospital-building',
            link: 'case.list',
          },
          // {
          //   title: 'Reports',
          //   icon: 'mdi-chart-areaspline',
          //   link: 'ReportList',
          // },
        ],
      };
    },
  },
  components: {
    SidebarStandard,
  },
};
