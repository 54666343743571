var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login-page fill-height fluid no-gutters"
  }, [_c('v-layout', [_c('v-app-bar', {
    attrs: {
      "elevation": "4"
    }
  }, [_c('v-img', {
    staticClass: "my-3 header-image",
    attrs: {
      "src": require('../assets/up-pgh-biobank.png'),
      "contain": "",
      "height": "100%"
    }
  })], 1)], 1), _c('v-layout', [_c('v-row', {
    staticClass: "mt-5",
    staticStyle: {
      "padding": "20px"
    }
  }, [_c('v-col', {
    attrs: {
      "lg": "4",
      "sm": "1"
    }
  }), _c('v-col', {
    attrs: {
      "lg": "4",
      "sm": "10"
    }
  }, [_c('div', {
    staticClass: "text-center mb-4"
  }, [_c('div', {
    staticClass: "transition-swing",
    class: ['text-h3', 'mb-2'],
    domProps: {
      "textContent": _vm._s('One Time Password')
    }
  }), _c('div', {
    staticClass: "transition-swing",
    class: ['text-h5', 'mb-2'],
    domProps: {
      "textContent": _vm._s('Please enter the One Time Password we sent to your email address.')
    }
  })]), _c('div', [_c('validation-observer', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var invalid = _ref.invalid;
        return [_c('form', {
          on: {
            "submit": function ($event) {
              $event.preventDefault();
              return _vm.handleSubmit.apply(null, arguments);
            }
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "one-time-password",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var errors = _ref2.errors;
              return [_c('v-text-field', {
                attrs: {
                  "append-icon": _vm.show3 ? 'mdi-eye' : 'mdi-eye-off',
                  "type": _vm.show3 ? 'text' : 'password',
                  "error-messages": errors,
                  "name": "input-10-1",
                  "outlined": "",
                  "dense": "",
                  "height": "70"
                },
                on: {
                  "click:append": function ($event) {
                    _vm.show3 = !_vm.show3;
                  }
                },
                model: {
                  value: _vm.oneTimePassword,
                  callback: function ($$v) {
                    _vm.oneTimePassword = $$v;
                  },
                  expression: "oneTimePassword"
                }
              })];
            }
          }], null, true)
        }), _c('v-row', [_c('v-spacer')], 1), _c('br'), !_vm.otp_expired ? _c('v-btn', {
          staticClass: "login--button primary mb-3",
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "dense": "",
            "disabled": invalid,
            "type": "submit"
          }
        }, [_vm._v(" Submit ")]) : _c('v-btn', {
          staticClass: "login--button primary mb-3",
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "dense": "",
            "type": "button"
          },
          on: {
            "click": _vm.resendOTP
          }
        }, [_vm._v(" Resend OTP ")]), _c('v-btn', {
          staticClass: "login--button primary",
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "dense": "",
            "type": "submit"
          },
          on: {
            "click": _vm.onLogout
          }
        }, [_vm._v(" Logout ")])], 1)];
      }
    }])
  }), _c('hr', {
    staticClass: "my-10"
  })], 1)]), _c('v-col', {
    attrs: {
      "lg": "4",
      "sm": "1"
    }
  })], 1)], 1), _c('loader', {
    attrs: {
      "is-loading": _vm.isLoading,
      "loader-style": 'dots'
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }