class ProtocolNumberService {
  constructor({ axios }) {
    this.axios = axios;
  }

  async getList({ query = '', dates = [] } = {}) {
    const result = await this.axios.get('/api/medical/protocol-numbers', {
      params: {
        query,
        ...(Object.keys(dates).length > 0 && dates),
      },
    });
    const { data } = result;
    return data;
  }

  async getFormResponsesAsXL({ items }) {
    const exportPath = '/api/medical/protocol-numbers/export/form-response/xlsx';
    const { data, headers } = await this.axios.get(
      // set query 'ids' to proper array
      // ?ids[0]=1&ids[1]=2&ids[2]=3
      `${exportPath}?${items.map((n, i) => `ids[${i}]=${n}`).join('&')}`,
      {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      },
    );
    return { data, filename: headers['x-suggested-filename'] };
  }

  async getSpecimensAsXL({ items }) {
    const exportPath = '/api/medical/protocol-numbers/export/specimen-case/xlsx';

    const { data, headers } = await this.axios.get(
      // set query 'ids' to proper array
      // ?ids[0]=1&ids[1]=2&ids[2]=3
      `${exportPath}?${items.map((n, i) => `ids[${i}]=${n}`).join('&')}`,
      {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      },
    );
    return { data, filename: headers['x-suggested-filename'] };
  }

  async getOne({ id }) {
    const { data } = await this.axios.get(`/api/medical/protocol-numbers/${id}`);
    return data;
  }

  async create({ question }) {
    const { data } = await this.axios.post('/api/medical/protocol-numbers', question);
    return data;
  }

  async update({ question }) {
    const { data } = await this.axios.put('/api/medical/protocol-numbers', question);
    return data;
  }
}

export default ProtocolNumberService;
