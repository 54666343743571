<template>
  <div>
    <div v-for="(question, index) in formMetaData.questionList"
      :key="question.name + index"
    >
      <template v-if="question.display === 'question'">
        <widget-component :widgetMetadata="question">
        </widget-component>
      </template>
      <template v-if="question.display === 'section'">
        <v-card style="margin-bottom: 15px;">
          <v-card-title>{{ question.title }}</v-card-title>
          <v-card-text>
            <nested-display-form
              :formMetaData="{ questionList: question.widgets }"
            ></nested-display-form>
          </v-card-text>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>
import WidgetComponent from '../../../../components/ui/WidgetComponent.vue';

export default {
  name: 'nested-display-form',
  components: {
    WidgetComponent,
  },
  props: {
    formMetaData: {
      questionList: [Object],
    },
  },
};
</script>
