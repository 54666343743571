var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('landing-create', {
    attrs: {
      "content": _vm.createPermissionContent
    }
  }), _c('list-component', {
    attrs: {
      "service": _vm.service,
      "tableMetaData": _vm.tableMetaData
    }
  }), _c('v-dialog', {
    model: {
      value: _vm.isView,
      callback: function ($$v) {
        _vm.isView = $$v;
      },
      expression: "isView"
    }
  }, [_c('question-view', {
    attrs: {
      "question": _vm.question
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }