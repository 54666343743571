<template>
  <v-card>
    <v-card-title> Details </v-card-title>
    <v-card-text>
      <v-card-text>
        <div class="pa-12">
          <widget-component
            v-if="question"
            :widgetMetadata="getValidWidgetJSON(question)"
          >
          </widget-component>
        </div>
      </v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>
import WidgetComponent from '../../../components/ui/WidgetComponent.vue';

import FormService from '../../../services/medical_registry/form.service';

export default {
  name: 'question-view',
  components: {
    WidgetComponent,
  },
  props: {
    question: Object,
  },
  methods: {
    getValidWidgetJSON(question) {
      return FormService.getValidWidgetJSON(question);
    },
  },
};
</script>
