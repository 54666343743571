var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "go-back"
  }, [_c('button', {
    on: {
      "click": _vm.goBack
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-left")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }