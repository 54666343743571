<template>
  <v-container>
    <loader :isLoading="isLoading"></loader>
    <v-form ref="form" @submit.prevent="onSave">
      <v-card elevation="2">
        <v-card-text>
          <widget-component v-for="(widgetMetadata, index) in formData.widgets"
            :key="index"
            :widgetMetadata="widgetMetadata">
          </widget-component>
          <v-card>
            <v-card-title>
              Medical Forms
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                dark
                text
                class="mb-2"
                @click="onClickShowFormSelection()"
              >
                <v-icon>mdi-plus</v-icon>
                Add Medical Form
              </v-btn>
            </v-card-title>
            <v-card-text>
              <ul style="list-style-type: none; margin: 0px; padding: 0px;">
                <draggable
                  handle=".handle"
                  :list="formSelectedTableMetadata.items"
                >
                  <li
                    v-for="(item, index) in formSelectedTableMetadata.items"
                    :key="`pn${index}`"
                  >
                    <v-btn text class="handle"><v-icon>mdi-menu</v-icon></v-btn>
                    <span class="text">{{ item.name }}</span>
                    <v-btn text class="close">
                      <v-icon
                        class="mr-2"
                        color="primary"
                        @click="onClickDeleteSelectedForm(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </v-btn>
                    <v-btn
                      text
                      class="close"
                      @click="onClickViewFormPreview(item)"
                    >
                      <v-icon
                        class="mr-2"
                        color="blue"
                      >
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </li>
                </draggable>
              </ul>
            </v-card-text>
          </v-card>
          <!-- { text: 'Form Name', value: 'name' },
          { text: 'ID', value: '_id' },
          { text: 'Actions', value: 'actions', sortable: false }, -->
          <v-btn
            style="margin-top: 20px; "
            color="primary"
            type="submit"
          >
            Save
          </v-btn>
        </v-card-text>
      </v-card>
    </v-form>

    <v-navigation-drawer
      v-model="isSelectingForms"
      fixed
      right
      temporary
      width="650px"
    >
      <v-list-item>
        <h3>Select Form(s) to link</h3>
      </v-list-item>

      <v-divider></v-divider>

      <v-col cols="12">
        <v-card>
          <v-card-title>
            Forms
            <v-spacer></v-spacer>
            <v-text-field
              v-model="navigationDrawerFormSelectionMetadata.search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-model="navigationDrawerFormSelectionMetadata.selected"
            :headers="navigationDrawerFormSelectionMetadata.headers"
            :items="navigationDrawerFormSelectionMetadata.items"
            :items-per-page="5"
            :search="navigationDrawerFormSelectionMetadata.search"
            item-key="_id"
            show-select
            class="elevation-1"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                class="mr-2"
                @click="onClickViewFormPreview(item)"
                color="blue"
              >
                mdi-eye
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
        <v-btn
          style="margin-top: 20px;"
          color="primary"
          @click="onClickAddSelectedForms"
        >
          Add
        </v-btn>

        <v-dialog v-model="isPreviewFormShown">
          <v-card>
            <v-card-title>
              Preview
            </v-card-title>
            <v-card-text>
              <template
                v-if="questionsMetadata.questionRawList && isPreviewFormShown"
              >
                <form-display
                  :questionList="questionsMetadata.questionRawList"
                  :formMetadata="formMetadata"
                >
                </form-display>
              </template>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-navigation-drawer>
  </v-container>
</template>

<!-- eslint-disable no-underscore-dangle -->
<script>
import draggable from 'vuedraggable';
import Loader from '../../../components/utilities/Loader.vue';
import WidgetComponent from '../../../components/ui/WidgetComponent.vue';
import services from '../../../services';

import FormService from '../../../services/medical_registry/form.service';

import FormDisplay from '../form/display/FormDisplay.vue';

const { medicalRegistry, controlPanelRegistry } = services;
const { formService, questionService, consentDocumentService } = medicalRegistry;
const { userService, departmentService, organizationService } = controlPanelRegistry;

export default {
  name: 'ProtocolNumberForm',
  components: {
    WidgetComponent,
    draggable,
    FormDisplay,
    Loader,
  },
  props: {
    isLoading: Boolean,
    onSubmit: Function,
    protocolNumber: Object,
  },
  data() {
    return {
      isSelectingForms: false,
      isPreviewFormShown: false,
      FormService,
      formMetadata: {
        _id: '',
        name: '',
        questions: [],
      },
      questionsMetadata: {
        questionRawList: [],
      },
      navigationDrawerFormSelectionMetadata: {
        search: '',
        selected: [],
        headers: [
          { text: 'Form Name', value: 'name' },
          { text: 'ID', value: '_id' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        items: [],
      },
      userChoices: [],
      formSelectedTableMetadata: {
        search: '',
        headers: [
          { text: 'Form Name', value: 'name' },
          { text: 'ID', value: '_id' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        items: [],
      },
      selectFormWidget: {
        type: 'select',
        name: 'type',
        label: 'Search forms',
        placeholder: 'Select a form',
        multiple: true,
        rules: [
          (v) => !!v || 'Question type is required',
        ],
        items: [],
        customFilter: (item, queryText) => {
          const label = item.label.toLowerCase();
          const searchText = queryText.toLowerCase();
          return label.indexOf(searchText) > -1;
        },
        value: '',
      },
      formData: {
        model: this.protocolNumber,
        widgets: [
          {
            type: 'text',
            name: 'protocolNumber',
            label: 'Protocol Number - UPMREB Code',
            placeholder: 'Enter the protocol number',
            value: '',
            counter: 100,
            maxLength: 100,
            rules: [
              (v) => !!v || 'Protocol Number is required',
              (v) => (v && v.length <= 100) || 'Protocol Number must be less than or equal to 100 characters',
            ],
          },
          {
            type: 'date',
            name: 'dateApproved',
            label: 'Date Approved',
            placeholder: 'Define the approved date',
            rules: [
              (v) => !!v || 'Department is required',
            ],
            value: '',
            range: false,
          },
          {
            type: 'select',
            name: 'consentDocuments',
            label: 'Consent Document',
            placeholder: 'Select the consent document',
            dense: true,
            chip: true,
            multiple: true,
            rules: [
              (v) => !!v || 'Department is required',
            ],
            items: [],
            customFilter: (item, queryText) => {
              const label = item.label.toLowerCase();
              const searchText = queryText.toLowerCase();
              return label.indexOf(searchText) > -1;
            },
            value: '',
          },
          {
            type: 'select',
            name: 'organizations',
            label: 'Organization(s)',
            placeholder: 'Select your organization',
            multiple: true,
            dense: true,
            chip: true,
            rules: [
              (v) => !!v || 'Organization is required',
            ],
            items: [],
            customFilter: (item, queryText) => {
              const label = item.label.toLowerCase();
              const searchText = queryText.toLowerCase();
              return label.indexOf(searchText) > -1;
            },
            value: '',
          },
          {
            type: 'select',
            name: 'departments',
            label: 'Department(s)',
            placeholder: 'Select the department',
            dense: true,
            chip: true,
            multiple: true,
            rules: [
              (v) => !!v || 'Department is required',
            ],
            items: [],
            customFilter: (item, queryText) => {
              const label = item.label.toLowerCase();
              const searchText = queryText.toLowerCase();
              return label.indexOf(searchText) > -1;
            },
            value: '',
          },
          {
            type: 'select',
            name: 'principalInvestigators',
            label: 'Principal Investigator',
            placeholder: 'Select the principal investigator',
            dense: true,
            chip: true,
            multiple: true,
            rules: [
              (v) => !!v || 'Department is required',
            ],
            items: [],
            customFilter: (item, queryText) => {
              const label = item.label.toLowerCase();
              const searchText = queryText.toLowerCase();
              return label.indexOf(searchText) > -1;
            },
            value: '',
          },
          {
            type: 'select',
            name: 'researchers',
            label: 'Researcher',
            placeholder: 'Select the researcher',
            dense: true,
            chip: true,
            multiple: true,
            rules: [
              (v) => !!v || 'Department is required',
            ],
            items: [],
            customFilter: (item, queryText) => {
              const label = item.label.toLowerCase();
              const searchText = queryText.toLowerCase();
              return label.indexOf(searchText) > -1;
            },
            value: '',
          },
          {
            type: 'select',
            name: 'collaborators',
            label: 'Collaborator',
            placeholder: 'Select the researcher',
            dense: true,
            chip: true,
            multiple: true,
            rules: [
              // (v) => !!v || 'Department is required',
            ],
            items: [],
            customFilter: (item, queryText) => {
              const label = item.label.toLowerCase();
              const searchText = queryText.toLowerCase();
              return label.indexOf(searchText) > -1;
            },
            value: '',
          },
        ],
      },
    };
  },
  async created() {
    this.questionsMetadata.questionRawList = await questionService.getList();
    this.navigationDrawerFormSelectionMetadata.items = await formService.getList();
    this.userChoices = await userService.getList();

    const departmentsWidget = this.formData.widgets.find((w) => w.name === 'departments');
    departmentsWidget.items = await departmentService.getList();

    const organizationsWidget = this.formData.widgets.find((w) => w.name === 'organizations');
    organizationsWidget.items = await organizationService.getList();

    const consentDocumentWidget = this.formData.widgets.find((w) => w.name === 'consentDocuments');
    consentDocumentWidget.items = (await consentDocumentService.getList())
      .map((document) => ({ code: document._id, label: document.name }));

    if (this.formData.model) {
      this.formData.widgets.forEach((w) => {
        const widget = w;
        const getValue = (key) => {
          if (key.includes('date')) {
            return new Date(this.formData.model[key]);
          }
          return this.formData.model[widget.name];
        };
        widget.value = getValue(widget.name);
      });
      // prepare previously selected medical forms
      this.formData.model.medicalForms.forEach((formId) => {
        const form = this.navigationDrawerFormSelectionMetadata
          .items
          .find((f) => f._id === formId);
        this.formSelectedTableMetadata.items.push(form);
      });
    }
    const principalInvestigator = this.formData.widgets.find((w) => w.name === 'principalInvestigators');
    principalInvestigator.items = this.userToChoices();

    const researcher = this.formData.widgets.find((w) => w.name === 'researchers');
    researcher.items = this.userToChoices();

    const collaborator = this.formData.widgets.find((w) => w.name === 'collaborators');
    collaborator.items = this.userToChoices();
  },
  methods: {
    userToChoices() {
      return this.userChoices.map(
        (user) => ({
          code: user._id,
          label: `${user.lastName}, ${user.firstName} ${user.middleName}`.trim(),
        }),
      );
    },
    getFormDataJSON() {
      const data = FormService.dataAsJSON(this.formData);
      // eslint-disable-next-line no-underscore-dangle
      data.medicalForms = this.formSelectedTableMetadata.items.map((f) => f._id);
      return data;
    },
    onSave() {
      if (this.$refs.form.validate() && this.formSelectedTableMetadata.items.length > 0) {
        const dataChanges = this.getFormDataJSON();
        const data = Object.assign(this.protocolNumber || {}, dataChanges);
        this.onSubmit(data);
      } if (this.formSelectedTableMetadata.items.length === 0) {
        this.$confirmDialog.show({
          title: 'Save Error',
          text: `
            <ul>
              <li>Must have at least one medical form selected</li>
            </ul>
          `,
          isMessage: true,
        });
      }
    },
    onClickShowFormSelection() {
      this.isSelectingForms = true;
    },
    onClickAddSelectedForms() {
      const selected = [...this.navigationDrawerFormSelectionMetadata.selected];
      this.navigationDrawerFormSelectionMetadata.selected = [];

      selected.forEach((form) => {
        const isPresent = this.formSelectedTableMetadata.items.find((f) => f._id === form._id);
        if (!isPresent) {
          this.formSelectedTableMetadata.items.push(form);
        }
      });
      this.isSelectingForms = false;
    },
    onClickDeleteSelectedForm(form) {
      this.$confirmDialog.show({
        text: `<p>Are you sure you want to delete: <b>${form.name}</b></p>`,
        title: 'Confirm Delete',
      }, () => {
        // eslint-disable-next-line no-underscore-dangle
        const filtered = this.formSelectedTableMetadata.items.filter((f) => f._id !== form._id);
        this.formSelectedTableMetadata.items = filtered;
        this.$snackbar.show({ color: 'green', text: 'Deleted successfully', closeColor: 'white' });
      });
    },
    onClickViewFormPreview(item) {
      this.isPreviewFormShown = true;
      this.formMetadata = item;
    },
  },
};
</script>

<style scoped>
.close {
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
}
.text {
  font-weight: bold;
  margin: 20px;
}
</style>
