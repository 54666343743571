<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <loader :isLoading="isLoading"></loader>
    <view-component v-if="attributes" :attributes="attributes"></view-component>
  </v-container>
</template>

<!-- eslint-disable no-underscore-dangle -->
<script>
import Loader from '../../../components/utilities/Loader.vue';
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import ViewComponent from '../../../components/ViewComponent.vue';

import services from '../../../services';

const { controlPanelRegistry } = services;
const {
  permissionService,
  userService,
} = controlPanelRegistry;

export default {
  components: {
    Loader,
    LandingCreate,
    ViewComponent,
  },
  data: () => ({
    isLoading: false,
    attributes: null,
    content: {
      pageTitle: 'Permission View',
      enableBackButton: true,
    },
  }),
  async created() {
    this.isLoading = true;

    const { id } = this.$route.params;
    const permission = await permissionService.getOne({ id });

    const createdBy = await userService.getOne({ id: permission.createdBy });
    const updatedBy = await userService.getOne({ id: permission.updatedBy });

    const mapUser = (user) => ({ id: user._id, name: `${user.firstName}, ${user.lastName}` });

    this.attributes = this.prepareAttribute({
      ...permission,
      createdBy: mapUser(createdBy),
      updatedBy: mapUser(updatedBy),
    });

    this.isLoading = false;
  },
  methods: {
    prepareAttribute({
      name, code, category, module, createdBy, dateCreated, updatedBy, dateUpdated,
    }) {
      return [
        {
          label: 'Name',
          value: name,
        },
        {
          label: 'Code',
          value: code,
        },
        {
          label: 'Category',
          value: category,
        },
        {
          label: 'Module',
          value: module,
        },
        {
          label: 'Created by',
          value: { id: createdBy.id, name: createdBy.name },
          reference: true,
          URLName: 'user.view',
        },
        {
          label: 'Created date',
          value: dateCreated,
          type: 'datetime',
        },
        {
          label: 'Updated by',
          value: { id: updatedBy.id, name: updatedBy.name },
          reference: true,
          URLName: 'user.view',
        },
        {
          label: 'Updated date',
          value: dateUpdated,
          type: 'datetime',
        },
      ];
    },
  },
};
</script>
