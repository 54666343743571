var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('loading', {
    attrs: {
      "active": _vm.isLoading,
      "can-cancel": _vm.canCancel,
      "is-full-page": _vm.isFullPage,
      "color": _vm.color,
      "loader": _vm.loaderStyle
    },
    on: {
      "update:active": function ($event) {
        _vm.isLoading = $event;
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }