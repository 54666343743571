import axios from 'axios';

import AuthService from './auth.service';

import medicalRegistry from './medical_registry';
import biobankRegistry from './biobank_registry';
import controlPanelRegistry from './control_panel';

axios.defaults.baseURL = process.env.VUE_APP_API_BIOBANK;
axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;

axios.interceptors.request.use((config) => {
  const refConfig = config;
  refConfig.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

export default {
  authService: new AuthService({ axios, storage: localStorage }),
  medicalRegistry: medicalRegistry.medicalRegistry({ axios }),
  biobankRegistry: biobankRegistry.biobankRegistry({ axios }),
  controlPanelRegistry: controlPanelRegistry.controlPanelRegistry({ axios }),
};
