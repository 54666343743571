var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.formMetaData.questionList, function (question, index) {
    return _c('div', {
      key: question.name + index
    }, [question.display === 'question' ? [_c('widget-component', {
      attrs: {
        "widgetMetadata": question
      }
    })] : _vm._e(), question.display === 'section' ? [_c('v-card', {
      staticStyle: {
        "margin-bottom": "15px"
      }
    }, [_c('v-card-title', [_vm._v(_vm._s(question.title))]), _c('v-card-text', [_c('nested-display-form', {
      attrs: {
        "formMetaData": {
          questionList: question.widgets
        }
      }
    })], 1)], 1)] : _vm._e()], 2);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }