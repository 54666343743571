import UserService from './user.service';
import RoleService from './role.service';
import PermissionService from './permission.service';
import DepartmentService from './department.service';
import OrganizationService from './organization.service';
import CategoryService from './category.service';
import ModuleService from './module.service';

export default {
  controlPanelRegistry: ({ axios }) => ({
    userService: new UserService({ axios }),
    roleService: new RoleService({ axios }),
    permissionService: new PermissionService({ axios }),
    departmentService: new DepartmentService(),
    organizationService: new OrganizationService(),
    categoryService: new CategoryService(),
    moduleService: new ModuleService(),
  }),
};
