<template>
  <v-container>
    <loader :isLoading="isLoading"></loader>
    <form-component
      :formData="formData"
    >
    </form-component>
  </v-container>
</template>

<script>
import Loader from '../../../components/utilities/Loader.vue';
import FormComponent from '../../../components/ui/FormComponent.vue';

import services from '../../../services';

const { controlPanelRegistry } = services;
const {
  categoryService,
  moduleService,
} = controlPanelRegistry;

export default {
  name: 'PermissionForm',
  components: {
    FormComponent,
    Loader,
  },
  props: {
    onSubmit: Function,
    isLoading: Boolean,
    permission: Object,
  },
  computed: {
    moduleSelections() {
      return this.modules;
    },
    categoryValue() {
      return this.formData.widgets[2].value;
    },
  },
  watch: {
    categoryValue(newVal) {
      this.onCategoryChanged(newVal);
    },
  },
  data() {
    return {
      modules: [],
      formData: {
        onSubmit: this.onSubmit,
        model: this.permission,
        widgets: [
          {
            type: 'text',
            name: 'name',
            label: 'Name',
            value: '',
            counter: 32,
            maxLength: 32,
          },
          {
            type: 'text',
            name: 'code',
            label: 'Code',
            value: '',
            counter: 32,
            maxLength: 32,
          },
          {
            type: 'select',
            name: 'category',
            label: 'Category',
            placeholder: 'Select Category',
            multiple: false,
            rules: [
              (v) => !!v || 'Category is required',
            ],
            items: [],
            value: '',
          },
          {
            type: 'select',
            name: 'module',
            label: 'Module',
            placeholder: 'Select Module',
            multiple: false,
            rules: [
              (v) => !!v || 'Midule is required',
            ],
            items: [],
            value: '',
          },
          // {
          //   type: 'file',
          //   name: 'document',
          //   label: 'Document',
          //   multiple: false,
          //   accept: '.pdf',
          //   value: null,
          // },
          {
            type: 'button',
            btnType: 'submit',
            name: 'save',
            label: 'Save',
            color: 'primary',
            value: null,
          },
        ],
      },
    };
  },
  async created() {
    this.modules = await moduleService.getList();
    const categoryWidget = this.formData.widgets.find((w) => w.name === 'category');
    categoryWidget.items = await categoryService.getList();
    if (this.permission) {
      this.onCategoryChanged(this.permission.category);
    }
  },
  methods: {
    onCategoryChanged(newVal) {
      const category = newVal;
      this.formData.widgets[3].items = this.modules.filter((item) => item.category === category);
    },
  },
};
</script>
