<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <loader :isLoading="isLoading"></loader>
    <view-component v-if="attributes" :attributes="attributes"></view-component>
  </v-container>
</template>

<style>
  .redirect {
    color: #2196F3 !important;
    cursor: pointer;
  }
</style>

<!-- eslint-disable no-underscore-dangle -->
<script>
import Loader from '../../../components/utilities/Loader.vue';
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import ViewComponent from '../../../components/ViewComponent.vue';

import services from '../../../services';

const { controlPanelRegistry } = services;
const {
  roleService,
  userService,
} = controlPanelRegistry;

export default {
  components: {
    Loader,
    LandingCreate,
    ViewComponent,
  },
  data: () => ({
    isLoading: false,
    attributes: null,
    content: {
      pageTitle: 'User View',
      enableBackButton: true,
    },
    categories: [],
  }),
  async created() {
    this.isLoading = true;

    const { id } = this.$route.params;
    const user = await userService.getOne({ id });
    console.log(user);
    const roles = await Promise.all(user.roles.map((rID) => roleService.getOne({ id: rID })));

    const mapUser = (_user, date) => ({ id: _user._id, name: `${_user.firstName}, ${_user.lastName}`, date });

    const createdBy = await userService.getOne({ id: user.createdBy });
    // @TODO: Updated By is not present anywhere
    //        this metadata must also be tracked
    //        Updated By is the person who made last edit to user
    const updatedBy = await userService.getOne({ id: user.updatedBy });
    const activatedBy = await userService.getOne({ id: user.activatedBy });

    this.attributes = this.prepareAttribute({
      ...user,
      createdBy: mapUser(createdBy, user.createdAt),
      updatedBy: mapUser(updatedBy, user.dateUpdated),
      activatedBy: mapUser(activatedBy, user.dateActivated),
      roles,
    });

    this.isLoading = false;
  },
  methods: {
    prepareAttribute({
      email, firstName, middleName, lastName, isVerified,
      dateVerified, createdBy, updatedBy, activatedBy,
    }) {
      return [
        {
          label: 'Email',
          value: email,
        },
        {
          label: 'First Name',
          value: firstName,
        },
        {
          label: 'Middle Name',
          value: middleName,
        },
        {
          label: 'Last Name',
          value: lastName,
        },
        {
          label: 'Verified Status',
          value: isVerified ? 'VERIFIED' : 'NOT VERIFIED',
        },
        {
          label: 'Date Verified',
          value: dateVerified,
          type: 'datetime',
        },
        {
          label: 'Created by',
          value: { id: createdBy.id, name: createdBy.name },
          reference: true,
          URLName: 'user.view',
        },
        {
          label: 'Created date',
          value: createdBy.date,
          type: 'datetime',
        },
        {
          label: 'Updated date',
          value: updatedBy.date,
          type: 'datetime',
        },
        {
          label: 'Activated by',
          value: { id: activatedBy.id, name: activatedBy.name },
          reference: true,
          URLName: 'user.view',
        },
        {
          label: 'Activated date',
          value: activatedBy.date,
          type: 'datetime',
        },
      ];
    },
  },
};
</script>
