<template>
  <div class="">
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="color"
    >
      <div
        v-html="text"
      >
      </div>

      <template v-slot:action="{ attrs }" v-if="showClose">
        <v-btn
          :color="closeColor"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import Vue from 'vue';

export default {
  data() {
    return {
      snackbar: false,
      showClose: true,
      text: '',
      timeout: 4000,
      closeColor: 'pink',
      color: undefined,
      iconClass: '',
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (typeof (Vue.prototype.snackbar) !== 'object') {
        Vue.prototype.$snackbar = {};
      }

      Vue.prototype.$snackbar = this;
    },
    showOk({ text }) {
      this.show({
        showClose: Boolean,
        text,
        timeout: 4000,
        closeColor: 'white',
        color: 'green',
      });
    },
    showBad({ text }) {
      this.show({
        showClose: Boolean,
        text,
        timeout: 4000,
        closeColor: 'white',
        color: 'red',
      });
    },
    show(options = {}) {
      // let icon = options.iconClass ? `<i class="${options.iconClass}"></i>` : '';

      this.showClose = options.showClose ?? true;
      this.text = options.text ? this.iconClass + options.text : '';
      this.timeout = options.timeout ?? 4000;
      this.closeColor = options.closeColor ?? 'pink';
      this.iconClass = options.iconClass ?? '';
      this.color = options.color ?? undefined;

      this.snackbar = true;
    },
    hide() {
      this.snackbar = false;
    },
  },
};

</script>
