var render = function () {
  var _vm$mutualTransferAgr;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), (_vm$mutualTransferAgr = _vm.mutualTransferAgreement) !== null && _vm$mutualTransferAgr !== void 0 && _vm$mutualTransferAgr._id ? [_c('v-row', {
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "blue",
      "dark": "",
      "text": ""
    },
    on: {
      "click": _vm.onClickViewDocument
    }
  }, [_vm._v(" View Document ")])], 1)], 1)] : _vm._e(), _c('form-component', {
    attrs: {
      "formData": _vm.formData
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }