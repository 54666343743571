<template>
  <v-container>
    <loader :isLoading="isLoading"></loader>
    <template v-if="consentDocument?._id">
      <v-row justify="end">
        <v-col md="2">
          <v-btn
            color="blue"
            dark
            text
            @click="onClickViewDocument"
          >
            View Document
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <form-component
      :formData="formData"
    >
    </form-component>
  </v-container>
</template>

<!-- eslint-disable no-underscore-dangle -->
<script>
import Loader from '../../../components/utilities/Loader.vue';
import FormComponent from '../../../components/ui/FormComponent.vue';
// import WidgetComponent from '../../../components/ui/WidgetComponent.vue';

export default {
  name: 'ConsentDocumentForm',
  components: {
    Loader,
    // WidgetComponent,
    FormComponent,
  },
  props: {
    onSubmit: Function,
    isLoading: Boolean,
    consentDocument: Object,
  },
  data() {
    const documentId = this.consentDocument?._id
      ? `${this.consentDocument?.document?._id}.pdf`
      : '';
    return {
      formData: {
        onSubmit: this.onSubmit,
        model: this.consentDocument,
        widgets: [
          {
            type: 'text',
            name: 'name',
            label: 'Name',
            value: '',
            counter: 50,
            maxLength: 50,
            rules: [
              (v) => !!v || 'Name is required',
            ],
          },
          {
            type: 'textarea',
            name: 'description',
            label: 'Description',
            value: '',
            counter: 200,
            maxLength: 200,
            rules: [
              (v) => !!v || 'Description is required',
            ],
          },
          {
            type: 'file',
            name: 'attachment',
            // eslint-disable-next-line no-underscore-dangle
            label: documentId,
            multiple: false,
            accept: '.pdf',
            ...(!this.consentDocument?._id && { maxNumberOfFiles: 1 }),
            value: null,
          },
          {
            type: 'button',
            btnType: 'submit',
            name: 'save',
            label: 'Save',
            color: 'primary',
            value: null,
          },
        ],
      },
    };
  },
  methods: {
    redirectToViewDocument() {
      window.open(this.consentDocument.document.url, '_blank');
    },
    onClickViewDocument() {
      this.redirectToViewDocument();
    },
  },
};
</script>
