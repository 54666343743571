<template>
  <div>
    <v-dialog
      v-model="dialog"
      :persistent="presistent"
      max-width="290"
    >
      <v-card>
        <v-card-title
          class="text-h6"
        >
          <div v-html="title"></div>
        </v-card-title>
        <v-card-text>
          <div v-html="text"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            dark
            text
            @click="runCallback"
          >
            {{ okayText }}
          </v-btn>
          <v-btn
            color="primary"
            text
            v-if="!isMessage"
            @click="dialog = false"
          >
            {{ cancelText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import Vue from 'vue';

export default {
  mounted() {
    this.init();
  },
  data() {
    return {
      dialog: false,
      presistent: false,
      title: 'Confirm',
      text: '',
      cancelText: 'Cancel',
      okayText: 'Okay',
      isMessage: false,
      callback: null,
    };
  },
  methods: {
    init() {
      if (typeof (Vue.prototype.confirmDialog) !== 'object') {
        Vue.prototype.$confirmDialog = {};
      }

      Vue.prototype.$confirmDialog = this;
    },
    show(options = {}, callback) {
      const {
        title, text, presistent, okayText, cancelText, isMessage,
      } = options;

      this.title = title ?? '';
      this.text = text ?? '';
      this.presistent = presistent ?? false;
      this.okayText = okayText ?? 'Confirm';
      this.cancelText = cancelText ?? 'Cancel';
      this.isMessage = isMessage ?? false;

      this.dialog = true;

      if (typeof callback === 'function') {
        this.callback = callback;
      }
    },
    runCallback() {
      this.dialog = false;
      if (typeof this.callback === 'function') {
        this.callback();
      }
    },
  },
};

</script>
