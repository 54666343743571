var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('landing-create', {
    attrs: {
      "content": _vm.createRoleContent
    }
  }), _c('landing-table-action', {
    attrs: {
      "tablemetadata": _vm.tableMetaData,
      "tablecontent": _vm.tableContent
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }