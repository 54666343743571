<template>
  <v-container>
    <v-form ref="form" @submit.prevent="onSave">
      <loader :isLoading="isLoading"></loader>
      <widget-component v-for="(widgetMetadata, index) in widgets"
        :key="`widget${index}`"
        :widgetMetadata="widgetMetadata">
      </widget-component>
      <slot></slot>
      <widget-component v-for="(widgetMetadata, index) in widgetButtons"
        :key="`widget_btns${index}`"
        :widgetMetadata="widgetMetadata">
      </widget-component>
    </v-form>
  </v-container>
</template>

<!-- eslint-disable no-underscore-dangle -->
<script>
import Loader from '../utilities/Loader.vue';
import WidgetComponent from './WidgetComponent.vue';

import FormService from '../../services/medical_registry/form.service';

export default {
  name: 'FormComponent',
  components: {
    WidgetComponent,
    Loader,
  },
  props: {
    isLoading: Boolean,
    formData: {
      widgets: {
        type: Array,
        default: () => [],
      },
      isFormData: Boolean,
      model: Object,
      onSubmit: Function,
    },
  },
  computed: {
    widgets() {
      return this.formData.widgets.filter((widget) => widget.type !== 'button');
    },
    widgetButtons() {
      return this.formData.widgets.filter((widget) => widget.type === 'button');
    },
  },
  created() {
    if (this.formData.model) {
      this.formData.widgets.forEach((w) => {
        const widget = w;
        widget.value = this.getModelValue(widget);
      });
    }
  },
  methods: {
    getModelValue(widget) {
      const modelValue = this.formData.model[widget.name];
      if (typeof modelValue === 'object'
        && !Array.isArray(modelValue)
        && modelValue !== null
      ) {
        return modelValue._id;
      }
      return modelValue;
    },
    dataAsFormData() {
      return FormService.dataAsFormData(this.formData);
    },
    dataAsJSON() {
      return FormService.dataAsJSON(this.formData);
    },
    validate() {
      return this.$refs.form.validate();
    },
    onSave() {
      if (this.validate()) {
        const data = !this.formData.isFormData ? this.dataAsJSON() : this.dataAsFormData();
        this.formData.onSubmit(data);
        // [...data.entries()].forEach((row) => console.log(row));
      }
    },
  },
};
</script>
