var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('landing-create', {
    attrs: {
      "content": _vm.content
    }
  }), _vm.specimenState ? [_c('specimen-state-form', {
    attrs: {
      "isLoading": _vm.isLoading,
      "specimenState": _vm.specimenState,
      "onSubmit": _vm.onSubmit
    }
  })] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }