<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <specimen-state-form
      :isLoading="isLoading"
      :onSubmit="onSubmit">
    </specimen-state-form>
  </v-container>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import SpecimenStateForm from './SpecimenStateForm.vue';
import services from '../../../services';

const { biobankRegistry } = services;
const { specimenStateService } = biobankRegistry;

export default {
  name: 'SpecimenStateCreate',
  components: {
    SpecimenStateForm,
    LandingCreate,
  },
  data: () => ({
    isLoading: false,
    content: {
      pageTitle: 'Create Specimen State',
      enableBackButton: true,
    },
  }),
  methods: {
    async onSubmit(data) {
      this.isLoading = true;
      try {
        const { _id: id } = await specimenStateService.create({ specimenState: data });

        this.$snackbar.showOk({ text: 'Successfully created Specimen State!' });

        this.$router.replace({ name: 'specimen-state.edit', params: { id } });
      } catch (e) {
        console.error(e);
      }
      this.isLoading = false;
    },
  },
};
</script>
