class CaseSpecimenTransactionService {
  constructor({ axios }) {
    this.axios = axios;
    this.url = '/api/biobank/specimen-cases/transactions';
  }

  async upsert({ specimenId, transaction }) {
    if (transaction.id) {
      return this.update({ specimenId, transaction });
    }
    return this.create({ specimenId, transaction });
  }

  async create({ specimenId, transaction }) {
    const { data } = await this.axios.post(`${this.url}/${specimenId}`, transaction);
    return data;
  }

  async update({ specimenId, transaction }) {
    const { data } = await this.axios.put(`${this.url}/${specimenId}`, transaction);
    return data;
  }

  async delete({ specimenId, transactionId }) {
    const { data } = await this.axios.delete(`${this.url}/${specimenId}/${transactionId}`);
    return data;
  }
}

export default CaseSpecimenTransactionService;
