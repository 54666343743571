class UserService {
  constructor({ axios }) {
    this.axios = axios;
  }

  async getList({ query = '', dates = [] } = {}) {
    const result = await this.axios.get('/api/users', {
      params: {
        query,
        ...(Object.keys(dates).length > 0 && dates),
      },
    });
    const { data } = result;
    return data;
  }

  async getOne({ id }) {
    const { data } = await this.axios.get(`/api/users/${id}`);
    return data;
  }

  async create({ user }) {
    const { data } = await this.axios.post('/api/users', user);
    return data;
  }

  async update({ user }) {
    const { data } = await this.axios.put('/api/users', user);
    return data;
  }

  async changePassword({ passwordConfirmation, passwordCurrent, password }) {
    const { data } = await this.axios.post('/api/users/change-password',
      {
        passwordConfirmation,
        passwordCurrent,
        password,
      });
    return data;
  }

  activate({ id }) {
    return this.axios.patch(`/api/users/activate/${id}`, { id });
  }

  deactivate({ id }) {
    return this.axios.patch(`/api/users/deactivate/${id}`);
  }
}

export default UserService;
