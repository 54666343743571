<template>
  <div>
    <landing-create :content="content"></landing-create>
    <list-component
      :service="service"
      :tableMetaData="tableMetaData"
    ></list-component>
  </div>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import ListComponent from '../../../components/ListComponent.vue';
import services from '../../../services';

const { biobankRegistry } = services;
const { specimenStateService } = biobankRegistry;

export default {
  name: 'SpecimenStateList',
  components: {
    ListComponent,
    LandingCreate,
  },
  data: () => ({
    service: specimenStateService,
    tableMetaData: {
      tableFilters: {
        selections: [],
        dates: [
          {
            text: 'Date created',
            column: 'dateCreated',
          },
          {
            text: 'Date updated',
            column: 'dateUpdated',
          },
        ],
      },
      tableHeaders: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Created by',
          value: '_metadata.created.user.fullName',
        },
        {
          text: 'Date created',
          value: '_metadata.created.date',
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ],
      URLName: 'specimen-state',
    },
    content: {
      pageTitle: 'Specimen State',
      commands: [
        {
          link: 'specimen-state.create',
          icon: 'mdi-plus-box',
          buttonName: 'Create',
        },
      ],
    },
  }),
};
</script>
