var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('v-snackbar', {
    attrs: {
      "timeout": _vm.timeout,
      "color": _vm.color
    },
    scopedSlots: _vm._u([_vm.showClose ? {
      key: "action",
      fn: function (_ref) {
        var attrs = _ref.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "color": _vm.closeColor,
            "text": ""
          },
          on: {
            "click": function ($event) {
              _vm.snackbar = false;
            }
          }
        }, 'v-btn', attrs, false), [_vm._v(" Close ")])];
      }
    } : null], null, true),
    model: {
      value: _vm.snackbar,
      callback: function ($$v) {
        _vm.snackbar = $$v;
      },
      expression: "snackbar"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.text)
    }
  })])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }