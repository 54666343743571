var render = function () {
  var _this$widgetMetadata,
      _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return ['text', 'number'].includes(_vm.widgetMetadata.type) ? _c('v-text-field', {
    attrs: {
      "name": _vm.widgetMetadata.name,
      "counter": _vm.widgetMetadata.counter,
      "label": _vm.widgetMetadata.label,
      "placeholder": _vm.widgetMetadata.placeholder,
      "maxlength": _vm.widgetMetadata.maxlength,
      "rules": !_vm.widgetMetadata.readonly ? _vm.widgetMetadata.rules : [],
      "type": _vm.widgetMetadata.type,
      "min": _vm.widgetMetadata.min,
      "max": _vm.widgetMetadata.max,
      "prefix": _vm.widgetMetadata.prefix,
      "suffix": _vm.widgetMetadata.suffix,
      "disabled": _vm.widgetMetadata.readonly,
      "outlined": "",
      "dense": "",
      "clearable": ""
    },
    on: {
      "change": _vm.onTextChange
    },
    model: {
      value: _vm.widgetMetadata.value,
      callback: function ($$v) {
        _vm.$set(_vm.widgetMetadata, "value", $$v);
      },
      expression: "widgetMetadata.value"
    }
  }) : _vm.widgetMetadata.type === 'textarea' ? _c('v-textarea', {
    attrs: {
      "name": _vm.widgetMetadata.name,
      "counter": _vm.widgetMetadata.counter,
      "label": _vm.widgetMetadata.label,
      "placeholder": _vm.widgetMetadata.placeholder,
      "maxlength": _vm.widgetMetadata.maxlength,
      "rules": !_vm.widgetMetadata.readonly ? _vm.widgetMetadata.rules : [],
      "readonly": _vm.widgetMetadata.readonly,
      "outlined": "",
      "dense": "",
      "clearable": ""
    },
    model: {
      value: _vm.widgetMetadata.value,
      callback: function ($$v) {
        _vm.$set(_vm.widgetMetadata, "value", $$v);
      },
      expression: "widgetMetadata.value"
    }
  }) : _vm.widgetMetadata.type === 'switch' ? _c('v-switch', {
    attrs: {
      "name": _vm.widgetMetadata.name,
      "label": _vm.widgetMetadata.label
    },
    model: {
      value: _vm.widgetMetadata.value,
      callback: function ($$v) {
        _vm.$set(_vm.widgetMetadata, "value", $$v);
      },
      expression: "widgetMetadata.value"
    }
  }) : _vm.widgetMetadata.type === 'checkbox' ? _c('v-card', {
    staticClass: "pa-2",
    staticStyle: {
      "margin": "0px 0px 20px 0px"
    },
    attrs: {
      "outlined": ""
    }
  }, [_c('label', {
    staticStyle: {
      "color": "rgba(0, 0, 0, 0.6)"
    }
  }, [_vm._v(" " + _vm._s(_vm.widgetMetadata.label) + " "), _vm.widgetMetadata.placeholder ? _c('small', [_vm._v("- " + _vm._s(_vm.widgetMetadata.placeholder))]) : _vm._e()]), _vm._l(_vm.widgetMetadata.items, function (item, i) {
    return _c('div', {
      key: 'cb' + i
    }, [_c('v-checkbox', {
      attrs: {
        "label": item.label,
        "value": item.code,
        "readonly": _vm.widgetMetadata.readonly
      },
      model: {
        value: _vm.widgetMetadata.value,
        callback: function ($$v) {
          _vm.$set(_vm.widgetMetadata, "value", $$v);
        },
        expression: "widgetMetadata.value"
      }
    })], 1);
  })], 2) : _vm.widgetMetadata.type === 'select' ? _c('v-autocomplete', {
    attrs: {
      "chips": "",
      "name": _vm.widgetMetadata.name,
      "label": _vm.widgetMetadata.label,
      "placeholder": _vm.widgetMetadata.placeholder,
      "items": _vm.widgetMetadata.items,
      "filter": _vm.customFilter,
      "multiple": _vm.widgetMetadata.multiple,
      "readonly": _vm.widgetMetadata.readonly,
      "rules": !_vm.widgetMetadata.readonly ? _vm.widgetMetadata.rules : [],
      "item-value": "code",
      "outlined": "",
      "dense": !_vm.widgetMetadata.dense,
      "clearable": ""
    },
    on: {
      "change": function (code) {
        return _vm.selectChange(code);
      }
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm.widgetMetadata.chip ? _c('v-chip', [item.icon ? _c('v-icon', [_vm._v(_vm._s(item.icon))]) : _vm._e(), _vm._v(" " + _vm._s(item.label) + " ")], 1) : [item.icon ? _c('v-icon', [_vm._v(_vm._s(item.icon))]) : _vm._e(), _vm._v(" " + _vm._s(item.label)), _c('br')]];
      }
    }, {
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item,
            attrs = _ref2.attrs;
        return [_c('span', [_vm.widgetMetadata.multiple ? _c('label', [attrs['aria-selected'] === 'true' ? _c('v-icon', [_vm._v("mdi-checkbox-marked")]) : _c('v-icon', [_vm._v("mdi-checkbox-blank-outline")])], 1) : _vm._e(), item.icon ? _c('v-icon', [_vm._v(_vm._s(item.icon))]) : _vm._e(), _vm._v(" " + _vm._s(item.label) + " ")], 1)];
      }
    }]),
    model: {
      value: _vm.widgetMetadata.value,
      callback: function ($$v) {
        _vm.$set(_vm.widgetMetadata, "value", $$v);
      },
      expression: "widgetMetadata.value"
    }
  }) : _vm.widgetMetadata.type === 'file' ? _c('span', [_c('v-file-input', {
    attrs: {
      "chips": "",
      "show-size": "",
      "truncate-length": "50",
      "name": _vm.widgetMetadata.name,
      "label": _vm.widgetMetadata.label,
      "placeholder": _vm.widgetMetadata.placeholder,
      "disabled": _vm.widgetMetadata.readonly,
      "multiple": "",
      "rules": !_vm.widgetMetadata.readonly && ((_this$widgetMetadata = this.widgetMetadata) === null || _this$widgetMetadata === void 0 ? void 0 : _this$widgetMetadata.maxNumberOfFiles) > 0 ? [function (v) {
        return v && v.length >= _this.widgetMetadata.maxNumberOfFiles || ("At least " + (_this.widgetMetadata.maxNumberOfFiles) + " attachment(s) is required");
      }] : [],
      "accept": _vm.widgetMetadata.accept,
      "clearable": ""
    },
    on: {
      "change": _vm.onFileChange
    },
    model: {
      value: _vm.widgetMetadata.value,
      callback: function ($$v) {
        _vm.$set(_vm.widgetMetadata, "value", $$v);
      },
      expression: "widgetMetadata.value"
    }
  }), _vm.widgetMetadata.imageURL ? [_c('img', {
    staticStyle: {
      "max-width": "800px",
      "max-height": "400px"
    },
    attrs: {
      "src": _vm.widgetMetadata.imageURL
    }
  })] : _vm._e()], 2) : ['date', 'time'].includes(_vm.widgetMetadata.type) ? _c('div', [_c('section', {
    staticStyle: {
      "margin": "20px 0px 20px 0px"
    }
  }, [_c('h4', [_vm._v(_vm._s(_vm.widgetMetadata.label))]), _c('date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": _vm.widgetMetadata.type,
      "placeholder": _vm.widgetMetadata.placeholder,
      "range": _vm.widgetMetadata.range,
      "disabled": _vm.widgetMetadata.readonly
    },
    model: {
      value: _vm.widgetMetadata.value,
      callback: function ($$v) {
        _vm.$set(_vm.widgetMetadata, "value", $$v);
      },
      expression: "widgetMetadata.value"
    }
  })], 1)]) : _vm.widgetMetadata.type === 'button' ? _c('v-btn', {
    class: _vm.widgetMetadata.class,
    attrs: {
      "depressed": "",
      "color": _vm.widgetMetadata.color,
      "type": _vm.widgetMetadata.btnType,
      "clearable": "",
      "dark": _vm.widgetMetadata.dark
    },
    on: {
      "click": _vm.onClick
    }
  }, [_vm._v(" " + _vm._s(_vm.widgetMetadata.label) + " ")]) : _c('div', [_vm.widgetMetadata.type ? [_vm._v(" Not supported: " + _vm._s(_vm.widgetMetadata.type) + " ")] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }