<template>
  <v-container>
    <landing-create
      :content="createQuestionContent"
    >
    </landing-create>
    <question-form
      v-if="question"
      :isLoading="isLoading"
      :question="question"
      :onSubmit="onSubmit"
    >
    </question-form>
  </v-container>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import QuestionForm from './QuestionForm.vue';
import services from '../../../services';

const { medicalRegistry } = services;
const { questionService } = medicalRegistry;

export default {
  name: 'QuestionUpdate',
  components: {
    QuestionForm,
    LandingCreate,
  },
  data: () => ({
    isLoading: false,
    createQuestionContent: {
      pageTitle: 'Update Question',
      enableBackButton: true,
    },
    question: null,
  }),
  async created() {
    const { id } = this.$route.params;
    const question = await questionService.getOne({ id });
    this.question = question;
  },
  methods: {
    async onSubmit(data) {
      this.isLoading = true;
      try {
        await questionService.update({ question: data });

        this.$snackbar.show({
          showClose: Boolean,
          text: 'Successfully updated question!',
          timeout: 4000,
          closeColor: 'white',
          color: 'green',
        });
        this.$router.push({ name: 'question.list' });
      } catch (e) {
        console.error(e);
      }
      this.isLoading = false;
    },
  },
};
</script>
