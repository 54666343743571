var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "generic-container"
  }, [_c('loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('page-header', {
    attrs: {
      "headerTitle": _vm.headerTitle
    }
  }), _c('v-card', [_c('v-row', {}, [_c('v-col', {
    staticClass: "role-section",
    attrs: {
      "cols": "6",
      "md": "4",
      "xl": "3"
    }
  }, [_c('form', {
    staticStyle: {
      "background-color": "rd"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('v-row', [_c('h2', {
    staticClass: "role-form-subheader"
  }, [_vm._v("Change Password")])]), _c('br'), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "Current Password",
      "type": "password",
      "required": "",
      "name": "passwordCurrent"
    },
    model: {
      value: _vm.passwordCurrent,
      callback: function ($$v) {
        _vm.passwordCurrent = $$v;
      },
      expression: "passwordCurrent"
    }
  }), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "New Password",
      "type": "password",
      "required": "",
      "name": "password"
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "New Password",
      "type": "password",
      "required": "",
      "name": "passwordConfirmation"
    },
    model: {
      value: _vm.passwordConfirmation,
      callback: function ($$v) {
        _vm.passwordConfirmation = $$v;
      },
      expression: "passwordConfirmation"
    }
  }), _c('br'), _c('v-btn', {
    staticClass: "mr-4 primary",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v(" Submit ")])], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }