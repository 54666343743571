import jwtDecode from 'jwt-decode';
import Vue from 'vue';

Vue.directive('has-any-permission', {
  bind: (el, binding) => {
    const userPermissions = localStorage.permissions ? JSON.parse(localStorage.permissions) : [];
    const allowedPermissions = Array.isArray(binding.value) ? binding.value : [binding.value];

    function arrayCompare(_arr1, _arr2) {
      let result = false;
      // eslint-disable-next-line padded-blocks
      _arr2.forEach((allowed) => {
        const has = _arr1.find((permission) => allowed === permission);
        if (has) {
          result = true;
        }
        return has;
      });

      return result;
    }
    console.log(arrayCompare(userPermissions, allowedPermissions));
    if (!arrayCompare(userPermissions, allowedPermissions)) {
      el.style.display = 'none';
    }
  },

});

class AuthService {
  constructor({ axios, storage }) {
    this.axios = axios;
    this.storage = storage;
  }

  getSignedInUser() {
    return JSON.parse(this.storage.getItem('user'));
  }

  doLogout() {
    this.storage.clear();
  }

  async doSignIn({ email, password }) {
    const { data } = await this.axios.post('/api/auth/sign-in', { email, password });
    const { jwt } = data;

    this.storage.setItem('token', jwt);

    const { context } = jwtDecode(jwt);
    const user = context;

    this.storage.setItem('user', JSON.stringify(context));
    const { data: _data } = await this.axios.get(`/api/users/self/${user.id}`);
    const { roles } = _data;

    const permissions = roles.flatMap((role) => role.permissions);

    // eslint-disable-next-line no-underscore-dangle
    const permissionId = permissions.map((p) => p._id);
    this.storage.setItem('permissions', JSON.stringify(permissionId));

    const permissionCode = permissions.map((p) => p.code);
    this.storage.setItem('permissionCode', JSON.stringify(permissionCode));

    return user;
  }

  getUserPermissionCode() {
    return JSON.parse(this.storage.getItem('permissionCode'));
  }

  async doForgotPassword({ email }) {
    const { data } = await this.axios.post('/api/auth/forgot-password', { email });

    return data;
  }

  async doResetPassword({ password, resetLink }) {
    const { data } = await this.axios.post('/api/auth/reset-password', { password, resetLink });

    return data;
  }

  async doVerify({ code, id }) {
    const { data } = await this.axios.post('/api/users/verify', { code, id });
    const user = JSON.parse(this.storage.getItem('user'));
    user.isVerified = true;
    this.storage.setItem('user', JSON.stringify(user));
    return data;
  }

  async resendVerification({ email, id }) {
    const { data } = await this.axios.post('/api/users/resend-verification', { id, email });

    return data;
  }
}

export default AuthService;
