import Vue from 'vue';
import Router from 'vue-router';
// import { mapGetters } from 'vuex';

import LoginPage from '../views/LoginPage.vue';
import ResetPassword from '../views/ResetPassword.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import LoginOtpPage from '../views/LoginOtpPage.vue';
import NotFound from '../views/NotFound.vue';

import HomePage from '../views/HomePage.vue';

import AccessMain from '../views/access/AccessMain.vue';
import UserMain from '../views/access/user/UserMain.vue';
import UserList from '../views/access/user/UserList.vue';
import UserCreate from '../views/access/user/UserCreate.vue';
import UserUpdate from '../views/access/user/UserUpdate.vue';
import UserView from '../views/access/user/UserView.vue';
import UserAccount from '../views/account/UserAccount.vue';
import ChangePassword from '../views/account/ChangePassword.vue';

import PermissionMain from '../views/access/permission/PermissionMain.vue';
import PermissionList from '../views/access/permission/PermissionList.vue';
import PermissionCreate from '../views/access/permission/PermissionCreate.vue';
import PermissionUpdate from '../views/access/permission/PermissionUpdate.vue';
import PermissionView from '../views/access/permission/PermissionView.vue';

import RoleMain from '../views/access/role/RoleMain.vue';
import RoleList from '../views/access/role/RoleList.vue';
import RoleCreate from '../views/access/role/RoleCreate.vue';
import RoleUpdate from '../views/access/role/RoleUpdate.vue';
import RoleView from '../views/access/role/RoleView.vue';

import MedicalMain from '../views/medical/MedicalMain.vue';

import BiobankMain from '../views/biobank/BiobankMain.vue';

// MAIN routes
import medical from './medical.router';
import biobank from './biobank.router';
// END routes

import DepartmentList from '../views/access/DepartmentList.vue';
import DatabaseList from '../views/access/DatabaseList.vue';
import store from '../vuex';

import middlewarePipeline from '../middleware/middlewarePipeline';
import auth from '../middleware/auth';

// import SidebarStandard from '../components/sidebars/SidebarStandard.vue';

Vue.use(Router);

const router = new Router({
  mode: 'hash',
  routes: [{
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  }, {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword,
  }, {
    path: '/',
    name: 'LoginPage',
    component: LoginPage,
  }, {
    path: '/otp',
    name: 'LoginOtpPage',
    component: LoginOtpPage,
  }, {
    path: '/home',
    name: 'HomePage',
    component: HomePage,
    meta: { middleware: [auth] },
  }, {
    path: '/access',
    name: 'AccessMain',
    component: AccessMain,
    meta: { middleware: [auth] },
    children: [{
      path: 'permission',
      name: 'permission',
      component: PermissionMain,
      meta: { middleware: [auth] },
      children: [
        {
          path: 'list',
          name: 'permission.list',
          component: PermissionList,
          meta: { middleware: [auth] },
        },
        {
          path: 'create',
          name: 'permission.create',
          component: PermissionCreate,
          meta: { middleware: [auth] },
        }, {
          path: 'update/:id',
          name: 'permission.edit',
          component: PermissionUpdate,
          meta: { middleware: [auth] },
        }, {
          path: 'view/:id',
          name: 'permission.view',
          component: PermissionView,
          meta: { middleware: [auth] },
        },
      ],
    }, {
      path: '/role',
      name: 'role',
      component: RoleMain,
      meta: { middleware: [auth] },
      children: [
        {
          path: 'list',
          name: 'role.list',
          component: RoleList,
          meta: { middleware: [auth] },
        },
        {
          path: 'create',
          name: 'role.create',
          component: RoleCreate,
          meta: { middleware: [auth] },
        }, {
          path: 'update/:id',
          name: 'role.edit',
          component: RoleUpdate,
          meta: { middleware: [auth] },
        }, {
          path: 'view/:id',
          name: 'role.view',
          component: RoleView,
          meta: { middleware: [auth] },
        },
      ],
    }, {
      path: '/user',
      name: 'user',
      component: UserMain,
      meta: { middleware: [auth] },
      children: [
        {
          path: 'list',
          name: 'user.list',
          component: UserList,
          meta: { middleware: [auth] },
        },
        {
          path: 'create',
          name: 'user.create',
          component: UserCreate,
          meta: { middleware: [auth] },
        }, {
          path: 'update/:id',
          name: 'user.edit',
          component: UserUpdate,
          meta: { middleware: [auth] },
        }, {
          path: 'view/:id',
          name: 'user.view',
          component: UserView,
          meta: { middleware: [auth] },
        },
      ],
    }, {
      path: 'department/list',
      name: 'DepartmentList',
      component: DepartmentList,
      meta: { middleware: [auth] },
    }, {
      path: 'database/list',
      name: 'DatabaseList',
      component: DatabaseList,
      meta: { middleware: [auth] },
    }],
  }, {
    path: '/medical',
    name: 'MedicalMain',
    component: MedicalMain,
    meta: { middleware: [auth] },
    children: medical.routes,
  }, {
    path: '/biobank',
    name: 'biobank',
    component: BiobankMain,
    meta: { middleware: [auth] },
    children: biobank.routes,
  }, {
    path: '/account/user',
    name: 'UserAccount',
    component: UserAccount,
    meta: { middleware: [auth] },
  }, {
    path: '/account/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: { middleware: [auth] },
  }, {
    path: '/:notFound(.*)',
    component: NotFound,
    meta: { middleware: [auth] },
  }],
});

router.beforeEach((to, from, next) => {
  if (!to.meta && !to.meta.middleware) {
    return next();
  }

  const middleware = to.meta && to.meta.middleware ? to.meta.middleware : [];

  const context = {
    // to,
    from,
    next,
    store,
  };

  middleware.forEach((obj, index) => {
    middleware[index]({ ...context, next: middlewarePipeline(context, middleware, 1) });
  });

  return next();
});

export default router;
