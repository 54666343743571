<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <case-form
      :onSubmit="onSubmit"
    ></case-form>
  </v-container>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import CaseForm from './CaseForm.vue';

import services from '../../../services';

const { medicalRegistry } = services;
const { caseService } = medicalRegistry;

export default {
  name: 'case-create',
  components: {
    LandingCreate,
    CaseForm,
  },
  data: () => ({
    isLoading: false,
    content: {
      pageTitle: 'Case Create',
      enableBackButton: true,
    },
  }),
  methods: {
    async onSubmit(data) {
      try {
        await caseService.create({ caseNumber: data });

        this.$snackbar.show({
          showClose: Boolean,
          text: 'Successfully created protocol number!',
          timeout: 4000,
          closeColor: 'white',
          color: 'green',
        });

        this.$router.push({ name: 'case.list' });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
