<template>
  <v-container>
    <landing-create :content="createQuestionContent"></landing-create>
    <question-form
      :onSubmit="onSubmit"
    ></question-form>
  </v-container>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import QuestionForm from './QuestionForm.vue';
import services from '../../../services';

const { medicalRegistry } = services;
const { questionService } = medicalRegistry;

export default {
  name: 'QuestionCreate',
  components: {
    QuestionForm,
    LandingCreate,
  },
  data: () => ({
    question: {
      name: '',
      hint: '',
      code: '',
      type: '',
    },
    createQuestionContent: {
      pageTitle: 'Create Question',
      enableBackButton: true,
    },
  }),
  methods: {
    async onSubmit(data) {
      try {
        await questionService.create({ question: data });
        this.$router.push({ name: 'question.list' });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
