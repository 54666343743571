<template>
<div class="login-page fill-height fluid no-gutters">
  <v-layout>
    <v-app-bar
      elevation="4"
    >
      <v-img
        :src="require('../assets/up-pgh-biobank.png')"
        class="my-3 header-image"
        contain
        height="100%"
      />
    </v-app-bar>
  </v-layout>
  <v-layout>
    <v-row style="padding: 20px" class="mt-5">
      <v-col lg="4" sm="1"></v-col>
      <v-col lg="4" sm="10">
        <div class="text-center mb-4">
          <div
            :class="['text-h3', 'mb-2']"
            class="transition-swing"
            v-text="'One Time Password'"
          >
          </div>
          <div
            :class="['text-h5', 'mb-2']"
            class="transition-swing"
            v-text="'Please enter the One Time Password we sent to your email address.'"
          >
          </div>
        </div>
        <div>
          <validation-observer ref="observer" v-slot="{ invalid }">
            <form @submit.prevent="handleSubmit">
              <validation-provider
                v-slot="{ errors }"
                name="one-time-password"
                rules="required">
                <v-text-field
                  v-model="oneTimePassword"
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show3 ? 'text' : 'password'"
                  :error-messages="errors"
                  name="input-10-1"
                  @click:append="show3 = !show3"
                  outlined
                  dense
                  height="70"
                ></v-text-field>
              </validation-provider>
              <v-row>
                <v-spacer></v-spacer>
              </v-row>
              <br>
              <v-btn
                v-if="!otp_expired"
                dense
                :disabled="invalid"
                style="width: 100%;"
                type="submit"
                class="login--button primary mb-3">
                Submit
              </v-btn>
              <v-btn
                v-else
                @click="resendOTP"
                dense
                style="width: 100%;"
                type="button"
                class="login--button primary mb-3">
                Resend OTP
              </v-btn>
              <v-btn
                @click="onLogout"
                dense
                style="width: 100%;"
                type="submit"
                class="login--button primary">
                Logout
              </v-btn>
            </form>
          </validation-observer>

          <hr class="my-10">

        </div>
      </v-col>
      <v-col lg="4" sm="1"></v-col>
    </v-row>
  </v-layout>

  <loader
    :is-loading="isLoading"
    :loader-style="'dots'"
  >
  </loader>

</div>
</template>

<script>
import { required } from 'vee-validate/dist/rules';
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from 'vee-validate';

import services from '../services';
import Loader from '../components/utilities/Loader.vue';

const { authService } = services;

setInteractionMode('eager');

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

export default {
  name: 'LoginOtpPage',
  components: {
    ValidationProvider,
    ValidationObserver,
    Loader,
  },
  data() {
    return {
      show3: false,
      oneTimePassword: '',
      isLoading: false,
      user: {},
      otp_expired: false,
    };
  },
  async created() {
    const user = await authService.getSignedInUser();
    this.$store.dispatch('setLoggedInUser', user);
    if (user) {
      this.user = user;

      if (user.isVerified) {
        this.$router.push({ name: 'HomePage' });
      }
    }
  },
  methods: {
    async resendOTP() {
      try {
        this.isLoading = true;
        const { message } = await authService.resendVerification({
          email: this.user.email,
          id: this.user.id,
        });

        this.$snackbar.show({ color: 'green', text: message, closeColor: 'white' });
        this.oneTimePassword = '';
        this.otp_expired = false;
      } catch (e) {
        const { message } = e.response.data;
        this.$snackbar.show({ color: 'red', text: message, closeColor: 'white' });
      }

      this.isLoading = false;
    },
    async handleSubmit() {
      try {
        this.isLoading = true;
        await authService.doVerify({
          code: this.oneTimePassword,
          id: this.user.id,
        });

        this.$router.go();
      } catch (e) {
        const { message } = e.response.data;
        this.otp_expired = message === 'OTP Expired';
        this.$snackbar.show({ color: 'red', text: message, closeColor: 'white' });
      }

      this.isLoading = false;
    },
    onLogout() {
      authService.doLogout();
      this.$store.dispatch('setLoggedInUser', null);
      this.$router.push('/');
    },
  },
};
</script>

<style scoped>

.header-image {
  flex: 0 0 auto;
}

.login--button {
  color: #fff !important;
  height: 50px !important;
}

.login-page {
  overflow: hidden auto;
  background: url(../assets/login-image.png) no-repeat 100% 100%,#f8eee7;
  /* background-color: rgb(87, 177, 142); */
  /* background-image: url("../assets/login.svg"); */
  background-attachment: fixed;
  overflow: hidden;
  /* background-size: cover; */
}

</style>
