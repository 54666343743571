var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('landing-create', {
    attrs: {
      "content": _vm.content
    }
  }), _c('loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _vm.attributes ? _c('view-component', {
    attrs: {
      "attributes": _vm.attributes
    }
  }) : _vm._e(), _c('v-card', {
    attrs: {
      "width": "500"
    }
  }, [_c('v-list', _vm._l(_vm.categories, function (category) {
    return _c('v-list-group', {
      key: category.category,
      attrs: {
        "value": true,
        "sub-group": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(category.category))])], 1)];
        },
        proxy: true
      }], null, true)
    }, _vm._l(category.modules, function (m) {
      return _c('v-list-group', {
        key: m.module,
        attrs: {
          "value": true,
          "no-action": "",
          "sub-group": ""
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function () {
            return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(m.module))])], 1)];
          },
          proxy: true
        }], null, true)
      }, _vm._l(m.permissions, function (permission) {
        return _c('v-list-item', {
          key: permission._id
        }, [_c('v-list-item-title', {
          staticClass: "redirect",
          on: {
            "click": function ($event) {
              return _vm.onClickReference(permission._id);
            }
          }
        }, [_vm._v(" " + _vm._s(permission.code) + " ")])], 1);
      }), 1);
    }), 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }