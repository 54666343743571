<template>
  <v-app>
    <header-app v-if="user && user.isVerified"></header-app>
    <v-main>
      <router-view></router-view>
    </v-main>
    <snackbar></snackbar>
    <confirmation-dialog></confirmation-dialog>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import HeaderApp from './components/layouts/HeaderApp.vue';
import Snackbar from './components/utilities/Snackbar.vue';
import ConfirmationDialog from './components/utilities/ConfirmationDialog.vue';

import services from './services';

import 'vue2-datepicker/index.css';

const { authService } = services;

export default {
  name: 'App',
  components: {
    HeaderApp,
    Snackbar,
    ConfirmationDialog,
  },
  data() {
    return {
      authenticated: true,
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
  created() {
    const user = authService.getSignedInUser();
    this.$store.dispatch('setLoggedInUser', user);
    this.init();
  },
  watch: {
    isAppIdle(newVal) {
      if (this.user && newVal) {
        this.autoLogout('Signed out due to inactivity');
      }
    },
  },
  methods: {
    init() {
      axios.interceptors.response.use((response) => response, (error) => {
        const { response } = error;
        const { status } = response;
        if (status === 401) {
          this.$snackbar.show({
            color: 'red',
            text: 'Not enough permission',
            closeColor: 'white',
            timeout: 0,
          });
        } else if (status === 403) {
          const message = 'Unknown session';
          this.autoLogout(message);
          if (error.response.data) {
            error.response.data.message = message;
          }
        }

        return Promise.reject(error);
      });
    },
    autoLogout(message = 'You have been logged out') {
      authService.doLogout();
      this.$store.dispatch('setLoggedInUser', null);
      this.$router.push('/');
      this.$snackbar.show({
        color: 'red',
        text: message,
        closeColor: 'white',
        timeout: 0,
      });
    },
  },
};
</script>
<style>
.main-container {
  background: blue;
  color: blue;
}
.generic-table-page {
  width: 103%;
  background-color: bluxe;
  margin-top: -70px;
  /* height: 1000px; */
}
.generic-container {
  margin-top: 85px;
  min-width: 100%;
}
.module-home-container {
  margin-top: 132px;
  margin-left: 25px;
}
.aside {
  width: 69px;
}
.dynamic-delete-button {
  margin-top: -30px;
  cursor: pointer;
}
.generic-create-button {
  color: #6E0D1C;
  margin-top: -3px;
  border: none;
  background: none;
  padding-top: 20px;
  outline: none;
  letter-spacing: 0;
}
.linkable {
  text-decoration: none;
  color: inherit;
  display: flex;
  vertical-align: middle;
}
</style>

<style scoped>

</style>
