<template>
  <v-container grid-list-lg>
    <v-layout row wrap>
      <v-flex xs3>
        <v-text-field v-model="query" label="Search" required></v-text-field>
      </v-flex>
      <v-flex
        xs3
        v-for="(selection, index) in tableMetaData.tableFilters.selections"
        :key="`select${index}`"
      >
        <v-select
          :label="selection.text"
          :items="selection.items"
          :item-text="selection.item.text"
          :item-value="selection.item.id"
          v-model="selections[selection.column]"
          @input="onSelect"
        ></v-select>
      </v-flex>
      <v-flex
        xs3
        v-for="(row, index) in tableMetaData.tableFilters.dates"
        :key="`date${index}`"
      >
        <v-menu
          ref="menu"
          v-model="dates[index].menu"
          :close-on-content-click="false"
          :return-value.sync="dates[index].value"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dates[index].value"
              :label="row.text"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates[index].value" no-title range scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dates[index].menu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="onSelectDate($refs, index)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs12>
        <v-data-table
          dense
          :headers="tableMetaData.tableHeaders"
          :items="items"
          :footer-props="{'items-per-page-options':[15, 30, 50, 100, -1]}"
          :loading="loading"
          return-object
          class="elevation-1"
        >
          <template v-for="row in tableMetaData.tableRows || []"
            v-slot:[`item.${row.key}`]="{ item }"
          >
            {{ row.display(item) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              :disabled="tableMetaData?.actionDisplay?.view?.disabled(item)"
              small
              class="mr-2"
              color="blue"
              @click="onView(item)"
            >
              mdi-eye
            </v-icon>
            <v-icon
              :disabled="tableMetaData?.actionDisplay?.edit?.disabled(item)"
              small
              class="mr-2"
              color="pink"
              @click="onEdit(item)"
            >
              mdi-pencil
            </v-icon>
            <!-- DELETE -->
            <v-icon
              small class="mr-2"
              color="primary"
              v-if="
                tableMetaData.actionDisplay?.delete?.visible &&
                permissionCode.includes('MEDICAL_QUESTIONS_CAN_DELETE')
              "
              @click="onArchive(item)"
            >
              {{ tableMetaData?.actionDisplay?.delete?.icon(item) || 'mdi-trash-can' }}
            </v-icon>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import services from '../services';

const { authService } = services;

export default {
  props: {
    tableMetaData: {
      tableFilters: {
        selections: Array,
        dates: Array,
      },
      tableHeaders: Array,
      tableActions: Array,
      onSuccessRetrieved: Function,
      onClickView: Function,
      actions: {
        view: Boolean,
      },
      URLName: String,
      default: () => ({
        selections: [],
        dates: [],
      }),
    },
    headers: {
      type: Array,
      default: () => [],
    },
    service: Object,
  },
  data: () => ({
    query: '',
    items: [],
    queryTimer: '',
    selections: {},
    dates: [],
    loading: false,
    permissionCode: [],
  }),
  watch: {
    query() {
      this.getItem();
    },
  },
  methods: {
    onView(item) {
      if (this.tableMetaData.onClickView) {
        this.tableMetaData.onClickView(item);
      } else {
        const { _id: id } = item;
        this.$router.push({ name: `${this.tableMetaData.URLName}.view`, params: { id } });
      }
    },
    onEdit(item) {
      const { _id: id } = item;
      this.$router.push({ name: `${this.tableMetaData.URLName}.edit`, params: { id } });
    },
    onArchive(item) {
      if (this.tableMetaData.onClickArchive) {
        this.$confirmDialog.show({
          title: 'Delete Data',
          text: 'Are you sure you want to <b>delete</b> this data?',
        }, async () => {
          this.tableMetaData.onClickArchive(item);
          this.getItem();
        });
      } else {
        this.$confirmDialog.show({
          title: 'No Permission',
          text: 'Please request to the IT Admin to delete this data',
          isMessage: true,
        });
      }
    },
    getItem() {
      if (this.queryTimer) {
        clearTimeout(this.queryTimer);
        this.queryTimer = '';
      }
      this.queryTimer = setTimeout(async () => {
        const { query } = this;
        // emotion:
        const dates = {};
        this.dates.forEach((date) => {
          const { value } = date;
          if (value.length > 0) {
            dates[date.column] = date.value;
          }
        });

        const selections = {};
        Object.keys(this.selections).forEach((key) => {
          const value = this.selections[key];
          if (value) {
            selections[key] = value;
          }
        });

        this.loading = true;
        try {
          const result = await this.service.getList({
            query,
            dates,
            selections: this.selections,
          });
          this.items = result;

          if (typeof this.tableMetaData?.onSuccessRetrieved === 'function') {
            this.tableMetaData.onSuccessRetrieved(result);
          }
        } catch (e) {
          // error message is handled in the interceptor
          console.error(e, 'error retrieving list');
        }

        setTimeout(() => {
          this.loading = false;
        }, 500);
      }, 500);
    },
    onSelect() {
      this.getItem();
    },
    onSelectDate(refs, index) {
      refs.menu[index].save(this.dates[index].value);
      this.getItem();
    },
    getSignedInUser() {
      return authService.getUserPermissionCode();
    },
  },
  created() {
    this.tableMetaData.tableFilters.selections.forEach((selection) => {
      this.selections[selection.column] = '';
    });
    this.tableMetaData.tableFilters.dates.forEach((date) => {
      this.dates.push({ menu: false, value: [], column: date.column });
    });
    this.getItem();
  },
  mounted() {
    this.permissionCode = this.getSignedInUser();
  },
};
</script>
