var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "md": "8",
      "offset-md": "2"
    }
  }, [_c('loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('v-form', {
    ref: "form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onClickSubmit.apply(null, arguments);
      }
    }
  }, [_vm.onExport ? _c('v-btn', {
    staticClass: "white--text",
    staticStyle: {
      "position": "fixed",
      "right": "370px",
      "top": "115px",
      "z-index": "2"
    },
    attrs: {
      "type": "button",
      "color": "orange",
      "disabled": _vm.isLoading
    },
    on: {
      "click": function ($event) {
        return _vm.onClickExport();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-file-export-outline")])], 1) : _vm._e(), _c('v-btn', {
    staticStyle: {
      "position": "fixed",
      "right": "300px",
      "top": "115px",
      "z-index": "2"
    },
    attrs: {
      "type": "submit",
      "color": "primary",
      "disabled": _vm.isLoading
    }
  }, [_c('v-icon', [_vm._v("mdi-content-save")])], 1), _c('v-btn', {
    staticClass: "white--text",
    staticStyle: {
      "position": "fixed",
      "right": "230px",
      "top": "115px",
      "z-index": "2"
    },
    attrs: {
      "type": "button",
      "color": "blue",
      "disabled": _vm.isLoading
    },
    on: {
      "click": function ($event) {
        return _vm.onClickPreview();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-eye")])], 1), _vm._l(_vm.formData.widgets, function (widgetMetadata, index) {
    return _c('widget-component', {
      key: index,
      attrs: {
        "widgetMetadata": widgetMetadata
      }
    });
  }), _c('nested-question', {
    attrs: {
      "display": 'main',
      "questionsMetadata": {
        questionRawList: _vm.questionsMetadata.questionRawList,
        questionList: _vm.questionsMetadata.questionList,
        widgets: _vm.questionsMetadata.widgets,
        parentWidget: _vm.questionsMetadata.widgets
      }
    }
  }), _c('v-btn', {
    attrs: {
      "depressed": "",
      "color": "blue",
      "dark": ""
    },
    on: {
      "click": function ($event) {
        return _vm.onClickAdd();
      }
    }
  }, [_vm._v(" Add Question ")])], 2)], 1)], 1), _c('v-dialog', {
    model: {
      value: _vm.isPreviewShown,
      callback: function ($$v) {
        _vm.isPreviewShown = $$v;
      },
      expression: "isPreviewShown"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" Preview ")]), _c('v-card-text', [_vm.questionsMetadata.questionRawList && _vm.isPreviewShown ? [_c('form-display', {
    attrs: {
      "questionList": _vm.questionsMetadata.questionRawList,
      "formMetadata": _vm.previewFormData
    }
  })] : _vm._e()], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }