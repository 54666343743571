class DepartmentService {
  constructor() {
    this.data = [
      {
        label: 'General Surgery Department',
        code: '62d3a2a80000000000147fbb',
      },
      {
        label: 'Obstetric Gynecology',
        code: '62d3a2c90000000000147fbc',
      },
      {
        label: 'Institute of Biology',
        code: '62d3a2cf0000000000147fbd',
      },
      {
        label: 'Department of Laboratories',
        code: '62d3a2d80000000000147fbe',
      },
    ];
  }

  async getList() {
    return new Promise((res) => {
      res(this.data);
    });
  }

  getOne({ id }) {
    return new Promise((res) => {
      const dept = this.data.find((datum) => datum.id === id);
      res(dept);
    });
  }
}

export default DepartmentService;
