<template>
  <v-container>
    <landing-create :content="createPermissionContent"></landing-create>
    <permission-form
      :isLoading="isLoading"
      :onSubmit="onSubmit">
    </permission-form>
  </v-container>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import PermissionForm from './PermissionForm.vue';
import services from '../../../services';

const { controlPanelRegistry } = services;
const { permissionService } = controlPanelRegistry;

export default {
  name: 'PermissionCreate',
  components: {
    PermissionForm,
    LandingCreate,
  },
  data: () => ({
    isLoading: false,
    createPermissionContent: {
      pageTitle: 'Create Permission',
      enableBackButton: true,
    },
  }),
  methods: {
    async onSubmit(data) {
      this.isLoading = true;
      try {
        const { _id: id } = await permissionService.create({ permission: data });

        this.$snackbar.show({
          showClose: Boolean,
          text: 'Successfully created permission!',
          timeout: 4000,
          closeColor: 'white',
          color: 'green',
        });

        this.$router.replace({ name: 'permission.edit', params: { id } });
      } catch (e) {
        console.error(e);
      }
      this.isLoading = false;
    },
  },
};
</script>
