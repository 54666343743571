<template>
  <v-container>
    <loader :isLoading="isLoading"></loader>
    <landing-create :content="content"></landing-create>
    <v-card>
      <v-row>
        <v-col
          class="role-section">
          <div style="background-color: rd">
            <user-form @onSubmit="onSubmit"></user-form>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import UserForm from './UserForm.vue';
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import services from '../../../services';
import Loader from '../../../components/utilities/Loader.vue';

const { controlPanelRegistry } = services;
const { userService } = controlPanelRegistry;

export default {
  name: 'UserCreate',
  components: {
    LandingCreate,
    UserForm,
    Loader,
  },
  methods: {
    async onSubmit(data) {
      try {
        this.isLoading = true;
        await userService.create({ user: data });
        this.$snackbar.show({
          showClose: Boolean,
          text: 'Successfully created a user!',
          timeout: 4000,
          closeColor: 'white',
          color: 'green',
        });
        this.isLoading = false;
        this.$router.replace({ name: 'user.list' });
      } catch (e) {
        const result = e.response.data;
        this.isLoading = false;

        if (result?.messages?.length) {
          const { message } = result.messages[0];
          this.$snackbar.show({
            showClose: Boolean,
            text: message,
            timeout: 4000,
            closeColor: 'white',
            color: 'red',
          });
        }

        console.log(e.response);
      }
    },
  },
  data() {
    return {
      roleDrawer: false,
      isLoading: false,
      content: {
        pageTitle: 'Create User',
        enableBackButton: true,
      },
    };
  },
};
</script>
<style scoped>

.role-form-subheader {
  padding-left: 20px;
}
.role-section{
  padding: 40px;
}
.valueset-search {
  padding: 40px;
}
.create-permission-button {
  color: #6E0D1C;
  margin-right: 10px;
  letter-spacing: 0;
}
</style>
