<template>
  <v-container>
    <loader :isLoading="isLoading"></loader>
    <v-form ref="form" @submit.prevent="onSave">
      <v-card elevation="2">
        <v-card-text>
          <widget-component v-for="(widgetMetadata, index) in formData.widgets"
            :key="index"
            :widgetMetadata="widgetMetadata"
          >
          </widget-component>
          <v-card class="mx-auto">
            <v-card-title>
              {{ protocolNumberTableMetadata.title }}
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                dark
                text
                class="mb-2"
                @click="onClickShowProtocolNumberSelection()"
              >
                <v-icon>mdi-plus</v-icon>
                {{ protocolNumberTableMetadata.addButtonName }}
              </v-btn>
            </v-card-title>
            <v-card-text>
              <ul style="list-style-type: none; margin: 0px; padding: 0px;">
                <draggable
                  handle=".handle"
                  :list="protocolNumberTableMetadata.items"
                >
                  <li
                    v-for="(item, index) in protocolNumberTableMetadata.items"
                    :key="`pn${index}`"
                  >
                    <v-btn text class="handle"><v-icon>mdi-menu</v-icon></v-btn>
                    <span class="text">{{ item.protocolNumber }}</span>
                    <v-btn text class="close">
                      <v-icon
                        class="mr-2"
                        color="primary"
                        @click="onClickDeleteProtocolNumber(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </v-btn>
                    <v-btn
                      text
                      class="close"
                      @click="onClickShowAnswerForm(item)"
                    >
                      <v-icon
                        class="mr-2"
                        color="pink"
                      >
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </li>
                </draggable>
              </ul>
            </v-card-text>
          </v-card>
          <v-btn
            style="margin-top: 20px;"
            color="primary"
            type="submit"
          >
            Save
          </v-btn>
        </v-card-text>
      </v-card>
    </v-form>

    <v-dialog
      v-model="answerFormMetaData.isOpen"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="closeAnswerForm()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <small>{{ caseNumber?.caseNumber }}</small>
            <v-btn icon dark>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            {{ answerFormMetaData.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              v-if="!answerFormMetaData.isReadOnly"
              @click="onClickSaveToolBarForm()"
            >
              <!-- <v-icon>mdi-content-save</v-icon> -->
              save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-alert
          dense
          border="left"
          type="warning"
          v-if="answerFormMetaData.isReadOnly"
        >
          You only have <strong>READONLY</strong> access
        </v-alert>

        <!-- Form Display -->
        <v-stepper v-model="answerFormMetaData.e6" vertical>
          <div v-for="(form, index) in answerFormMetaData.forms" :key="index">
            <v-stepper-step :complete="answerFormMetaData.e6 > index+1" :step="index+1" editable>
              {{ form.name }}
            </v-stepper-step>
            <v-stepper-content :step="index+1">
              <v-card>
                <v-card-text>
                  <v-form :ref="`form${index}`">
                    <form-display
                      v-if="form.name !== 'Initial'"
                      :questionList="answerFormMetaData.questionRawList"
                      :isReadOnly="answerFormMetaData.isReadOnly"
                      :formMetadata="form"
                      :ref="`formDisplay${index}`"
                    >
                    </form-display>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-stepper-content>
          </div>
        </v-stepper>

      </v-card>
    </v-dialog>

    <v-navigation-drawer
      v-model="isSelectingProtocolNumbers"
      fixed
      right
      temporary
      width="650px"
    >
      <v-list-item>
        <h3>{{ navDrawerSelectionMetadata.title }}</h3>
      </v-list-item>

      <v-divider></v-divider>

      <v-col cols="12">
        <v-card>
          <v-card-title>
            {{ navDrawerSelectionMetadata.tableTitle }}
            <v-spacer></v-spacer>
            <v-text-field
              v-model="navDrawerSelectionMetadata.search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-model="navDrawerSelectionMetadata.selected"
            :headers="navDrawerSelectionMetadata.headers"
            :items="navDrawerSelectionMetadata.items"
            :items-per-page="5"
            :search="navDrawerSelectionMetadata.search"
            item-key="_id"
            show-select
            class="elevation-1"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                class="mr-2"
                @click="onClickViewFormPreview(item)"
                color="blue"
              >
                mdi-eye
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
        <v-btn
          style="margin-top: 20px;"
          color="primary"
          @click="onClickAddSelectedItems"
        >
          Add
        </v-btn>
      </v-col>
    </v-navigation-drawer>
  </v-container>
</template>

<!-- eslint-disable no-underscore-dangle -->
<script>
import draggable from 'vuedraggable';
import Loader from '../../../components/utilities/Loader.vue';
import WidgetComponent from '../../../components/ui/WidgetComponent.vue';

import FormDisplay from '../form/display/FormDisplay.vue';

import FormService from '../../../services/medical_registry/form.service';
import services from '../../../services';

const { medicalRegistry, authService } = services;
const {
  protocolNumberService,
  formService,
  questionService,
} = medicalRegistry;

export default {
  components: {
    Loader,
    draggable,
    FormDisplay,
    WidgetComponent,
  },
  props: {
    isLoading: Boolean,
    onSubmit: Function,
    caseNumber: Object,
  },
  data() {
    return {
      isSelectingProtocolNumbers: false,
      formDialog: false,
      answerFormMetaData: {
        isOpen: false,
        isReadOnly: false,
        title: '',
        e6: 1,
        questionRawList: [],
        forms: [{ name: 'Initial' }],
      },
      formData: {
        model: this.caseNumber,
        widgets: [
          {
            type: 'text',
            name: 'caseNumber',
            label: 'Case Number',
            placeholder: 'Enter the case number',
            value: '',
            counter: 120,
            maxLength: 120,
            rules: [
              (v) => !!v || 'Case Number is required',
              (v) => (v && v.length <= 120) || 'Case Number must be less than or equal to 100 characters',
            ],
          },
        ],
      },
      protocolNumberTableMetadata: {
        search: '',
        title: 'Protocol Numbers',
        addButtonName: 'Add Protocol Number',
        headers: [
          { text: 'Protocol Number', value: 'protocolNumber' },
          { text: 'ID', value: '_id' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        items: [],
      },
      navDrawerSelectionMetadata: {
        search: '',
        title: 'Select Protocol Number to use',
        tableTitle: 'Protocol Numbers',
        headers: [
          { text: 'Protocol Number', value: 'protocolNumber' },
          { text: 'ID', value: '_id' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        items: [],
        selected: [],
      },
    };
  },
  async created() {
    const protocolNumbers = await protocolNumberService.getList();
    this.navDrawerSelectionMetadata.items = protocolNumbers;

    const questions = await questionService.getList();
    this.answerFormMetaData.questionRawList = questions;

    if (this.caseNumber) {
      this.formData.widgets.forEach((w) => {
        const widget = w;
        widget.value = this.formData.model[widget.name];
      });
      const selected = this.caseNumber.protocolNumbers
        .map((protocol) => ({
          ...protocolNumbers.find((_protocol) => protocol.id === _protocol._id),
          response: protocol,
        }));
      this.protocolNumberTableMetadata.items = selected;
    }
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'answerFormMetaData.isOpen'(value) {
      // console.log(value);
      if (value) {
        setTimeout(() => {
          Array.from(document.getElementsByClassName('v-dialog--active')).forEach((item) => {
            item.setAttribute('tabindex', '0');
          });
        }, 2500);
      }
    },
  },
  methods: {
    // stepper save button has its own save function
    onClickSaveToolBarForm() {
      // // temporary disable validation when saving as requested by Team
      // const formsCount = [...Array(this.answerFormMetaData.forms.length).keys()];
      // const invalidIndex = formsCount.findIndex(
      //    (index) => !this.$refs[`form${index}`][0].validate()
      // );

      // if (invalidIndex === -1) {
      //   this.saveAnswerForm();
      // }

      this.saveAnswerForm();
    },
    saveAnswerForm() { // big save button on click
      const data = this.getProtocolAnswer();
      this.answerFormMetaData.e6 = 1;
      this.answerFormMetaData.isOpen = false;

      const protocolNumberResponse = {
        id: this.answerFormMetaData.id,
        forms: data,
      };
      const medicalForm = this.protocolNumberTableMetadata
        .items
        .find((form) => form._id === protocolNumberResponse.id);
      medicalForm.response = protocolNumberResponse;
      // console.log(medicalForm, 'medicalForm');
    },
    getFormDisplay(index) {
      return this.$refs[`formDisplay${index}`][0];
    },
    getProtocolAnswer() {
      return this.answerFormMetaData.forms
        .map((_form, index) => this.getFormDisplay(index).getData());
    },
    isLast(size, index) {
      return size === (index + 1);
    },
    async onClickShowAnswerForm(item) {
      this.answerFormMetaData.isOpen = true;
      this.answerFormMetaData.answerFormMetaData = false;
      this.answerFormMetaData.id = item._id;
      this.answerFormMetaData.title = item.protocolNumber;
      const getQuery = item.medicalForms
        .map((formId) => formService.getOne({ id: formId }));
      const forms = await Promise.all(getQuery);

      const user = authService.getSignedInUser();
      const isCollaborator = item.principalInvestigators.includes(user.id);

      this.answerFormMetaData.isReadOnly = !isCollaborator;

      // answers are flattened away from section
      // inflating section by the flattened answers
      const setQuestionValue = (questions, code, value) => {
        questions.forEach((question) => {
          if (question.display === 'section') {
            const section = question;
            setQuestionValue(section.questions, code, value);
          } else if (question.code === code) {
            question.value = value;
          }
        });
      };

      // add value field in question
      this.answerFormMetaData.forms = forms.map((form, index) => {
        const previousAnswers = item.response?.forms?.find((res) => res.id === form._id);
        Object.keys(previousAnswers?.answer || {}).forEach((code) => {
          setQuestionValue(form.questions, code, previousAnswers.answer[code]);
        });
        return {
          ...form,
          button: {
            label: !this.isLast(forms.length, index) ? 'next' : 'save',
            onClick: () => {
              // // temporary disable validation as requested by team
              // const vueForm = this.$refs[`form${index}`][0];
              // if (vueForm.validate()) {
              //   if (!this.isLast(forms.length, index)) {
              //     this.answerFormMetaData.e6 += 1;
              //   } else {
              //     this.saveAnswerForm();
              //   }
              // }
              if (!this.isLast(forms.length, index)) {
                this.answerFormMetaData.e6 += 1;
              } else {
                this.saveAnswerForm();
              }
            },
          },
        };
      });
    },
    closeAnswerForm() {
      const closeAnswerFormDialog = () => {
        this.answerFormMetaData.e6 = 1;
        this.answerFormMetaData.isOpen = false;
      };
      if (!this.answerFormMetaData.isReadOnly) {
        this.$confirmDialog.show({
          text: 'Are you sure you want to close without saving?',
          title: 'Confirm Close',
        }, () => {
          closeAnswerFormDialog();
        });
      } else {
        closeAnswerFormDialog();
      }
    },
    onClickDeleteProtocolNumber(item) {
      // console.log(item);
      this.$confirmDialog.show({
        text: `<p>Are you sure you want to delete: <b>${item.protocolNumber}</b></p>`,
        title: 'Confirm Delete',
      }, () => {
        const filtered = this.protocolNumberTableMetadata.items
          .filter((protocol) => protocol._id !== item._id);
        this.protocolNumberTableMetadata.items = filtered;
        this.$snackbar.show({ color: 'green', text: 'Deleted successfully', closeColor: 'white' });
      });
    },
    onClickAddSelectedItems() {
      // console.log(this.navDrawerSelectionMetadata.selected, 'selected');
      const selected = [...this.navDrawerSelectionMetadata.selected];
      this.navDrawerSelectionMetadata.selected = [];

      selected.forEach((form) => {
        const isPresent = this.protocolNumberTableMetadata.items
          .find((f) => f._id === form._id);
        if (!isPresent) {
          this.protocolNumberTableMetadata.items.push(form);
        }
      });
      this.isSelectingProtocolNumbers = false;
    },
    onClickShowProtocolNumberSelection() {
      this.isSelectingProtocolNumbers = true;
    },
    onSave() {
      if (this.$refs.form.validate() && this.protocolNumberTableMetadata.items.length > 0) {
        const dataChanges = FormService.dataAsJSON(this.formData);
        dataChanges.protocolNumbers = this.protocolNumberTableMetadata
          .items
          .map((protocol) => ({
            id: protocol._id,
            forms: protocol.response?.forms,
          }));
        // console.log(dataChanges.protocolNumbers);
        const data = Object.assign(this.caseNumber || {}, dataChanges);
        this.onSubmit(data);
      } if (this.protocolNumberTableMetadata.items.length === 0) {
        this.$confirmDialog.show({
          title: 'Save Error',
          text: `
            <ul>
              <li>Must have at least one Protocol Number selected</li>
            </ul>
          `,
          isMessage: true,
        });
      }
    },
  },
};
</script>

<style scoped>
.close {
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
}
.text {
  font-weight: bold;
  margin: 20px;
}
</style>
