<template>
  <v-container>
    <loader :isLoading="isLoading"></loader>
    <widget-component v-for="(widgetMetadata, index) in formData.widgets"
      :key="index"
      :widgetMetadata="widgetMetadata">
    </widget-component>
    <v-expansion-panels v-if="caseNumber">
      <v-expansion-panel
        v-for="(protocolNumber, i) in caseNumber.protocolNumbers"
        :key="`pn${i}`">
        <v-expansion-panel-header>
          {{ protocolNumber.protocolNumber }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="tableMetadata.headers"
            :items="protocolNumber.specimens"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Specimens</v-toolbar-title>
                <!-- <v-divider
                  class="mx-4"
                  inset
                  vertical
                >
                </v-divider> -->
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  dark
                  text
                  class="mb-2"
                  @click="onShowAddSpecimen(protocolNumber)"
                >
                  <v-icon>mdi-plus</v-icon>
                  New Specimen
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                v-for="(action, i) in tableMetadata.actions"
                :key="`btn${i}`"
                text
                small
                @click="action.onClick(item, protocolNumber)"
              >
                <v-icon
                  class="mr-2"
                  :color="action.color"
                >
                  {{ action.icon }}
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog
      v-model="dialog.showSpecimenDetails"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog.showSpecimenDetails = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ selectedProtocolNumber.protocolNumber }} ::
            {{ selectedSpecimen.code }} ::
            Details
          </v-toolbar-title>
        </v-toolbar>
        <br>
        <v-container>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ sampleFrom.find(sf => sf.code === selectedSpecimen.sampleFrom)?.label }}
                </v-list-item-title>
                <v-list-item-subtitle>From</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ selectedSpecimen.specimenType?.name }}
                </v-list-item-title>
                <v-list-item-subtitle>Specimen Type</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ selectedSpecimen.specimenState?.name }}
                </v-list-item-title>
                <v-list-item-subtitle>Specimen State</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Location
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ selectedSpecimen.location?.shelfNumber }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Freezer No.</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ selectedSpecimen.location?.rackNumber }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Rack No.</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ selectedSpecimen.location?.slidingRackNumber }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Sliding Rack No.</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ selectedSpecimen.location?.boxLocation }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Box Location</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="selectedSpecimen.barcode?.url">
              <v-list-item-content>
                <v-list-item-title>
                  <img
                    :src="selectedSpecimen.barcode.url"
                    style="max-width: 800px; max-height: 400px;"
                  />
                </v-list-item-title>
                <v-list-item-subtitle>Barcode</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <h2>Transactions</h2>
          <v-data-table
            :headers="transactionTableMetadata.headers"
            :items="selectedSpecimen.transactions"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Transactions</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  dark
                  text
                  class="mb-2"
                  @click="onOpenTransactionForm()"
                >
                  <v-icon>mdi-plus</v-icon>
                  New Transaction
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                v-for="(action, i) in transactionTableMetadata.actions"
                :key="`btn${i}`"
                text
                small
                @click="action.onClick(selectedSpecimen, item)"
              >
                <v-icon
                  class="mr-2"
                  :color="action.color"
                >
                  {{ action.icon }}
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>

          <v-dialog
            v-model="dialog.showTransaction"
            max-width="600"
            persistent
          >
            <v-card>
              <v-card-title class="text-h5">
                Transaction
              </v-card-title>
              <loader :isLoading="isLoadingSpecimen"></loader>
              <form-component
                v-if="dialog.showTransaction"
                ref="specimenTransactionForm"
                :formData="transactionFormData"
              >
              </form-component>
              <v-card-actions>
                <v-spacer></v-spacer>
                  <v-btn
                    text
                    @click="onHideTransactionForm()"
                  >
                    Cancel
                  </v-btn>

                  <!-- <v-btn
                    color="primary darken-1"
                    text
                    @click="dialog.showTransaction = false"
                  >
                    Save
                  </v-btn> -->
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Add your table here -->
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.upsertSpecimen"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog.upsertSpecimen = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ selectedProtocolNumber.protocolNumber }} ::
            <span v-if="!specimenFormData.model">
              Create
            </span>
            <span v-if="specimenFormData.model">
              {{ specimenFormData.model.code }} :: Edit
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="onClickSpecimenSave()"
            >
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <br>
        <loader :isLoading="isLoadingSpecimen"></loader>
        <form-component
          v-if="dialog.upsertSpecimen"
          ref="specimenForm"
          :formData="specimenFormData"
        >
        </form-component>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<!-- eslint-disable no-underscore-dangle -->
<script>
import Loader from '../../../components/utilities/Loader.vue';
import WidgetComponent from '../../../components/ui/WidgetComponent.vue';

import FormComponent from '../../../components/ui/FormComponent.vue';

import services from '../../../services';

const { biobankRegistry } = services;
const {
  caseService,
  caseSpecimenService,
  specimenTypeService,
  specimenStateService,
  caseSpecimenTransactionService,
} = biobankRegistry;

export default {
  name: 'case-form',
  components: {
    Loader,
    FormComponent,
    WidgetComponent,
  },
  props: {
    onSubmit: Function,
    isLoading: Boolean,
    caseNumber: Object,
  },
  data() {
    const sampleFrom = [
      {
        label: 'Biopsy',
        code: '63392b88000000000041812f',
      },
      {
        label: 'Mastetctomy',
        code: '63392b9f0000000000418130',
      },
    ];
    return {
      isLoadingSpecimen: false,
      dialog: {
        upsertSpecimen: false,
        showSpecimenDetails: false,
        showTransaction: false,
      },
      selectedProtocolNumber: {
        protocolNumber: '',
      },
      selectedSpecimen: {
        code: '',
      },
      sampleFrom,
      specimenFormData: {
        model: null,
        onSubmit: () => {},
        widgets: [
          {
            type: 'text',
            name: 'code',
            label: 'Specimen Code',
            value: '',
            counter: 100,
            maxLength: 100,
            rules: [
              (v) => !!v || 'Sample Code is required',
            ],
          },
          {
            type: 'select',
            name: 'sampleFrom',
            label: 'Sample From',
            placeholder: 'Select where the specimen came from',
            multiple: false,
            rules: [
              (v) => !!v || 'Sample From is required',
            ],
            items: sampleFrom,
            value: '',
          },
          {
            type: 'select',
            name: 'specimenType',
            label: 'Specimen Type',
            placeholder: 'Seleect where the specimen came from',
            multiple: false,
            rules: [
              (v) => !!v || 'Specimen Type is required',
            ],
            items: [
              {
                label: 'Blood',
                code: '630cf042e5b54f0138f7b1cd',
              },
              {
                label: 'Urine',
                code: '632d96ba43c7bf920b027833',
              },
            ],
            value: '',
          },
          {
            type: 'select',
            name: 'specimenState',
            label: 'Specimen State',
            placeholder: 'Seleect where the specimen came from',
            multiple: false,
            rules: [
              (v) => !!v || 'Specimen State is required',
            ],
            items: [
              {
                label: 'Frozen',
                code: '630ccdf89eb0efca5a34e01b',
              },
            ],
            value: '',
          },
          {
            type: 'number',
            name: 'freezerNumber',
            label: 'Freezer Number',
            value: 1,
            min: 1,
            rules: [
              (v) => !!v || 'Freezer number is required',
            ],
          },
          {
            type: 'text',
            name: 'shelfNumber',
            label: 'Shelf Number',
            value: '',
            placeholder: 'top to bottom: 1, 2',
            counter: 100,
            maxLength: 100,
            rules: [
              (v) => !!v || 'Shelf number is required',
            ],
          },
          {
            type: 'number',
            name: 'rackNumber',
            label: 'Rack Number',
            placeholder: 'from left to right',
            value: 1,
            min: 1,
            rules: [
              (v) => !!v || 'Rack number is required',
            ],
          },
          {
            type: 'number',
            name: 'slidingRackNumber',
            label: 'Sliding Rack Number',
            placeholder: 'from top to bottom',
            value: 1,
            min: 1,
            rules: [
              (v) => !!v || 'Sliding rack number is required',
            ],
          },
          {
            type: 'text',
            name: 'boxLocation',
            label: 'Box Location',
            value: '',
            placeholder: 'A1, A2, A3, ...',
            counter: 250,
            maxLength: 250,
            rules: [
              (v) => !!v || 'Box location is required',
            ],
          },
          {
            type: 'file',
            name: 'barcodeAttachment',
            label: '',
            multiple: false,
            accept: '.png',
            imageURL: null,
            value: null,
          },
          {
            type: 'textarea',
            name: 'note',
            label: 'note',
            value: '',
            counter: 500,
            maxLength: 500,
            rules: [],
          },
          {
            type: 'button',
            btnType: 'button',
            name: 'save',
            label: 'Save',
            color: 'primary',
            onClick: async () => {
              this.onClickSpecimenSave();
            },
            value: null,
          },
        ],
      },
      transactionFormData: {
        model: null,
        onSubmit: () => {},
        widgets: [
          {
            type: 'select',
            name: 'status',
            label: 'Status',
            placeholder: 'Select the status of specimen',
            multiple: false,
            rules: [
              (v) => !!v || 'Status is required',
            ],
            items: [
              {
                label: 'Discard',
                code: '6359892e0000000000f3e280',
              },
              {
                label: 'New Consent Form',
                code: '6329f91c0000000000eb130e',
              },
              {
                label: 'New Institution',
                code: '635989450000000000f3e281',
              },
            ],
            value: '',
          },
          {
            type: 'textarea',
            name: 'note',
            label: 'Notes',
            value: '',
            counter: 255,
            maxLength: 255,
            rules: [
              (v) => !!v || 'Status is required',
            ],
          },
          {
            type: 'button',
            btnType: 'button',
            name: 'save',
            label: 'Save',
            color: 'primary',
            onClick: async () => {
              this.onClickSpecimenTransactionSave(this.selectedSpecimen);
            },
            value: null,
          },
        ],
      },
      formData: {
        onSubmit: this.onSubmit,
        model: this.caseNumber,
        widgets: [
          {
            type: 'select',
            name: 'caseNumber',
            label: 'Case Number',
            placeholder: 'Select a case number',
            multiple: false,
            readonly: false,
            rules: [
              (v) => !!v || 'Case number is required',
            ],
            items: [],
            customFilter: (item, queryText) => {
              const label = item.label.toLowerCase();
              const searchText = queryText.toLowerCase();
              return label.indexOf(searchText) > -1;
            },
            value: '',
          },
        ],
      },
      transactionTableMetadata: {
        headers: [
          {
            text: 'Action',
            value: 'status.name',
          },
          {
            text: 'Date Performed',
            value: '_metadata.updated.date',
          },
          {
            text: 'User',
            value: '_metadata.updated.user.fullName',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
          },
        ],
        actions: [
          {
            icon: 'mdi-delete',
            color: 'primary',
            onClick: (specimen, transaction) => {
              this.onClickRemoveSpecimenTransaction(specimen, transaction);
            },
          },
          {
            icon: 'mdi-pencil',
            color: 'pink',
            onClick: (specimen, transaction) => {
              this.onClickEditSpecimenTransaction(specimen, transaction);
            },
          },
          // {
          //   icon: 'mdi-eye',
          //   color: 'blue',
          //   onClick: (item) => console.log(item, 'lmao 3'),
          // },
        ],
      },
      tableMetadata: {
        headers: [
          {
            text: 'Specimen Code',
            align: 'start',
            value: 'code',
          },
          {
            text: 'Specimen Type',
            value: 'specimenType.name',
          },
          {
            text: 'Specimen State',
            value: 'specimenState.name',
          },
          {
            text: 'Date',
            value: '_metadata.created.date',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
          },
        ],
        actions: [
          {
            icon: 'mdi-delete',
            color: 'primary',
            onClick: (specimen, protocolNumber) => this.onClickRemoveSpecimen(
              specimen,
              protocolNumber,
            ),
          },
          {
            icon: 'mdi-pencil',
            color: 'pink',
            onClick: (specimen, protocolNumber) => this.onEditSpecimenDetails(
              specimen,
              protocolNumber,
            ),
          },
          {
            icon: 'mdi-eye',
            color: 'blue',
            onClick: (specimen, protocolNumber) => this.onShowSpecimenDetails(
              specimen,
              protocolNumber,
            ),
          },
        ],
      },
    };
  },
  async created() {
    const caseNumberWidget = this.formData
      .widgets
      .find((w) => w.name === 'caseNumber');

    if (this.caseNumber?._id) {
      caseNumberWidget.value = this.caseNumber._id;
      caseNumberWidget.readonly = true;
    }

    const prepareSpecimen = async ({ name, dataSource }) => {
      const data = await dataSource();
      const widget = this.specimenFormData.widgets.find((w) => w.name === name);
      widget.items = data.map((doc) => ({ code: doc._id, label: doc.name }));
    };

    prepareSpecimen({ name: 'specimenType', dataSource: () => specimenTypeService.getList() });
    prepareSpecimen({ name: 'specimenState', dataSource: () => specimenStateService.getList() });

    const cases = await caseService.getList();
    caseNumberWidget.items = cases
      .map((document) => ({ code: document._id, label: document.caseNumber }));

    // on barcode change display preview
    const barcodeAttachment = this.specimenFormData
      .widgets
      .find(({ name }) => name === 'barcodeAttachment');
    barcodeAttachment.onFileChange = (e, imageURL) => {
      if (e.length > 0) {
        const file = e[0];
        barcodeAttachment.imageURL = URL.createObjectURL(file);
      } else {
        console.log(imageURL, 'imageurl');
        barcodeAttachment.imageURL = imageURL;
      }
    };
  },
  methods: {
    onOpenTransactionForm() {
      this.dialog.showTransaction = true;
    },
    onHideTransactionForm() {
      this.dialog.showTransaction = false;
      this.transactionFormData.model = {};
    },
    async onClickEditSpecimenTransaction(specimen, transaction) {
      const editTransaction = { ...transaction };
      editTransaction.status = transaction.status._id;
      this.transactionFormData.model = editTransaction;

      this.onOpenTransactionForm();
    },
    async onClickRemoveSpecimenTransaction(specimen, transaction) {
      this.$confirmDialog.show({
        text: `<p>Are you sure you want to permanently delete:
          <ul>
            <li><b>${transaction.status.name}</b></li>
            <li>${transaction._metadata.created.user.fullName}</li>
          </ul>
          </p>`,
        title: 'Delete Transaction',
      }, () => this.onRemoveSpecimenTransaction(specimen, transaction));
    },
    async onRemoveSpecimenTransaction(specimen, transaction) {
      this.isLoadingSpecimen = true;
      await caseSpecimenTransactionService.delete({
        specimenId: specimen._id,
        transactionId: transaction._id,
      });
      this.$snackbar.show({
        color: 'success',
        text: 'Transaction deleted successfully',
        closeColor: 'white',
      });
      const index = specimen.transactions.findIndex((t) => t._id === transaction._id);
      specimen.transactions.splice(index, 1);
      this.isLoadingSpecimen = false;
    },
    async onClickSpecimenTransactionSave(specimen) {
      const { specimenTransactionForm } = this.$refs;
      if (!specimenTransactionForm.validate()) {
        return;
      }

      this.isLoadingSpecimen = true;
      const specimenTransaction = specimenTransactionForm.dataAsJSON();

      const transaction = await caseSpecimenTransactionService.upsert({
        specimenId: specimen._id,
        transaction: specimenTransaction,
      });
      const index = specimen.transactions.findIndex((t) => t._id === transaction._id);
      console.log(index, 'index');
      if (index === -1) {
        specimen.transactions.push(transaction);
      } else {
        specimen.transactions.splice(index, 1, transaction);
      }

      const message = !specimenTransaction.id
        ? 'Transaction added successfully'
        : 'Transaction updated successfully';
      this.$snackbar.show({
        color: 'success',
        text: message,
        closeColor: 'white',
      });
      this.onHideTransactionForm();
      this.isLoadingSpecimen = false;
    },
    async onClickSpecimenSave() {
      const { specimenForm } = this.$refs;
      if (!specimenForm.validate()) {
        return;
      }
      this.isLoadingSpecimen = true;
      const caseSpecimen = this.$refs.specimenForm.dataAsJSON();
      caseSpecimen.caseNumber = {
        _id: this.caseNumber._id,
      };
      caseSpecimen.protocolNumber = {
        _id: this.selectedProtocolNumber.id,
      };
      caseSpecimen.location = {
        freezerNumber: caseSpecimen.freezerNumber,
        shelfNumber: caseSpecimen.shelfNumber,
        rackNumber: caseSpecimen.rackNumber,
        slidingRackNumber: caseSpecimen.slidingRackNumber,
        boxLocation: caseSpecimen.boxLocation,
      };

      const specimenCaseId = await (async () => {
        if (!this.specimenFormData.model) {
          const { _id, _metadata } = await caseSpecimenService.create({ specimen: caseSpecimen });
          // prepare case for display
          caseSpecimen._metadata = _metadata;
          caseSpecimen.specimenState = await specimenStateService.getOne({
            id: caseSpecimen.specimenState,
          });
          caseSpecimen.specimenType = await specimenTypeService.getOne({
            id: caseSpecimen.specimenType,
          });
          this.selectedProtocolNumber.specimens.push(caseSpecimen);
          return _id;
        }
        caseSpecimen._metadata = this.specimenFormData.model._metadata;
        const updatedSpecimen = await caseSpecimenService.update({ specimen: caseSpecimen });
        Object.assign(this.specimenFormData.model, updatedSpecimen);
        return updatedSpecimen._id;
      })();

      const { barcodeAttachment } = caseSpecimen;
      if (barcodeAttachment) {
        const formData = new FormData();
        formData.append('attachment', barcodeAttachment[0]);
        await caseSpecimenService.uploadBarcode({ id: specimenCaseId, formData });
      }

      this.selectedProtocolNumber = {};
      this.dialog.upsertSpecimen = false;
      this.resetSpecimenForm();

      this.isLoadingSpecimen = false;
    },
    onClickRemoveSpecimen(specimen, protocolNumber) {
      this.$confirmDialog.show({
        text: `<p>Are you sure you want to permanently delete: <b>${specimen.code}</b></p>`,
        title: 'Delete Specimen',
      }, () => this.onRemoveSpecimen(specimen, protocolNumber));
    },
    async onRemoveSpecimen(specimen, protocolNumber) {
      await caseSpecimenService.delete({ specimen });
      const index = protocolNumber.specimens.findIndex((sp) => sp._id === specimen._id);
      protocolNumber.specimens.splice(index, 1);
    },
    onShowAddSpecimen(protocolNumber) {
      this.resetSpecimenForm();
      this.selectedProtocolNumber = protocolNumber;
      this.dialog.upsertSpecimen = true;
    },
    async onEditSpecimenDetails(specimen, protocolNumber) {
      this.resetSpecimenForm();
      specimen.freezerNumber = specimen.location.freezerNumber;
      specimen.shelfNumber = specimen.location.shelfNumber;
      specimen.rackNumber = specimen.location.rackNumber;
      specimen.slidingRackNumber = specimen.location.slidingRackNumber;
      specimen.boxLocation = specimen.location.boxLocation;
      this.specimenFormData.model = specimen;
      if (specimen.barcode?._id) {
        const barcodeAttachment = this.specimenFormData.widgets.find(
          ({ name }) => name === 'barcodeAttachment',
        );
        barcodeAttachment.label = specimen.barcode
          ? `${specimen.barcode?._id}.png`
          : '';
        const { barcode } = await caseSpecimenService.getOne({ id: specimen._id });
        barcodeAttachment.imageURL = barcode.url;
      }
      this.selectedProtocolNumber = protocolNumber;
      this.selectedSpecimen = specimen;
      this.dialog.upsertSpecimen = true;
    },
    async onShowSpecimenDetails(specimen, protocolNumber) {
      this.selectedProtocolNumber = protocolNumber;
      if (specimen.barcode?._id) {
        const { barcode } = await caseSpecimenService.getOne({ id: specimen._id });
        specimen.barcode = barcode;
      }
      this.selectedSpecimen = specimen;
      this.dialog.showSpecimenDetails = true;
    },
    resetSpecimenForm() {
      this.specimenFormData.model = null;
      this.specimenFormData.widgets.forEach((w) => {
        if (w.name === 'freezerNumber') {
          w.value = 1;
          return;
        }
        if (w.name === 'barcodeAttachment') {
          w.value = null;
          w.imageURL = null;
          return;
        }
        w.value = '';
      });
    },
  },
};
</script>
