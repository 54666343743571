<template>
  <v-container>
    <v-row
      no-gutters
    >
      <v-col
        md="8"
        offset-md="2"
      >
        <loader :isLoading="isLoading"></loader>
        <v-form ref="form" @submit.prevent="onClickSubmit">
          <v-btn
            v-if="onExport"
            type="button"
            color="orange"
            class="white--text"
            style="position: fixed; right: 370px; top: 115px; z-index: 2;"
            :disabled="isLoading"
            @click="onClickExport()"
          >
            <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
          <v-btn
            type="submit"
            color="primary"
            style="position: fixed; right: 300px; top: 115px; z-index: 2;"
            :disabled="isLoading"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn
            type="button"
            color="blue"
            class="white--text"
            style="position: fixed; right: 230px; top: 115px; z-index: 2;"
            :disabled="isLoading"
            @click="onClickPreview()"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
          <widget-component v-for="(widgetMetadata, index) in formData.widgets"
            :key="index"
            :widgetMetadata="widgetMetadata">
          </widget-component>
          <nested-question
            :display="'main'"
            :questionsMetadata="{
                questionRawList: questionsMetadata.questionRawList,
                questionList: questionsMetadata.questionList,
                widgets: questionsMetadata.widgets,
                parentWidget: questionsMetadata.widgets,
            }"
          >
          </nested-question>
          <v-btn
            depressed
            color="blue"
            dark
            @click="onClickAdd()"
          >
            Add Question
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <v-dialog v-model="isPreviewShown">
      <v-card>
        <v-card-title>
          Preview
        </v-card-title>
        <v-card-text>
          <template
            v-if="questionsMetadata.questionRawList && isPreviewShown"
          >
            <form-display
              :questionList="questionsMetadata.questionRawList"
              :formMetadata="previewFormData"
            >
            </form-display>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { Subject } from 'rxjs';
import Loader from '../../../components/utilities/Loader.vue';
import NestedQuestion from './components/NestedQuestion.vue';

import WidgetComponent from '../../../components/ui/WidgetComponent.vue';

import FormDisplay from './display/FormDisplay.vue';

import FormService from '../../../services/medical_registry/form.service';

import services from '../../../services';

const { medicalRegistry } = services;
const { questionService } = medicalRegistry;

export default {
  name: 'survey-form',
  components: {
    WidgetComponent,
    NestedQuestion,
    FormDisplay,
    Loader,
  },
  props: {
    isLoading: Boolean,
    onSubmit: Function,
    onExport: Function,
    surveyForm: Object,
  },
  data: () => ({
    switch1: true,
    isPreviewShown: false,
    questionListSubject: new Subject(),
    previewFormData: null,
    questionsMetadata: {
      questionRawList: [],
      questionList: [],
      widgets: [],
    },
    formData: {
      widgets: [
        {
          type: 'text',
          name: 'name',
          label: 'Form Name',
          value: '',
          counter: 50,
          maxLength: 50,
          rules: [
            (v) => !!v || 'Form name is required',
            (v) => (v && v.length <= 50) || 'Form name must be less than or equal to 50 characters',
          ],
        },
      ],
    },
  }),
  async created() {
    const questions = await questionService.getList();
    this.questionsMetadata.questionRawList = questions;
    const selections = questions.map((q) => {
      const { _id, name, type } = q;
      return {
        label: name,
        code: _id,
        icon: FormService.getQuestionTypeIcon(type),
      };
    });

    this.questionsMetadata.questionList = selections;
    this.questionListSubject.next(selections);

    if (this.surveyForm?.questions?.length > 0) {
      const qParent = this.questionsMetadata.widgets;
      const goToQuestions = (question, index, parent) => {
        const { display, _id, title } = question;
        const widget = this.getWidget(display, parent, index);
        if (display === 'question') {
          this.onSelectChangeDisableItem(_id, null, 'init of widget');
          const questionJSON = questions.find((q) => {
            const { _id: qId } = q;
            return qId === _id;
          });
          widget.value = _id;
          widget.preview = {
            ...FormService.getValidWidgetJSON(questionJSON),
            readonly: true,
          };
          parent.push(widget);
        } if (display === 'section') {
          widget.value = title;
          parent.push(widget);
          question.questions.forEach(
            (_question, _index) => goToQuestions(_question, _index, widget.widgets),
          );
        }
      };
      this.surveyForm.questions.forEach(
        (question, index) => goToQuestions(question, index, qParent),
      );
      this.formData.widgets[0].value = this.surveyForm.name;
    } else {
      const basedArray = ['question'];
      basedArray.forEach((type, index) => {
        const parent = this.questionsMetadata.widgets;
        const widget = this.getWidget(type, parent, index);
        this.questionsMetadata.widgets.push(widget);
      });
    }
  },
  methods: {
    onClickExport() {
      this.onExport();
    },
    onClickPreview() {
      if (this.$refs.form.validate()) {
        this.isPreviewShown = true;
        const formData = this.formDataToValidJSONSchema();
        this.previewFormData = formData;
      } else {
        this.$snackbar.show({ color: 'red', text: 'Form is not valid', closeColor: 'white' });
      }
    },
    disableSelection(code, enabled) {
      const item = this.questionsMetadata.questionList.find((q) => q.code === code);
      if (enabled) {
        item.disabled = true;
      } else {
        delete item.disabled;
      }
    },
    // eslint-disable-next-line no-unused-vars
    onSelectChangeDisableItem(code, oldVal, source) {
      // console.log('disable a selection', source);
      this.disableSelection(code, true);
      if (oldVal) {
        this.disableSelection(oldVal, false);
      }
    },
    getWidget(type, parent, index) {
      if (type === 'section') {
        return FormService.getWidgetSection({
          parent,
          index,
        });
      }
      return FormService.getWidgetQuestion({
        questions: this.questionsMetadata.questionRawList,
        selections: this.questionsMetadata.questionList,
        parent,
        index,
        selectChange: (value, oldVal) => this.onSelectChangeDisableItem(value, oldVal, 'selectChange'),
      });
    },
    onClickAdd() {
      const qParent = this.questionsMetadata.widgets;
      const widget = FormService.getWidgetQuestion({
        questions: this.questionsMetadata.questionRawList,
        selections: this.questionsMetadata.questionList,
        parent: qParent,
        index: qParent.length,
        selectChange: (value, oldVal) => this.onSelectChangeDisableItem(value, oldVal, 'onClickAdd'),
      });
      this.questionsMetadata.widgets.push(widget);
    },
    onClickSubmit() {
      if (this.$refs.form.validate()) {
        this.onSubmitFormData();
      }
    },
    onSubmitFormData() {
      const formDataChanges = this.formDataToValidJSONSchema();
      const formData = Object.assign(this.surveyForm || {}, formDataChanges);
      this.onSubmit(formData);
    },
    formDataToValidJSONSchema() {
      const goToWidgets = (widget, data) => {
        const refData = data;
        switch (widget.display) {
          case 'question': {
            const {
              label,
              name,
              type,
              range,
            } = widget.preview;

            // eslint-disable-next-line no-underscore-dangle
            refData._id = widget.value;
            refData.isRequired = widget.conditionalWidgets.isRequired.value;
            refData.name = label;
            refData.code = name;
            refData.type = type;
            refData.display = 'question';

            if (type === 'date') {
              refData.isDateRange = range;
            }
            break;
          }
          case 'section': {
            refData.title = widget.value;
            refData.display = 'section';
            refData.questions = [];
            widget.widgets.forEach((_widget) => {
              const question = {};
              refData.questions.push(question);
              goToWidgets(_widget, question);
            });
            break;
          }
          default:
            break;
        }
      };
      const formData = FormService.dataAsJSON(this.formData);
      formData.questions = [];
      this.questionsMetadata.widgets.forEach((widget) => {
        const data = {};
        formData.questions.push(data);
        goToWidgets(widget, data);
      });
      const { _id } = this.surveyForm || {};
      if (_id) {
        formData.id = _id;
      }
      return formData;
    },
  },
};
</script>
<style scoped></style>
