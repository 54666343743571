<template>
  <div>
    <landing-create :content="createListContent"></landing-create>
    <loader
      :is-loading="isLoading"
      :loader-style="'dots'"
    >
    </loader>
    <list-component
      :service="service"
      :tableMetaData="tableMetaData"
    ></list-component>
  </div>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import ListComponent from '../../../components/ListComponent.vue';
import services from '../../../services';

// src/components/utilities/Loader.vue

import Loader from '../../../components/utilities/Loader.vue';

const { controlPanelRegistry } = services;
const { userService } = controlPanelRegistry;

export default {
  name: 'UserList',
  components: {
    ListComponent,
    LandingCreate,
    Loader,
  },
  async created() {
    //
  },
  methods: {
    onActivate(item) {
      this.$confirmDialog.show({
        title: 'Activate User',
        text: 'Are you sure you want to <b>activate</b> this user?',
      }, async () => {
        this.isLoading = true;

        const { _id: id } = item;
        userService.activate({ id });
        item.isActive = true;

        this.isLoading = false;
      });
    },
    onDeactivate(item) {
      this.$confirmDialog.show({
        title: 'Deactivate User',
        text: 'Are you sure you want to <b>deactivate</b> this user?',
      }, async () => {
        this.isLoading = true;

        const { _id: id } = item;
        this.isLoading = true;
        userService.deactivate({ id });
        item.isActive = false;

        this.isLoading = false;
      });
    },
  },
  data() {
    const component = this;
    return {
      service: userService,
      drawer: true,
      mini: true,
      isLoading: false,
      createListContent: {
        pageTitle: 'Users',
        commands: [
          {
            link: 'user.create',
            icon: 'mdi-account-plus',
            buttonName: 'Create User',
          },
        ],
      },
      tableMetaData: {
        tableFilters: {
          selections: [],
          dates: [
            {
              text: 'Date created',
              column: 'createdAt',
            },
          ],
        },
        onClickDelete(item) {
          if (item?.isActive !== true) {
            component.onActivate(item);
          } else {
            component.onDeactivate(item);
          }
        },
        tableRows: [
          {
            key: 'status',
            display(item) {
              return item.isActive === true ? 'Active' : 'Inactive';
            },
          },
          {
            key: 'roles',
            display(item) {
              return item.roles.map((data) => data.name).join(', ');
            },
          },
        ],
        actionDisplay: {
          view: {
            disabled(item) {
              return !item.isActive;
            },
          },
          edit: {
            disabled(item) {
              return !item.isActive;
            },
          },
          delete: {
            visible: true,
            icon(item) {
              return item.isActive ? 'mdi-trash-can' : 'mdi-delete-restore';
            },
          },
        },
        tableHeaders: [
          {
            text: 'First Name',
            align: 'start',
            sortable: true,
            value: 'firstName',
          },
          { text: 'Last Name', value: 'lastName' },
          { text: 'Email', value: 'email' },
          {
            text: 'Status',
            value: 'status',
            display(item) {
              return item.isActive ? 'Active' : 'Inactive';
            },
          },
          { text: 'Roles', value: 'roles' },
          {
            text: 'Date created',
            value: 'createdAt',
          },
          {
            text: 'Actions',
            value: 'actions',
          },
        ],
        URLName: 'user',
      },
    };
  },
};
</script>
<style scoped>
.terminology-page {
  width: 100%;
  background-color: ed;
  height: 1000px;
}
.sidebar {
  z-index: 1;
  margin-top: -16px;
  min-width: 64px;
}
.first-list-item {
  padding-left: 15px;
  padding-top: 0px;
  margin-bottom: -12px;
}
.first-list-content {
  margin-left: -16px;
}
.next-list-item {
  padding-left: 21px;
}
.next-list-content {
  padding-top: 10px;
  margin-left: -10px;
}
.nav-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  vertical-align: middle;
}
</style>
