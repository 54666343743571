var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', [_vm._v(" Details ")]), _c('v-card-text', [_c('v-card-text', [_c('div', {
    staticClass: "pa-12"
  }, [_vm.question ? _c('widget-component', {
    attrs: {
      "widgetMetadata": _vm.getValidWidgetJSON(_vm.question)
    }
  }) : _vm._e()], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }