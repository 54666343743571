var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('form-component', {
    attrs: {
      "formData": _vm.formData
    }
  }, [_c('v-row', {}, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_c('strong', [_vm._v("Permissions")])])]), _vm._l(_vm.permissions, function (permission, index) {
    return _c('v-col', {
      key: ("permission" + index),
      attrs: {
        "cols": "6"
      }
    }, [_c('p', [_vm._v(_vm._s(index))]), _c('v-data-table', {
      staticClass: "elevation-1 mb-4",
      attrs: {
        "headers": _vm.headers,
        "items": permission,
        "item-key": "_id",
        "sort-by": "name",
        "group-by": "module",
        "show-group-by": ""
      },
      scopedSlots: _vm._u([{
        key: "item",
        fn: function (props) {
          return [_c('tr', [_c('td', [_c('v-checkbox', {
            attrs: {
              "value": props.item._id,
              "label": props.item.name
            },
            model: {
              value: _vm.formData.model.permissions,
              callback: function ($$v) {
                _vm.$set(_vm.formData.model, "permissions", $$v);
              },
              expression: "formData.model.permissions"
            }
          })], 1)])];
        }
      }], null, true)
    })], 1);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }