class ModuleService {
  constructor() {
    this.data = [
      {
        category: 'Medical Registry',
        label: 'Question',
        code: 'Question',
      },
      {
        category: 'Medical Registry',
        label: 'Form Builder',
        code: 'Forms',
      },
      {
        category: 'Medical Registry',
        label: 'Consent Document',
        code: 'Consent Document',
      },
      {
        category: 'Medical Registry',
        label: 'Protocol Number',
        code: 'Protocol Number',
      },
      {
        category: 'Medical Registry',
        label: 'Medical Registry Case',
        code: 'Case Number',
      },
      {
        category: 'Biobank Registry',
        label: 'Speciment State',
        code: 'Specimen State',
      },
      {
        category: 'Biobank Registry',
        label: 'Speciment Type',
        code: 'Specimen Type',
      },
      {
        category: 'Biobank Registry',
        label: 'MTA Document',
        code: 'Mutual Transfer Agreement',
      },
      {
        category: 'Biobank Registry',
        label: 'Biobank Registry Case',
        code: 'Specimen Case',
      },
      {
        category: 'Control Panel',
        label: 'Permission',
        code: 'Permission',
      },
      {
        category: 'Control Panel',
        label: 'Role',
        code: 'Role',
      },
      {
        category: 'Control Panel',
        label: 'User',
        code: 'User',
      },
    ];
  }

  async getList() {
    return new Promise((res) => {
      res(this.data);
    });
  }

  getOne({ id }) {
    return new Promise((res) => {
      const dept = this.data.find((datum) => datum.id === id);
      res(dept);
    });
  }
}

export default ModuleService;
