var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "module-home-container"
  }, [_c('aside', {
    staticClass: "aside"
  }, [_c('sidebar-standard', {
    attrs: {
      "name": "sidebar",
      "contents": _vm.sidebarContent
    }
  })], 1), _c('main', [_c('v-container', {
    staticClass: "generic-table-page"
  }, [_c('router-view')], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }