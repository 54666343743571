<template>
  <div>
    <landing-create :content="createPermissionContent"></landing-create>
    <list-component
      :service="service"
      :tableMetaData="tableMetaData"
    ></list-component>
  </div>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import ListComponent from '../../../components/ListComponent.vue';
import services from '../../../services';

const { controlPanelRegistry } = services;
const { permissionService } = controlPanelRegistry;

export default {
  name: 'PermissionList',
  components: {
    ListComponent,
    LandingCreate,
  },
  data: () => ({
    service: permissionService,
    tableMetaData: {
      tableFilters: {
        selections: [],
        dates: [
          {
            text: 'Date created',
            column: 'dateCreated',
          },
          {
            text: 'Date updated',
            column: 'dateUpdated',
          },
        ],
      },
      tableHeaders: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Code',
          value: 'code',
        },
        {
          text: 'Category',
          value: 'category',
        },
        {
          text: 'Module',
          value: 'module',
        },
        {
          text: 'Date created',
          value: 'dateCreated',
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ],
      URLName: 'permission',
    },
    createPermissionContent: {
      pageTitle: 'Permissions',
      commands: [
        {
          link: 'permission.create',
          icon: 'mdi-plus-box',
          buttonName: 'Create',
        },
      ],
    },
  }),
};
</script>
