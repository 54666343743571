<template>
  <v-container>
    <loader :isLoading="isLoading"></loader>
    <form-component
      :formData="formData"
    >
      <v-row class="">
        <v-col cols="12">
          <p class="mb-0"><strong>Permissions</strong></p>
        </v-col>
        <v-col cols="6" v-for="(permission, index) in permissions" :key="`permission${index}`">
          <p>{{ index }}</p>
          <v-data-table
            :headers="headers"
            :items="permission"
            item-key="_id"
            sort-by="name"
            group-by="module"
            class="elevation-1 mb-4"
            show-group-by
          >
          <template v-slot:item="props">
            <tr>
              <td>
                <v-checkbox
                  v-model="formData.model.permissions"
                  :value="props.item._id"
                  :label="props.item.name"
                >
                </v-checkbox>
              </td>
            </tr>
          </template>
          </v-data-table>
        </v-col>
      </v-row>
    </form-component>
  </v-container>
</template>

<!-- eslint-disable no-underscore-dangle -->
<script>
import Loader from '../../../components/utilities/Loader.vue';
import FormComponent from '../../../components/ui/FormComponent.vue';

import services from '../../../services';

const { controlPanelRegistry } = services;
const { permissionService } = controlPanelRegistry;

export default {
  name: 'RoleForm',
  props: {
    roleModel: Object,
  },
  components: {
    Loader,
    FormComponent,
  },
  watch: {
    roleModel(value) {
      this.formData.model = value;
    },
  },
  async created() {
    const result = await permissionService.getList({
      query: '',
      dates: [],
    });
    this.permissions = result.reduce((group, permission) => {
      const { category } = permission;
      const fCategory = category || 'Uncategorized';
      group[fCategory] = group[fCategory] ?? [];
      group[fCategory].push(permission);
      return group;
    }, {});
  },
  data() {
    return {
      permissions: [],
      isLoading: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
          groupable: true,
        },
        { text: 'Module', value: 'module', align: 'right' },
      ],
      formData: {
        onSubmit: this.onSubmit,
        model: this.roleModel,
        widgets: [
          {
            type: 'text',
            name: 'name',
            label: 'Role Name',
            placeholder: 'Role Name',
            value: '',
            counter: 50,
            maxLength: 50,
            rules: [
              (v) => !!v || 'Role Name is required',
              (v) => (v == null || v.length <= 50) || 'Role Name must be less than or equal to 50 characters',
            ],
          },
          // {
          //   type: 'select',
          //   name: 'permissions',
          //   label: 'Permissions',
          //   placeholder: 'Select Permissions',
          //   multiple: true,
          //   rules: [
          //     (v) => !!v || 'Permissions is required',
          //   ],
          //   items: [],
          //   value: '',
          // },
          {
            type: 'button',
            btnType: 'submit',
            name: 'save',
            label: 'Save',
            color: 'primary',
            value: null,
          },
        ],
      },
    };
  },
  methods: {
    async onSubmit(data) {
      data.permissions = this.formData.model.permissions;
      this.$emit('onSubmit', data);
    },
    validateEmail(email) {
      const isValid = String(email)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      return isValid !== null;
    },
  },
};
</script>
