var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('base-card', [_c('h2', [_vm._v("Page not found")]), _c('p', [_vm._v(" This page could not be found. Go in "), _c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v("Home")]), _vm._v(" page. ")], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }