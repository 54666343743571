<template>
  <div>
    <landing-create :content="landingCreateContent"></landing-create>
    <survey-form
      :isLoading="isLoading"
      :onSubmit="onSubmit"
    ></survey-form>
  </div>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import SurveyForm from './Form.vue';
import services from '../../../services';

const { medicalRegistry } = services;
const { formService } = medicalRegistry;

export default {
  name: 'FormCreate',
  components: {
    LandingCreate,
    SurveyForm,
  },
  data: () => ({
    isLoading: false,
    landingCreateContent: {
      pageTitle: 'Form Create',
      enableBackButton: true,
    },
  }),
  methods: {
    async onSubmit(data) {
      this.isLoading = true;
      try {
        const { _id: id } = await formService.create({ form: data });

        this.$snackbar.show({
          showClose: Boolean,
          text: 'Successfully created the form!',
          timeout: 4000,
          closeColor: 'white',
          color: 'green',
        });

        this.$router.replace({ name: 'form.edit', params: { id } });
      } catch (e) {
        console.error(e);
      }
      this.isLoading = false;
    },
  },
};
</script>
