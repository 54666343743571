<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <loader :isLoading="isLoading"></loader>
    <view-component v-if="attributes" :attributes="attributes"></view-component>
  </v-container>
</template>

<!-- eslint-disable no-underscore-dangle -->
<script>
import Loader from '../../../components/utilities/Loader.vue';
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import ViewComponent from '../../../components/ViewComponent.vue';

import services from '../../../services';

const { biobankRegistry } = services;
const {
  specimenStateService,
} = biobankRegistry;

export default {
  components: {
    Loader,
    LandingCreate,
    ViewComponent,
  },
  data: () => ({
    isLoading: false,
    attributes: null,
    content: {
      pageTitle: 'Specimen State View',
      enableBackButton: true,
    },
  }),
  async created() {
    this.isLoading = true;

    const { id } = this.$route.params;
    const specimenState = await specimenStateService.getOne({ id });

    this.attributes = this.prepareAttribute(specimenState);

    this.isLoading = false;
  },
  methods: {
    prepareAttribute({
      name, description, _metadata,
    }) {
      return [
        {
          label: 'Name',
          value: name,
        },
        {
          label: 'Description',
          value: description,
        },
        {
          label: 'Created by',
          value: { id: _metadata.created.user._id, name: _metadata.created.user.fullName },
          reference: true,
          URLName: 'user.view',
        },
        {
          label: 'Created date',
          value: _metadata.created.date,
          type: 'datetime',
        },
        {
          label: 'Updated by',
          value: { id: _metadata.updated.user._id, name: _metadata.updated.user.fullName },
          reference: true,
          URLName: 'user.view',
        },
        {
          label: 'Updated date',
          value: _metadata.updated.date,
          type: 'datetime',
        },
      ];
    },
  },
};
</script>
