<template>
  <v-container>
    <landing-create :content="landingContent"></landing-create>
    <survey-form
      v-if="surveyForm"
      :isLoading="isLoading"
      :surveyForm="surveyForm"
      :onSubmit="onSubmit"
      :onExport="onExport"
    ></survey-form>
  </v-container>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import SurveyForm from './Form.vue';
import services from '../../../services';

const { medicalRegistry } = services;
const { formService } = medicalRegistry;

export default {
  components: {
    LandingCreate,
    SurveyForm,
  },
  data: () => ({
    isLoading: false,
    landingContent: {
      pageTitle: 'Update Form',
      enableBackButton: true,
    },
    surveyForm: null,
    id: null,
  }),
  async created() {
    const { id } = this.$route.params;
    this.id = id;

    const surveyForm = await formService.getOne({ id });
    this.surveyForm = surveyForm;
  },
  methods: {
    async onSubmit(data) {
      this.isLoading = true;
      try {
        await formService.update({ form: data });

        this.$snackbar.show({
          showClose: Boolean,
          text: 'Successfully updated the form!',
          timeout: 4000,
          closeColor: 'white',
          color: 'green',
        });
        this.$router.push({ name: 'form.list' });
      } catch (e) {
        console.error(e);
      }
      this.isLoading = false;
    },
    async onExport() {
      const data = await formService.exportAsDocument({ id: this.id });
      const fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      const blob = new Blob([data], { type: fileType });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = this.surveyForm.name;
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
};
</script>
