<template>
  <v-container>
    <landing-create :content="landingCreateContent"></landing-create>
    <list-component
      :service="service"
      :tableMetaData="tableMetaData"
    ></list-component>
    <v-dialog v-model="isView">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <form-display
            :questionList="questionsMetadata.questionRawList"
            :formMetadata="surveyForm"
          >
          </form-display>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import ListComponent from '../../../components/ListComponent.vue';
import FormDisplay from './display/FormDisplay.vue';

// import FormService from '../../../services/medical_registry/form.service';
import services from '../../../services';

const { medicalRegistry } = services;
const { formService, questionService } = medicalRegistry;

export default {
  name: 'Form',
  components: {
    LandingCreate,
    ListComponent,
    FormDisplay,
  },
  data: () => ({
    service: formService,
    landingCreateContent: {
      pageTitle: 'Form',
      commands: [
        {
          link: 'form.create',
          icon: 'mdi-plus-box',
          buttonName: 'Create',
        },
      ],
    },
    surveyForm: null,
    isView: false,
    questionsMetadata: {
      questionRawList: [],
    },
    tableMetaData: {
      tableFilters: {
        selections: [],
        dates: [
          {
            text: 'Date created',
            column: 'dateCreated',
          },
        ],
      },
      tableHeaders: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Created by',
          value: 'metadata.created.user.fullName',
        },
        {
          text: 'Date created',
          value: 'metadata.created.date',
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ],
      URLName: 'form',
    },
  }),
  async created() {
    this.tableMetaData.onClickView = (surveyForm) => {
      this.surveyForm = surveyForm;
      this.isView = true;
    };

    this.questionsMetadata.questionRawList = await questionService.getList();
  },
};
</script>
