<template>
  <v-container>
    <landing-create :content="createPermissionContent"></landing-create>
    <list-component
      :service="service"
      :tableMetaData="tableMetaData"
    ></list-component>
    <v-dialog v-model="isView">
      <question-view
        :question="question"
      ></question-view>
    </v-dialog>
  </v-container>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import ListComponent from '../../../components/ListComponent.vue';

import QuestionView from './QuestionView.vue';

import services from '../../../services';

const { medicalRegistry } = services;
const { questionService } = medicalRegistry;

export default {
  name: 'QuestionList',
  components: {
    LandingCreate,
    ListComponent,
    QuestionView,
  },
  data() {
    const component = this;
    return {
      service: questionService,
      question: null,
      isView: false,
      createPermissionContent: {
        pageTitle: 'Questions',
        commands: [
          {
            link: 'question.create',
            icon: 'mdi-plus-box',
            buttonName: 'Create',
          },
        ],
      },
      tableMetaData: {
        tableFilters: {
          selections: [],
          dates: [
            {
              text: 'Date created',
              column: 'dateCreated',
            },
          ],
        },
        tableHeaders: [
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Type',
            value: 'type',
          },
          {
            text: 'Date created',
            value: 'dateCreated',
          },
          {
            text: 'Actions',
            value: 'actions',
          },
        ],
        URLName: 'question',
        actionDisplay: {
          delete: {
            visible: true,
            icon: () => 'mdi-delete-empty',
          },
        },
        onClickArchive(item) {
          component.onArchive(item);
        },
      },
    };
  },
  methods: {
    async onArchive(item) {
      this.isLoading = true;
      try {
        await questionService.archive({ question: item });
        this.$snackbar.show({
          text: 'Successfully deleted question!',
          closeColor: 'white',
          color: 'green',
        });
      } catch (e) {
        const { message } = e.response.data;
        this.$snackbar.show({ color: 'red', text: message, closeColor: 'white' });
      }
      this.isLoading = false;
    },
  },
  created() {
    this.tableMetaData.onClickView = (question) => {
      this.question = question;
      this.isView = true;
    };
  },
};
</script>
