<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <loader :isLoading="isLoading"></loader>
    <view-component v-if="attributes" :attributes="attributes"></view-component>
  </v-container>
</template>

<!-- eslint-disable no-underscore-dangle -->
<script>
import Loader from '../../../components/utilities/Loader.vue';
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import ViewComponent from '../../../components/ViewComponent.vue';

import services from '../../../services';

const { medicalRegistry } = services;
const { caseService } = medicalRegistry;

export default {
  components: {
    Loader,
    LandingCreate,
    ViewComponent,
  },
  data: () => ({
    isLoading: false,
    attributes: null,
    content: {
      pageTitle: 'Case View',
      enableBackButton: true,
    },
  }),
  async created() {
    this.isLoading = true;

    const { id } = this.$route.params;
    const caseNumber = await caseService.getOne({ id });
    console.log(caseNumber, 'lmao');

    this.attributes = this.prepareAttribute(caseNumber);

    this.isLoading = false;
  },
  methods: {
    prepareAttribute({ _metadata, caseNumber, protocolNumbers }) {
      return [
        {
          label: 'Case Number',
          value: caseNumber,
        },
        {
          label: 'Protocol Numbers',
          value: protocolNumbers.map((p) => ({ id: p.id, name: p.protocolNumber })),
          type: 'array',
          reference: true,
          URLName: 'protocol-number.view',
        },
        {
          label: 'Created by',
          value: { id: _metadata.created.user._id, name: _metadata.created.user.fullName },
          reference: true,
          URLName: 'user.view',
        },
        {
          label: 'Created date',
          value: _metadata.created.date,
          type: 'datetime',
        },
        {
          label: 'Updated by',
          value: { id: _metadata.updated.user._id, name: _metadata.updated.user.fullName },
          reference: true,
          URLName: 'user.view',
        },
        {
          label: 'Updated date',
          value: _metadata.updated.date,
          type: 'datetime',
        },
      ];
    },
  },
};
</script>
