<template>
  <v-list>
    <v-list-item v-for="row in attributes" :key="row.label">
      <v-list-item-content>
        <v-list-item-title v-if="row.type !== 'file'">
          <span v-if="!['date', 'datetime', 'array'].includes(row.type)">
            <span v-if="row.reference" class="redirect"
              @click="onClickReference(row, row.value)"
            >
              {{ row.value.name }}
            </span>
            <span v-else-if="!row.reference">
              {{ row.value }}
            </span>
          </span>
          <span v-else-if="row.type === 'array'">
            <div v-for="(item, i) in row.value" :key="item.id">
              <span v-if="row.reference" class="redirect" @click="onClickReference(row, item)">
                {{item.name}}
              </span>
              <span v-else>
                {{item.name}}
              </span>
              <br v-if="i !== item.length">
            </div>
          </span>
          <span v-else-if="['date', 'datetime'].includes(row.type)">
            {{ displayDate(row.value, row.type) }}
          </span>
        </v-list-item-title>
        <v-list-item-title v-else-if="row.type === 'file'">
          <div class="redirect" @click="row.onClick">
            {{ row.display }}
          </div>
        </v-list-item-title>
        <v-list-item-subtitle>{{ row.label }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<style>
  .redirect {
    color: #2196F3 !important;
    cursor: pointer;
  }
</style>

<script>
export default {
  props: {
    attributes: Array,
  },
  methods: {
    onClickReference({ URLName }, { id }) {
      this.$router.push({ name: URLName, params: { id } });
    },
    displayDate(date, type) {
      const nDate = new Date(date);
      // eslint-disable-next-line no-restricted-globals
      if (nDate instanceof Date && !isNaN(nDate)) {
        return type === 'datetime' ? nDate.toLocaleString() : nDate.toLocaleDateString();
      }
      return '---';
    },
  },
};
</script>
