<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <specimen-type-form
      :isLoading="isLoading"
      :onSubmit="onSubmit">
    </specimen-type-form>
  </v-container>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import SpecimenTypeForm from './SpecimenTypeForm.vue';
import services from '../../../services';

const { biobankRegistry } = services;
const { specimenTypeService } = biobankRegistry;

export default {
  name: 'SpecimenTypeCreate',
  components: {
    SpecimenTypeForm,
    LandingCreate,
  },
  data: () => ({
    isLoading: false,
    content: {
      pageTitle: 'Create Specimen Type',
      enableBackButton: true,
    },
  }),
  methods: {
    async onSubmit(data) {
      this.isLoading = true;
      try {
        const { _id: id } = await specimenTypeService.create({ specimenType: data });

        this.$snackbar.showOk({ text: 'Successfully created specimen type!' });

        this.$router.replace({ name: 'specimen-type.edit', params: { id } });
      } catch (e) {
        console.error(e);
      }
      this.isLoading = false;
    },
  },
};
</script>
