var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('landing-create', {
    attrs: {
      "content": _vm.content
    }
  }), _vm.caseNumber ? [_c('case-form', {
    attrs: {
      "isLoading": _vm.isLoading,
      "caseNumber": _vm.caseNumber,
      "onSubmit": _vm.onSubmit
    }
  })] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }