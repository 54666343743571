<template>
  <loading
    :active.sync="isLoading"
    :can-cancel="canCancel"
    :is-full-page="isFullPage"
    :color="color"
    :loader="loaderStyle"
  >
  </loading>
</template>

<script type="text/javascript">

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    canCancel: {
      type: Boolean,
      default: false,
    },
    isFullPage: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#003592',
    },
    loaderStyle: {
      type: String,
      default: 'spinner',
    },
  },
  components: {
    Loading,
  },

};

</script>
