var render = function () {
  var _vm$formData$widgets$, _vm$formData$widgets$2, _vm$formData$widgets$3, _vm$formData$widgets$4, _vm$formData$widgets$5, _vm$formData$widgets$6;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('v-container', [_vm._l(_vm.warning, function (message, index) {
    return _c('v-alert', {
      key: ("warning" + index),
      attrs: {
        "type": "warning"
      }
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(message)
      }
    })]);
  }), _c('v-col', {
    attrs: {
      "align": "right"
    }
  }, [_c('v-dialog', {
    attrs: {
      "transition": "dialog-top-transition",
      "max-width": "600"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "white--text",
          staticStyle: {
            "margin-top": "20px",
            "margin-right": "10px"
          },
          attrs: {
            "widgetMetadata": _vm.buttons[1],
            "color": "blue"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.onClickPreview();
            }
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" Preview ")])];
      }
    }, {
      key: "default",
      fn: function (dialog) {
        return [_c('v-card', [_c('v-list-item', {
          attrs: {
            "two-line": ""
          }
        }, [_c('v-list-item-content', [_c('div', {
          staticClass: "text-overline mb-4"
        }, [_vm._v(" PREVIEW ")])])], 1), _c('v-card-text', [_c('div', {
          staticClass: "pa-12"
        }, [_vm.questionPreview ? _c('widget-component', {
          attrs: {
            "widgetMetadata": _vm.FormService.getValidWidgetJSON(_vm.questionPreview)
          }
        }) : _vm._e()], 1)]), _c('v-card-actions', {
          staticClass: "justify-end"
        }, [_c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function ($event) {
              dialog.value = false;
            }
          }
        }, [_vm._v("Close")])], 1)], 1)];
      }
    }])
  })], 1), _c('v-form', {
    ref: "form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onSave.apply(null, arguments);
      }
    }
  }, [_vm._l(_vm.formData.widgets, function (widgetMetadata, index) {
    return _c('widget-component', {
      key: index,
      attrs: {
        "widgetMetadata": widgetMetadata
      }
    });
  }), (_vm$formData$widgets$ = _vm.formData.widgets[3].value) !== null && _vm$formData$widgets$ !== void 0 && _vm$formData$widgets$.includes('multipleChoice') || (_vm$formData$widgets$2 = _vm.formData.widgets[3].value) !== null && _vm$formData$widgets$2 !== void 0 && _vm$formData$widgets$2.includes('checkboxes') || (_vm$formData$widgets$3 = _vm.formData.widgets[3].value) !== null && _vm$formData$widgets$3 !== void 0 && _vm$formData$widgets$3.includes('dropdown') ? [_c('v-col', _vm._l(_vm.choicesOptions, function (option, i) {
    return _c('v-row', {
      key: 'multipleChoiceOption' + i,
      attrs: {
        "align": "center"
      }
    }, [_vm._l(option, function (widget, ii) {
      return _c('v-col', {
        key: 'multipleChoiceOptionItem' + ii,
        staticClass: "d-flex",
        attrs: {
          "cols": "12",
          "sm": widget.sm
        }
      }, [_c('widget-component', {
        attrs: {
          "widgetMetadata": widget
        }
      })], 1);
    }), _c('v-btn', {
      staticClass: "dynamic-delete-button",
      on: {
        "click": function ($event) {
          return _vm.deleteMultipleChoiceOptionItem(i);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "outlined": "",
        "dense": ""
      }
    }, [_vm._v("mdi-delete")])], 1)], 2);
  }), 1), _c('v-btn', {
    staticClass: "create-permission-button",
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        var _vm$choicesOptions;

        return _vm.addOption(((_vm$choicesOptions = _vm.choicesOptions) === null || _vm$choicesOptions === void 0 ? void 0 : _vm$choicesOptions.length) + 1);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")]), _vm._v(" Add Option ")], 1)] : _vm._e(), ['shortAnswer', 'paragraph'].includes(_vm.formData.widgets[3].value) ? _c('widget-component', {
    attrs: {
      "widgetMetadata": _vm.conditionalWidgets.minCharacters
    }
  }) : _vm._e(), ['shortAnswer', 'paragraph'].includes(_vm.formData.widgets[3].value) ? _c('widget-component', {
    attrs: {
      "widgetMetadata": _vm.conditionalWidgets.maxCharacters
    }
  }) : _vm._e(), (_vm$formData$widgets$4 = _vm.formData.widgets[3].value) !== null && _vm$formData$widgets$4 !== void 0 && _vm$formData$widgets$4.includes('date') ? _c('widget-component', {
    attrs: {
      "widgetMetadata": _vm.conditionalWidgets.enableDateRange
    }
  }) : _vm._e(), (_vm$formData$widgets$5 = _vm.formData.widgets[3].value) !== null && _vm$formData$widgets$5 !== void 0 && _vm$formData$widgets$5.includes('fileUpload') ? _c('widget-component', {
    attrs: {
      "widgetMetadata": _vm.conditionalWidgets.maxNumberOfFiles
    }
  }) : _vm._e(), (_vm$formData$widgets$6 = _vm.formData.widgets[3].value) !== null && _vm$formData$widgets$6 !== void 0 && _vm$formData$widgets$6.includes('fileUpload') ? _c('widget-component', {
    attrs: {
      "widgetMetadata": _vm.conditionalWidgets.maxFileSize
    }
  }) : _vm._e(), _c('br'), _c('widget-component', {
    staticStyle: {
      "margin-top": "20px",
      "margin-right": "10px"
    },
    attrs: {
      "widgetMetadata": _vm.buttons[0]
    }
  })], 2)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }