<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <loader :isLoading="isLoading"></loader>
    <view-component v-if="attributes" :attributes="attributes"></view-component>
  </v-container>
</template>

<!-- eslint-disable no-underscore-dangle -->
<script>
import Loader from '../../../components/utilities/Loader.vue';
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import ViewComponent from '../../../components/ViewComponent.vue';

import services from '../../../services';

const { biobankRegistry } = services;
const { mutualTransferAgreementService } = biobankRegistry;

export default {
  name: 'ConsentDocumentView',
  components: {
    Loader,
    LandingCreate,
    ViewComponent,
  },
  props: {
  },
  data: () => ({
    isLoading: false,
    attributes: null,
    content: {
      pageTitle: 'Mutual Tranfer Agreement',
      enableBackButton: true,
    },
  }),
  async created() {
    this.isLoading = true;
    const { id } = this.$route.params;
    const mutualTransferAgreement = await mutualTransferAgreementService.getOne({ id });
    this.attributes = this.prepareAttribute(mutualTransferAgreement);
    this.isLoading = false;
  },
  methods: {
    redirectToViewDocument(url) {
      window.open(url, '_blank');
    },
    prepareAttribute({
      _metadata, description, document, name,
    }) {
      const {
        created,
        updated,
      } = _metadata;
      return [
        {
          label: 'Name',
          value: name,
        },
        {
          label: 'Description',
          value: description,
        },
        {
          label: 'Document',
          value: document.url,
          display: 'View Document',
          type: 'file',
          onClick: () => {
            this.redirectToViewDocument(document.url);
          },
        },
        {
          label: 'Created by',
          value: { id: created.user._id, name: created.user.fullName },
          reference: true,
          URLName: 'user.view',
        },
        {
          label: 'Created date',
          value: created.date,
          type: 'datetime',
        },
        {
          label: 'Updated by',
          value: { id: updated.user._id, name: updated.user.fullName },
          reference: true,
          URLName: 'user.view',
        },
        {
          label: 'Updated dated',
          value: updated.date,
          type: 'datetime',
        },
      ];
    },
  },
};
</script>
