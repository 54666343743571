<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <mutual-transfer-agreement-form
      :onSubmit="onSubmit"
      :isLoading="isLoading"
    ></mutual-transfer-agreement-form>
  </v-container>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import MutualTransferAgreementForm from './MutualTransferAgreementForm.vue';

import services from '../../../services';

const { biobankRegistry } = services;
const { mutualTransferAgreementService } = biobankRegistry;

export default {
  name: 'MutualTransferAgreementCreate',
  components: {
    MutualTransferAgreementForm,
    LandingCreate,
  },
  data: () => ({
    isLoading: false,
    content: {
      pageTitle: 'Consent Document Create',
      enableBackButton: true,
    },
  }),
  methods: {
    async onSubmit(data) {
      try {
        const mutualTransferAgreementData = {
          name: data.name,
          description: data.description,
        };
        this.isLoading = true;

        const mutualTransferAgreement = await mutualTransferAgreementService
          .create({ mutualTransferAgreement: mutualTransferAgreementData });

        const { _id } = mutualTransferAgreement;

        const formData = new FormData();
        formData.append('attachment', data.attachment[0]);
        await mutualTransferAgreementService.upload({ id: _id, formData });

        this.isLoading = false;

        this.$snackbar.showOk({ text: 'Successfully created MTA!' });

        this.$router.push({ name: 'mutual-transfer-agreement.list' });
      } catch (e) {
        this.isLoading = false;
        this.$snackbar.showBad({ text: 'Something weng wrong!' });
        console.error(e);
      }
    },
  },
};
</script>
