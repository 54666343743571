var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    staticClass: "page-app-bar",
    staticStyle: {
      "margin-top": "49px",
      "z-index": "2"
    },
    attrs: {
      "app": "",
      "color": "white",
      "dense": "",
      "flat": ""
    }
  }, [_c('h3', [_vm._v("  " + _vm._s(_vm.headerTitle) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }