<template>
  <v-container>
    <loader :isLoading="isLoading"></loader>
    <form-component
      :formData="formData"
    >
    </form-component>
  </v-container>
</template>

<script>
import Loader from '../../../components/utilities/Loader.vue';
import FormComponent from '../../../components/ui/FormComponent.vue';

export default {
  name: 'SpecimenStateForm',
  components: {
    FormComponent,
    Loader,
  },
  props: {
    onSubmit: Function,
    isLoading: Boolean,
    specimenState: Object,
  },
  data() {
    return {
      formData: {
        onSubmit: this.onSubmit,
        model: this.specimenState,
        widgets: [
          {
            type: 'text',
            name: 'name',
            label: 'Name',
            value: '',
            counter: 100,
            maxLength: 100,
            rules: [
              (v) => !!v || 'Name is required',
            ],
          },
          {
            type: 'textarea',
            name: 'description',
            label: 'Description',
            value: '',
            counter: 255,
            maxLength: 255,
            rules: [
              (v) => !!v || 'Description is required',
            ],
          },
          {
            type: 'button',
            btnType: 'submit',
            name: 'save',
            label: 'Save',
            color: 'primary',
            value: null,
          },
        ],
      },
    };
  },
};
</script>
