<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <loader :isLoading="isLoading"></loader>
    <view-component v-if="attributes" :attributes="attributes"></view-component>
  </v-container>
</template>

<!-- eslint-disable no-underscore-dangle -->
<script>
import Loader from '../../../components/utilities/Loader.vue';
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import ViewComponent from '../../../components/ViewComponent.vue';
import services from '../../../services';

const { medicalRegistry, controlPanelRegistry } = services;
const {
  protocolNumberService,
  consentDocumentService,
  formService,
} = medicalRegistry;
const {
  userService,
  organizationService,
  departmentService,
} = controlPanelRegistry;

export default {
  name: 'ConsentDocumentView',
  components: {
    Loader,
    LandingCreate,
    ViewComponent,
  },
  data: () => ({
    isLoading: false,
    attributes: null,
    content: {
      pageTitle: 'Protocol Number View',
      enableBackButton: true,
    },
    departments: [],
    organizations: [],
  }),
  async created() {
    this.isLoading = true;

    this.departments = await departmentService.getList();
    this.organizations = await organizationService.getList();

    const { id } = this.$route.params;
    const caseNumber = await protocolNumberService.getOne({ id });
    const consentDocuments = await Promise.all(caseNumber.consentDocuments.map(
      (cID) => consentDocumentService.getOne({ id: cID }),
    ));
    console.log(caseNumber, 'caseNumber');
    this.attributes = await this.prepareAttribute({ ...caseNumber, consentDocuments });
    this.isLoading = false;
  },
  methods: {
    redirectToViewDocument(url) {
      window.open(url, '_blank');
    },
    getUsers(ids) {
      return ids.map((id) => userService.getOne({ id }));
    },
    mapUser(user) {
      const {
        _id: id,
        firstName,
        lastName,
      } = user;
      console.log(id, firstName, lastName);
      return { id, name: `${firstName}, ${lastName}` };
    },
    async prepareAttribute({
      _metadata, protocolNumber, dateApproved, consentDocuments, organizations, departments,
      principalInvestigators, researchers, collaborators, medicalForms,
    }) {
      const {
        created,
        updated,
      } = _metadata;
      const PIusers = await Promise.all(this.getUsers(principalInvestigators));
      const RSusers = await Promise.all(this.getUsers(researchers));
      const CRusers = await Promise.all(this.getUsers(collaborators));
      const forms = await Promise.all(medicalForms.map((id) => formService.getOne({ id })));
      console.log(forms, 'forms');
      return [
        {
          label: 'Protocol Number',
          value: protocolNumber,
        },
        {
          label: 'Date Approved',
          value: dateApproved,
          type: 'date',
        },
        {
          label: 'Consent Documents',
          value: consentDocuments.map((d) => ({ id: d._id, name: d.name })),
          reference: true,
          URLName: 'consent-document.view',
          type: 'array',
        },
        {
          label: 'Organization(s)',
          value: organizations
            .map((id) => this.organizations.find((o) => o.code === id))
            .map((o) => ({ id: o.code, name: o.label })),
          type: 'array',
        },
        {
          label: 'Department(s)',
          value: departments
            .map((id) => this.departments.find((o) => o.code === id))
            .map((o) => ({ id: o.code, name: o.label })),
          type: 'array',
        },
        {
          label: 'Principal Investigator',
          value: PIusers.map((u) => this.mapUser(u)),
          type: 'array',
          reference: true,
          URLName: 'user.view',
        },
        {
          label: 'Researcher',
          value: RSusers.map((u) => this.mapUser(u)),
          type: 'array',
          reference: true,
          URLName: 'user.view',
        },
        {
          label: 'Collaborator',
          value: CRusers.map((u) => this.mapUser(u)),
          type: 'array',
          reference: true,
          URLName: 'user.view',
        },
        {
          label: 'Medical Forms',
          value: forms.map((u) => ({ id: u._id, name: u.name })),
          type: 'array',
          reference: true,
          URLName: 'form.edit',
        },
        {
          label: 'Created by',
          value: { id: created.user._id, name: created.user.fullName },
          reference: true,
          URLName: 'user.view',
        },
        {
          label: 'Created date',
          value: created.date,
          type: 'datetime',
        },
        {
          label: 'Updated by',
          value: { id: updated.user._id, name: updated.user.fullName },
          reference: true,
          URLName: 'user.view',
        },
        {
          label: 'Updated dated',
          value: updated.date,
          type: 'datetime',
        },
      ];
    },
  },
};
</script>
