class SpecimenTypeService {
  constructor({ axios }) {
    this.axios = axios;
    this.url = '/api/biobank/specimen-types';
  }

  async getList({ query = '', dates = [] } = {}) {
    const result = await this.axios.get(this.url, {
      params: {
        query,
        ...(Object.keys(dates).length > 0 && dates),
      },
    });
    const { data } = result;
    return data;
  }

  async getOne({ id }) {
    const { data } = await this.axios.get(`${this.url}/${id}`);
    return data;
  }

  async create({ specimenType }) {
    const { data } = await this.axios.post(this.url, specimenType);
    return data;
  }

  async update({ specimenType }) {
    const { data } = await this.axios.put(this.url, specimenType);
    return data;
  }
}

export default SpecimenTypeService;
