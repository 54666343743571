var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('landing-create', {
    attrs: {
      "content": _vm.content
    }
  }), _c('loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _vm.attributes ? _c('view-component', {
    attrs: {
      "attributes": _vm.attributes
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }