var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('form-component', {
    attrs: {
      "formData": _vm.formData
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }