class QuestionService {
  constructor({ axios }) {
    this.axios = axios;
  }

  async getList({ query = '', dates = [] } = {}) {
    const result = await this.axios.get('/api/medical/questions', {
      params: {
        query,
        ...(Object.keys(dates).length > 0 && dates),
      },
    });
    const { data } = result;
    return data;
  }

  async getOne({ id }) {
    const { data } = await this.axios.get(`/api/medical/questions/${id}`);
    return data;
  }

  async create({ question }) {
    const { data } = await this.axios.post('/api/medical/questions', question);
    return data;
  }

  async update({ question }) {
    const { data } = await this.axios.put('/api/medical/questions', question);
    return data;
  }

  async archive({ question }) {
    const { _id: id } = question;
    const { data } = await this.axios.delete(`/api/medical/questions/archive/${id}`, { data: question });
    return data;
  }
}

export default QuestionService;
