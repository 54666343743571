/* eslint-disable no-underscore-dangle */

class FormService {
  constructor({ axios }) {
    this.axios = axios;
  }

  static getQuestionTypeIcon(type) {
    switch (type) {
      case 'shortAnswer':
        return 'mdi-text-short';
      case 'paragraph':
        return 'mdi-text-long';
      case 'number':
        return 'mdi-numeric';
      case 'multipleChoice':
        return 'mdi-radiobox-marked';
      case 'dropdown':
        return 'mdi-arrow-down-drop-circle';
      case 'checkboxes':
        return 'mdi-checkbox-marked';
      case 'date':
        return 'mdi-calendar';
      case 'time':
        return 'mdi-clock-outline';
      case 'fileUpload':
        return 'mdi-cloud-upload';
      default:
        return '';
    }
  }

  static getWidgetSection({ index, parent }) {
    return {
      type: 'text',
      display: 'section',
      name: 'Section',
      label: 'Section title',
      placeholder: 'Enter section title',
      value: '',
      parent,
      index,
      widgets: [],
      rules: [
        (v) => !!v || 'Section title is required',
      ],
    };
  }

  static getWidgetQuestion({
    questions, selections, parent, index, selectChange,
  }) {
    const widget = {
      type: 'select',
      display: 'question',
      name: 'selectedQuestion',
      label: 'Question',
      placeholder: 'Enter question',
      parent,
      index,
      conditionalWidgets: {
        isRequired: {
          type: 'switch',
          name: 'isRequired',
          label: 'Mandatory',
          value: true,
        },
      },
      multiple: false,
      value: '',
      preview: {},
      items: selections,
      rules: [
        (v) => !!v || 'Question type is required',
      ],
    };
    // eslint-disable-next-line func-names
    widget.selectChange = function (code, oldValue) {
      if (code) {
        const question = questions.find((q) => {
          const { _id } = q;
          return _id === code;
        });
        this.preview = { ...FormService.getValidWidgetJSON(question), readonly: true };
      } else {
        this.preview = {};
      }
      selectChange(code, oldValue);
    };
    return widget;
  }

  // #1: getQuestionJSON()
  // #2: call this function
  static getValidWidgetJSON(question) {
    // minCharacters
    const {
      type: rawType, name, code, value, choices, hint, isDateRange,
      maxNumberOfFiles, maxCharacters, minCharacters,
    } = question;

    const type = FormService.getWidgetType(rawType);

    const rules = [];
    if (type === 'text') {
      rules.push((v) => (v || '').length >= minCharacters || `${name} must be greater than or equal to ${minCharacters} characters`);
      rules.push((v) => (v || '').length <= maxCharacters || `${name} must be less than or equal to ${maxCharacters} characters`);
    }

    const defaultValue = type === 'checkbox' ? [] : null;

    const getTypedValue = (rawValue) => {
      if (type === 'date' && rawValue) {
        if (!isDateRange) {
          return new Date(rawValue);
        }
        return rawValue.map((v) => new Date(v));
      }
      return rawValue;
    };

    // display previously answered question
    const valueTyped = getTypedValue(value || '');
    return {
      type,
      name: code,
      label: name,
      placeholder: hint,
      multiple: rawType === 'multipleChoice',
      items: choices?.map((c) => ({
        label: c.option,
        code: c.code,
      })) || [],
      range: isDateRange,
      maxNumberOfFiles,
      counter: maxCharacters,
      maxlength: maxCharacters,
      rules,
      value: valueTyped || defaultValue,
    };
  }

  static getWidgetType(type) {
    switch (type) {
      case 'shortAnswer':
        return 'text';
      case 'paragraph':
        return 'textarea';
      case 'number':
        return 'number';
      case 'multipleChoice':
      case 'dropdown':
        return 'select';
      case 'checkboxes':
        return 'checkbox';
      case 'date':
        return 'date';
      case 'time':
        return 'time';
      case 'fileUpload':
        return 'file';
      default:
        return '';
    }
  }

  static dataAsFormData(formData) {
    const data = new FormData();
    formData.widgets.forEach((w) => {
      if (w.type !== 'file') {
        data.append(w.name, w.value);
      } else if (!w.multiple) {
        data.append(w.name, w.value[0], w.value.name);
      } else {
        w.value.forEach((wf) => {
          data.append(w.name, wf);
        });
      }
    });
    if (formData.model) {
      data.append('id', formData.model._id);
    }
    return data;
  }

  static widgetValue(widget) {
    const { type, value } = widget;
    switch (type) {
      case 'number':
        return value * 1;
      case 'date': {
        const { range } = widget;
        // return !range ? value.toISOString() : value.map((v) => v.toISOString());
        return !range ? value?.toISOString() : value;
      }
      default:
        return value;
    }
  }

  // we are flattening the answers away from the section
  // so the answer is a flattened JSON
  static dataAsJSON(formData) {
    const data = {};
    const getData = (widget) => {
      const { display, name } = widget;
      if (display === 'section') {
        widget.widgets.forEach((_widget) => getData(_widget));
      } else {
        data[name] = FormService.widgetValue(widget);
      }
    };
    formData.widgets.forEach((w) => getData(w));
    if (formData.model) {
      data.id = formData.model._id;
    }
    return data;
  }

  async getList({ query = '', dates = [] } = {}) {
    const result = await this.axios.get('/api/medical/forms', {
      params: {
        query,
        ...(Object.keys(dates).length > 0 && dates),
      },
    });
    const { data } = result;
    return data;
  }

  async getOne({ id }) {
    const { data } = await this.axios.get(`/api/medical/forms/${id}`);
    return data;
  }

  async create({ form }) {
    const { data } = await this.axios.post('/api/medical/forms', form);
    return data;
  }

  async update({ form }) {
    const { data } = await this.axios.put('/api/medical/forms', form);
    return data;
  }

  async exportAsDocument({ id }) {
    const { data } = await this.axios.get(
      `/api/medical/forms/${id}/export`,
      {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        },
      },
    );
    return data;
  }
}

export default FormService;
