var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', [_c('v-card-title', [_vm._v(_vm._s(_vm.form.name))]), _c('v-card-text', [_c('nested-display-form', {
    attrs: {
      "formMetaData": {
        questionList: _vm.selectedQuestions
      }
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }