var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', _vm._l(_vm.attributes, function (row) {
    return _c('v-list-item', {
      key: row.label
    }, [_c('v-list-item-content', [row.type !== 'file' ? _c('v-list-item-title', [!['date', 'datetime', 'array'].includes(row.type) ? _c('span', [row.reference ? _c('span', {
      staticClass: "redirect",
      on: {
        "click": function ($event) {
          return _vm.onClickReference(row, row.value);
        }
      }
    }, [_vm._v(" " + _vm._s(row.value.name) + " ")]) : !row.reference ? _c('span', [_vm._v(" " + _vm._s(row.value) + " ")]) : _vm._e()]) : row.type === 'array' ? _c('span', _vm._l(row.value, function (item, i) {
      return _c('div', {
        key: item.id
      }, [row.reference ? _c('span', {
        staticClass: "redirect",
        on: {
          "click": function ($event) {
            return _vm.onClickReference(row, item);
          }
        }
      }, [_vm._v(" " + _vm._s(item.name) + " ")]) : _c('span', [_vm._v(" " + _vm._s(item.name) + " ")]), i !== item.length ? _c('br') : _vm._e()]);
    }), 0) : ['date', 'datetime'].includes(row.type) ? _c('span', [_vm._v(" " + _vm._s(_vm.displayDate(row.value, row.type)) + " ")]) : _vm._e()]) : row.type === 'file' ? _c('v-list-item-title', [_c('div', {
      staticClass: "redirect",
      on: {
        "click": row.onClick
      }
    }, [_vm._v(" " + _vm._s(row.display) + " ")])]) : _vm._e(), _c('v-list-item-subtitle', [_vm._v(_vm._s(row.label))])], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }