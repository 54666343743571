<template>
  <div>
    <landing-create :content="createRoleContent"></landing-create>
    <list-component
      :service="service"
      :tableMetaData="tableMetaData"
    ></list-component>
  </div>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import ListComponent from '../../../components/ListComponent.vue';

import services from '../../../services';

const { controlPanelRegistry } = services;
const { roleService } = controlPanelRegistry;

export default {
  name: 'RoleList',
  components: {
    ListComponent,
    // LandingTableAction,
    LandingCreate,
  },
  async created() {
    //
  },
  data() {
    return {
      service: roleService,
      drawer: true,
      mini: true,
      search: '',
      createRoleContent: {
        pageTitle: 'Roles',
        commands: [
          {
            link: 'role.create',
            icon: 'mdi-plus-box',
            buttonName: 'Create Role',
          },
        ],
      },
      tableMetaData: {
        tableFilters: {
          selections: [],
          dates: [
            {
              text: 'Date created',
              column: 'createdAt',
            },
          ],
        },
        onSuccessRetrieved: (list) => {
          list.map((item) => {
            item.permissions_count = item.permissions?.length;
            item.permissions = item.permissions.map((data) => data.name);
            return item;
          });
        },
        tableHeaders: [
          {
            text: 'Title',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Permissions', value: 'permissions_count' },
          {
            text: 'Date created',
            value: 'createdAt',
          },
          {
            text: 'Actions',
            value: 'actions',
          },
        ],
        URLName: 'role',
      },
    };
  },
};
</script>
<style scoped>
.create-action-button {
  /*color: white;*/
  border: none;
  background: none;
  outline: none;
  letter-spacing: 0;
}
.create-link {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  display: flex;
  vertical-align: middle;
}
</style>
