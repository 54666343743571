import SpecimenStateMain from '../views/biobank/specimen_state/SpecimenStateMain.vue';
import SpecimenStateList from '../views/biobank/specimen_state/SpecimenStateList.vue';
import SpecimenStateCreate from '../views/biobank/specimen_state/SpecimenStateCreate.vue';
import SpecimenStateUpdate from '../views/biobank/specimen_state/SpecimenStateUpdate.vue';
import SpecimenStateView from '../views/biobank/specimen_state/SpecimenStateView.vue';

import SpecimenTypeMain from '../views/biobank/specimen_type/SpecimenTypeMain.vue';
import SpecimenTypeList from '../views/biobank/specimen_type/SpecimenTypeList.vue';
import SpecimenTypeCreate from '../views/biobank/specimen_type/SpecimenTypeCreate.vue';
import SpecimenTypeUpdate from '../views/biobank/specimen_type/SpecimenTypeUpdate.vue';
import SpecimenTypeView from '../views/biobank/specimen_type/SpecimenTypeView.vue';

import MutualTransferAgreementMain from '../views/biobank/mutual_transfer_agreement/MutualTransferAgreementMain.vue';
import MutualTransferAgreementList from '../views/biobank/mutual_transfer_agreement/MutualTransferAgreementList.vue';
import MutualTransferAgreementCreate from '../views/biobank/mutual_transfer_agreement/MutualTransferAgreementCreate.vue';
import MutualTransferAgreementUpdate from '../views/biobank/mutual_transfer_agreement/MutualTransferAgreementUpdate.vue';
import MutualTransferAgreementView from '../views/biobank/mutual_transfer_agreement/MutualTransferAgreementView.vue';

import CaseMain from '../views/biobank/case/CaseMain.vue';
import CaseList from '../views/biobank/case/CaseList.vue';
import CaseCreate from '../views/biobank/case/CaseCreate.vue';
import CaseUpdate from '../views/biobank/case/CaseUpdate.vue';
import CaseView from '../views/biobank/case/CaseView.vue';

export default {
  routes: [
    {
      path: 'specimen-state', // SpecimenStateList
      name: 'specimen-state',
      component: SpecimenStateMain,
      children: [
        {
          path: 'list',
          name: 'specimen-state.list',
          component: SpecimenStateList,
        },
        {
          path: 'create',
          name: 'specimen-state.create',
          component: SpecimenStateCreate,
        },
        {
          path: 'update/:id',
          name: 'specimen-state.edit',
          component: SpecimenStateUpdate,
        },
        {
          path: 'update/:id',
          name: 'specimen-state.view',
          component: SpecimenStateView,
        },
      ],
    },
    {
      path: 'specimen-type',
      name: 'specimen-type',
      component: SpecimenTypeMain,
      children: [
        {
          path: 'list',
          name: 'specimen-type.list',
          component: SpecimenTypeList,
        },
        {
          path: 'create',
          name: 'specimen-type.create',
          component: SpecimenTypeCreate,
        },
        {
          path: 'update/:id',
          name: 'specimen-type.edit',
          component: SpecimenTypeUpdate,
        },
        {
          path: 'update/:id',
          name: 'specimen-type.view',
          component: SpecimenTypeView,
        },
      ],
    },
    {
      path: 'mutual-transfer-agreement',
      name: 'mutual-transfer-agreement',
      component: MutualTransferAgreementMain,
      children: [
        {
          path: 'list',
          name: 'mutual-transfer-agreement.list',
          component: MutualTransferAgreementList,
        },
        {
          path: 'create',
          name: 'mutual-transfer-agreement.create',
          component: MutualTransferAgreementCreate,
        },
        {
          path: 'update/:id',
          name: 'mutual-transfer-agreement.edit',
          component: MutualTransferAgreementUpdate,
        },
        {
          path: 'update/:id',
          name: 'mutual-transfer-agreement.view',
          component: MutualTransferAgreementView,
        },
      ],
    },
    {
      path: 'bcase',
      name: 'bcase',
      component: CaseMain,
      children: [
        {
          path: 'list',
          name: 'bcase.list',
          component: CaseList,
        },
        {
          path: 'create',
          name: 'bcase.create',
          component: CaseCreate,
        },
        {
          path: 'update/:id',
          name: 'bcase.edit',
          component: CaseUpdate,
        },
        {
          path: 'update/:id',
          name: 'bcase.view',
          component: CaseView,
        },
      ],
    },
  ],
};
