<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <template v-if="caseNumber">
      <case-form
        :isLoading="isLoading"
        :caseNumber="caseNumber"
        :onSubmit="onSubmit">
      </case-form>
    </template>
  </v-container>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import CaseForm from './CaseForm.vue';
import services from '../../../services';

const { biobankRegistry } = services;
const { caseService } = biobankRegistry;

export default {
  name: 'case-update',
  components: {
    CaseForm,
    LandingCreate,
  },
  data: () => ({
    isLoading: false,
    content: {
      pageTitle: 'Update Case Number',
      enableBackButton: true,
    },
    caseNumber: null,
  }),
  async created() {
    const { id } = this.$route.params;
    const caseNumber = await caseService.getOne({ id });
    this.caseNumber = caseNumber;
  },
  methods: {
    async onSubmit(dataChange) {
      const data = Object.assign(this.caseNumber || {}, dataChange);

      this.isLoading = true;
      try {
        await caseService.update({ caseNumber: data });

        this.$snackbar.showOk({ text: 'Successfully updated Case Number!' });

        this.$router.push({ name: 'bcase.list' });
      } catch (e) {
        console.error(e);
      }
      this.isLoading = false;
    },
  },
};
</script>
