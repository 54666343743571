class CaseSpecimenService {
  constructor({ axios }) {
    this.axios = axios;
    this.url = '/api/biobank/specimen-cases';
  }

  async getList({ query = '', dates = [] } = {}) {
    const result = await this.axios.get(this.url, {
      params: {
        query,
        ...(Object.keys(dates).length > 0 && dates),
      },
    });
    const { data } = result;
    return data;
  }

  async getOne({ id }) {
    const { data } = await this.axios.get(`${this.url}/${id}`);
    return data;
  }

  async create({ specimen }) {
    const { data } = await this.axios.post(this.url, specimen);
    return data;
  }

  async update({ specimen }) {
    const { data } = await this.axios.put(this.url, specimen);
    return data;
  }

  async uploadBarcode({ id, formData }) {
    const { data } = await this.axios.put(`${this.url}/upload/barcode/${id}`, formData);
    return data;
  }

  async delete({ specimen }) {
    const { _id } = specimen;
    const { data } = await this.axios.delete(`${this.url}/${_id}`, { data: specimen });
    return data;
  }
}

export default CaseSpecimenService;
