var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('v-form', {
    ref: "form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onSave.apply(null, arguments);
      }
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "2"
    }
  }, [_c('v-card-text', [_vm._l(_vm.formData.widgets, function (widgetMetadata, index) {
    return _c('widget-component', {
      key: index,
      attrs: {
        "widgetMetadata": widgetMetadata
      }
    });
  }), _c('v-card', [_c('v-card-title', [_vm._v(" Medical Forms "), _c('v-spacer'), _c('v-btn', {
    staticClass: "mb-2",
    attrs: {
      "color": "primary",
      "dark": "",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.onClickShowFormSelection();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")]), _vm._v(" Add Medical Form ")], 1)], 1), _c('v-card-text', [_c('ul', {
    staticStyle: {
      "list-style-type": "none",
      "margin": "0px",
      "padding": "0px"
    }
  }, [_c('draggable', {
    attrs: {
      "handle": ".handle",
      "list": _vm.formSelectedTableMetadata.items
    }
  }, _vm._l(_vm.formSelectedTableMetadata.items, function (item, index) {
    return _c('li', {
      key: ("pn" + index)
    }, [_c('v-btn', {
      staticClass: "handle",
      attrs: {
        "text": ""
      }
    }, [_c('v-icon', [_vm._v("mdi-menu")])], 1), _c('span', {
      staticClass: "text"
    }, [_vm._v(_vm._s(item.name))]), _c('v-btn', {
      staticClass: "close",
      attrs: {
        "text": ""
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "color": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.onClickDeleteSelectedForm(item);
        }
      }
    }, [_vm._v(" mdi-delete ")])], 1), _c('v-btn', {
      staticClass: "close",
      attrs: {
        "text": ""
      },
      on: {
        "click": function ($event) {
          return _vm.onClickViewFormPreview(item);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "color": "blue"
      }
    }, [_vm._v(" mdi-eye ")])], 1)], 1);
  }), 0)], 1)])], 1), _c('v-btn', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "color": "primary",
      "type": "submit"
    }
  }, [_vm._v(" Save ")])], 2)], 1)], 1), _c('v-navigation-drawer', {
    attrs: {
      "fixed": "",
      "right": "",
      "temporary": "",
      "width": "650px"
    },
    model: {
      value: _vm.isSelectingForms,
      callback: function ($$v) {
        _vm.isSelectingForms = $$v;
      },
      expression: "isSelectingForms"
    }
  }, [_c('v-list-item', [_c('h3', [_vm._v("Select Form(s) to link")])]), _c('v-divider'), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" Forms "), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.navigationDrawerFormSelectionMetadata.search,
      callback: function ($$v) {
        _vm.$set(_vm.navigationDrawerFormSelectionMetadata, "search", $$v);
      },
      expression: "navigationDrawerFormSelectionMetadata.search"
    }
  })], 1), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.navigationDrawerFormSelectionMetadata.headers,
      "items": _vm.navigationDrawerFormSelectionMetadata.items,
      "items-per-page": 5,
      "search": _vm.navigationDrawerFormSelectionMetadata.search,
      "item-key": "_id",
      "show-select": ""
    },
    scopedSlots: _vm._u([{
      key: "item.actions",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "color": "blue"
          },
          on: {
            "click": function ($event) {
              return _vm.onClickViewFormPreview(item);
            }
          }
        }, [_vm._v(" mdi-eye ")])];
      }
    }], null, true),
    model: {
      value: _vm.navigationDrawerFormSelectionMetadata.selected,
      callback: function ($$v) {
        _vm.$set(_vm.navigationDrawerFormSelectionMetadata, "selected", $$v);
      },
      expression: "navigationDrawerFormSelectionMetadata.selected"
    }
  })], 1), _c('v-btn', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.onClickAddSelectedForms
    }
  }, [_vm._v(" Add ")]), _c('v-dialog', {
    model: {
      value: _vm.isPreviewFormShown,
      callback: function ($$v) {
        _vm.isPreviewFormShown = $$v;
      },
      expression: "isPreviewFormShown"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" Preview ")]), _c('v-card-text', [_vm.questionsMetadata.questionRawList && _vm.isPreviewFormShown ? [_c('form-display', {
    attrs: {
      "questionList": _vm.questionsMetadata.questionRawList,
      "formMetadata": _vm.formMetadata
    }
  })] : _vm._e()], 2)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }