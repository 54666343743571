var render = function () {
  var _vm$caseNumber;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('v-form', {
    ref: "form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onSave.apply(null, arguments);
      }
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "2"
    }
  }, [_c('v-card-text', [_vm._l(_vm.formData.widgets, function (widgetMetadata, index) {
    return _c('widget-component', {
      key: index,
      attrs: {
        "widgetMetadata": widgetMetadata
      }
    });
  }), _c('v-card', {
    staticClass: "mx-auto"
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.protocolNumberTableMetadata.title) + " "), _c('v-spacer'), _c('v-btn', {
    staticClass: "mb-2",
    attrs: {
      "color": "primary",
      "dark": "",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.onClickShowProtocolNumberSelection();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")]), _vm._v(" " + _vm._s(_vm.protocolNumberTableMetadata.addButtonName) + " ")], 1)], 1), _c('v-card-text', [_c('ul', {
    staticStyle: {
      "list-style-type": "none",
      "margin": "0px",
      "padding": "0px"
    }
  }, [_c('draggable', {
    attrs: {
      "handle": ".handle",
      "list": _vm.protocolNumberTableMetadata.items
    }
  }, _vm._l(_vm.protocolNumberTableMetadata.items, function (item, index) {
    return _c('li', {
      key: ("pn" + index)
    }, [_c('v-btn', {
      staticClass: "handle",
      attrs: {
        "text": ""
      }
    }, [_c('v-icon', [_vm._v("mdi-menu")])], 1), _c('span', {
      staticClass: "text"
    }, [_vm._v(_vm._s(item.protocolNumber))]), _c('v-btn', {
      staticClass: "close",
      attrs: {
        "text": ""
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "color": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.onClickDeleteProtocolNumber(item);
        }
      }
    }, [_vm._v(" mdi-delete ")])], 1), _c('v-btn', {
      staticClass: "close",
      attrs: {
        "text": ""
      },
      on: {
        "click": function ($event) {
          return _vm.onClickShowAnswerForm(item);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "color": "pink"
      }
    }, [_vm._v(" mdi-pencil ")])], 1)], 1);
  }), 0)], 1)])], 1), _c('v-btn', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "color": "primary",
      "type": "submit"
    }
  }, [_vm._v(" Save ")])], 2)], 1)], 1), _c('v-dialog', {
    attrs: {
      "fullscreen": "",
      "hide-overlay": "",
      "persistent": "",
      "transition": "dialog-bottom-transition"
    },
    model: {
      value: _vm.answerFormMetaData.isOpen,
      callback: function ($$v) {
        _vm.$set(_vm.answerFormMetaData, "isOpen", $$v);
      },
      expression: "answerFormMetaData.isOpen"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "dark": ""
    },
    on: {
      "click": function ($event) {
        return _vm.closeAnswerForm();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1), _c('v-toolbar-title', [_c('small', [_vm._v(_vm._s((_vm$caseNumber = _vm.caseNumber) === null || _vm$caseNumber === void 0 ? void 0 : _vm$caseNumber.caseNumber))]), _c('v-btn', {
    attrs: {
      "icon": "",
      "dark": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1), _vm._v(" " + _vm._s(_vm.answerFormMetaData.title) + " ")], 1), _c('v-spacer'), _c('v-toolbar-items', [!_vm.answerFormMetaData.isReadOnly ? _c('v-btn', {
    attrs: {
      "dark": "",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.onClickSaveToolBarForm();
      }
    }
  }, [_vm._v(" save ")]) : _vm._e()], 1)], 1), _vm.answerFormMetaData.isReadOnly ? _c('v-alert', {
    attrs: {
      "dense": "",
      "border": "left",
      "type": "warning"
    }
  }, [_vm._v(" You only have "), _c('strong', [_vm._v("READONLY")]), _vm._v(" access ")]) : _vm._e(), _c('v-stepper', {
    attrs: {
      "vertical": ""
    },
    model: {
      value: _vm.answerFormMetaData.e6,
      callback: function ($$v) {
        _vm.$set(_vm.answerFormMetaData, "e6", $$v);
      },
      expression: "answerFormMetaData.e6"
    }
  }, _vm._l(_vm.answerFormMetaData.forms, function (form, index) {
    return _c('div', {
      key: index
    }, [_c('v-stepper-step', {
      attrs: {
        "complete": _vm.answerFormMetaData.e6 > index + 1,
        "step": index + 1,
        "editable": ""
      }
    }, [_vm._v(" " + _vm._s(form.name) + " ")]), _c('v-stepper-content', {
      attrs: {
        "step": index + 1
      }
    }, [_c('v-card', [_c('v-card-text', [_c('v-form', {
      ref: ("form" + index),
      refInFor: true
    }, [form.name !== 'Initial' ? _c('form-display', {
      ref: ("formDisplay" + index),
      refInFor: true,
      attrs: {
        "questionList": _vm.answerFormMetaData.questionRawList,
        "isReadOnly": _vm.answerFormMetaData.isReadOnly,
        "formMetadata": form
      }
    }) : _vm._e()], 1)], 1)], 1)], 1)], 1);
  }), 0)], 1)], 1), _c('v-navigation-drawer', {
    attrs: {
      "fixed": "",
      "right": "",
      "temporary": "",
      "width": "650px"
    },
    model: {
      value: _vm.isSelectingProtocolNumbers,
      callback: function ($$v) {
        _vm.isSelectingProtocolNumbers = $$v;
      },
      expression: "isSelectingProtocolNumbers"
    }
  }, [_c('v-list-item', [_c('h3', [_vm._v(_vm._s(_vm.navDrawerSelectionMetadata.title))])]), _c('v-divider'), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.navDrawerSelectionMetadata.tableTitle) + " "), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.navDrawerSelectionMetadata.search,
      callback: function ($$v) {
        _vm.$set(_vm.navDrawerSelectionMetadata, "search", $$v);
      },
      expression: "navDrawerSelectionMetadata.search"
    }
  })], 1), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.navDrawerSelectionMetadata.headers,
      "items": _vm.navDrawerSelectionMetadata.items,
      "items-per-page": 5,
      "search": _vm.navDrawerSelectionMetadata.search,
      "item-key": "_id",
      "show-select": ""
    },
    scopedSlots: _vm._u([{
      key: "item.actions",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "color": "blue"
          },
          on: {
            "click": function ($event) {
              return _vm.onClickViewFormPreview(item);
            }
          }
        }, [_vm._v(" mdi-eye ")])];
      }
    }], null, true),
    model: {
      value: _vm.navDrawerSelectionMetadata.selected,
      callback: function ($$v) {
        _vm.$set(_vm.navDrawerSelectionMetadata, "selected", $$v);
      },
      expression: "navDrawerSelectionMetadata.selected"
    }
  })], 1), _c('v-btn', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.onClickAddSelectedItems
    }
  }, [_vm._v(" Add ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }