<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <template v-if="mutualTransferAgreement">
      <mutual-transfer-agreement-form
        :isLoading="isLoading"
        :onSubmit="onSubmit"
        :mutualTransferAgreement="mutualTransferAgreement"
      ></mutual-transfer-agreement-form>
    </template>
  </v-container>
</template>

<!-- eslint-disable no-underscore-dangle -->
<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import MutualTransferAgreementForm from './MutualTransferAgreementForm.vue';
import services from '../../../services';

const { biobankRegistry } = services;
const { mutualTransferAgreementService } = biobankRegistry;

export default {
  name: 'MutualTransferAgreementUpdate',
  components: {
    LandingCreate,
    MutualTransferAgreementForm,
  },
  data: () => ({
    isLoading: false,
    content: {
      pageTitle: 'Mutual Transfer Agreement Update',
      enableBackButton: true,
    },
    mutualTransferAgreement: null,
  }),
  async created() {
    const { id } = this.$route.params;
    this.mutualTransferAgreement = await mutualTransferAgreementService.getOne({ id });
  },
  methods: {
    async onSubmit(data) {
      this.isLoading = true;
      try {
        const { _id, _metadata } = this.mutualTransferAgreement;
        const mutualTransferAgreementData = {
          _id,
          _metadata,
          name: data.name,
          description: data.description,
        };
        await mutualTransferAgreementService
          .update({ mutualTransferAgreement: mutualTransferAgreementData });
        if (data.attachment) {
          const formData = new FormData();
          formData.append('attachment', data.attachment[0]);
          await mutualTransferAgreementService.upload({ id: _id, formData });
        }
        this.isLoading = false;

        this.$snackbar.showOk({ text: 'Successfully updated MTA!' });

        this.$router.push({ name: 'mutual-transfer-agreement.list' });
      } catch (e) {
        this.isLoading = false;
        this.$snackbar.showBad({ text: 'Something weng wrong!' });
        console.error(e);
      }
      this.isLoading = false;
    },
  },
};
</script>
