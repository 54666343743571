import ConsentDocumentService from './consent-document.service';
import ProtocolNumberService from './protocol-number.service';
import QuestionService from './question.service';
import FormService from './form.service';
import CaseService from './case.service';

export default {
  medicalRegistry: ({ axios }) => ({
    consentDocumentService: new ConsentDocumentService({ axios }),
    protocolNumberService: new ProtocolNumberService({ axios }),
    questionService: new QuestionService({ axios }),
    formService: new FormService({ axios }),
    caseService: new CaseService({ axios }),
  }),
};
