<template>
  <v-container>
    <loader :isLoading="isLoading"></loader>
    <v-container>
      <v-alert type="warning"
        v-for="(message, index) in warning"
        :key="`warning${index}`"
      >
        <span v-html="message"></span>
      </v-alert>
      <v-col align="right">
        <v-dialog
          transition="dialog-top-transition"
          max-width="600"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              style="margin-top: 20px; margin-right: 10px;"
              :widgetMetadata="buttons[1]"
              color="blue"
              class="white--text"
              v-bind="attrs"
              v-on="on"
              v-on:click.prevent="onClickPreview()"
            >
              Preview
            </v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-list-item two-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    PREVIEW
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-card-text>
                <div class="pa-12">
                  <widget-component
                    v-if="questionPreview"
                    :widgetMetadata="FormService.getValidWidgetJSON(questionPreview)"
                  >
                  </widget-component>
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  text
                  @click="dialog.value = false"
                >Close</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
      <v-form ref="form" @submit.prevent="onSave">
        <widget-component v-for="(widgetMetadata, index) in formData.widgets"
          :key="index"
          :widgetMetadata="widgetMetadata">
        </widget-component>
        <template
          v-if="formData.widgets[3].value?.includes('multipleChoice') ||
            formData.widgets[3].value?.includes('checkboxes') ||
            formData.widgets[3].value?.includes('dropdown')"
        >
          <v-col>
            <v-row align="center"
              v-for="(option, i) in choicesOptions"
              :key="'multipleChoiceOption' + i"
            >
              <v-col
                class="d-flex"
                v-for="(widget, ii) in option"
                cols="12"
                :sm="widget.sm"
                :key="'multipleChoiceOptionItem' + ii"
              >
                <widget-component
                  :widgetMetadata="widget"
                >
                </widget-component>
              </v-col>
              <v-btn class="dynamic-delete-button" v-on:click="deleteMultipleChoiceOptionItem(i)">
                <v-icon outlined dense>mdi-delete</v-icon>
              </v-btn>
            </v-row>
          </v-col>
          <v-btn
            text
            class="create-permission-button"
            @click="addOption(choicesOptions?.length + 1)"
          >
          <v-icon>mdi-plus</v-icon>
            Add Option
          </v-btn>
        </template>
        <widget-component
          :widgetMetadata="conditionalWidgets.minCharacters"
          v-if="['shortAnswer', 'paragraph'].includes(formData.widgets[3].value)"
        >
        </widget-component>
        <widget-component
          :widgetMetadata="conditionalWidgets.maxCharacters"
          v-if="['shortAnswer', 'paragraph'].includes(formData.widgets[3].value)"
        >
        </widget-component>
        <widget-component
          :widgetMetadata="conditionalWidgets.enableDateRange"
          v-if="formData.widgets[3].value?.includes('date')"
        >
        </widget-component>
        <widget-component
          :widgetMetadata="conditionalWidgets.maxNumberOfFiles"
          v-if="formData.widgets[3].value?.includes('fileUpload')"
        >
        </widget-component>
        <widget-component
          :widgetMetadata="conditionalWidgets.maxFileSize"
          v-if="formData.widgets[3].value?.includes('fileUpload')"
        >
        </widget-component>
        <br>
        <widget-component
          style="margin-top: 20px; margin-right: 10px;"
          :widgetMetadata="buttons[0]"
        >
        </widget-component>
      </v-form>
    </v-container>
  </v-container>
</template>

<script>
import Loader from '../../../components/utilities/Loader.vue';
import WidgetComponent from '../../../components/ui/WidgetComponent.vue';

import FormService from '../../../services/medical_registry/form.service';

import utilities from '../../../utilities';

const { TextUtil } = utilities;

export default {
  name: 'QuestionForm',
  components: {
    Loader,
    WidgetComponent,
  },
  props: {
    isLoading: Boolean,
    onSubmit: Function,
    question: Object,
  },
  data() {
    return {
      date: null,
      FormService,
      questionPreview: {},
      queryTimer: null,
      buttons: [
        {
          type: 'button',
          btnType: 'submit',
          name: 'save',
          label: 'Save',
          color: 'primary',
          value: null,
        },
        {
          type: 'button',
          btnType: 'button',
          name: 'preview',
          label: 'Preview',
          color: 'success',
          value: null,
        },
      ],
      multipleChoiceOptionsItem: [
        {
          type: 'text',
          name: 'option',
          label: 'Option',
          placeholder: 'Enter option name',
          value: '',
          sm: '7',
          counter: 500,
          maxLength: 500,
          rules: [
            (v) => !!v || 'Option is required',
            (v) => (v && v.length <= 500) || 'Option must be less than or equal to 500 characters',
          ],
        },
        {
          type: 'text',
          name: 'code',
          label: 'Code',
          placeholder: 'Enter option code',
          value: '',
          sm: '3',
          counter: 500,
          maxLength: 500,
          readonly: true,
          rules: [
            (v) => !!v || 'Code is required',
            (v) => (v && v.length <= 500) || 'Code must be less than or equal to 500 characters',
          ],
        },
      ],
      choicesOptions: [],
      formData: {
        onSubmit: this.onSubmit,
        model: this.question,
        widgets: [
          {
            type: 'text',
            name: 'name',
            label: 'Name',
            placeholder: 'Enter question name',
            value: '',
            counter: 1000,
            maxLength: 1000,
            rules: [
              (v) => !!v || 'Name is required',
              (v) => (v && v.length <= 1000) || 'Name must be less than or equal to 1000 characters',
            ],
          },
          {
            type: 'text',
            name: 'hint',
            label: 'Hint',
            placeholder: 'Enter question hint',
            value: '',
            counter: 50,
            maxLength: 50,
            rules: [
              (v) => (v == null || v.length <= 50) || 'Hint must be less than or equal to 50 characters',
            ],
          },
          {
            type: 'text',
            name: 'code',
            label: 'Code',
            placeholder: 'Enter question code',
            value: '',
            counter: 1000,
            maxLength: 1000,
            readonly: true,
            rules: [
              (v) => !!v || 'Code is required',
              (v) => (v && v.length <= 1000) || 'Code must be less than or equal to 1000 characters',
            ],
          },
          {
            type: 'select',
            name: 'type',
            label: 'Question type',
            placeholder: 'Enter question type',
            multiple: false,
            rules: [
              (v) => !!v || 'Question type is required',
            ],
            items: [
              {
                label: 'Short answer',
                code: 'shortAnswer',
                icon: 'mdi-text-short',
              },
              {
                label: 'Paragraph',
                code: 'paragraph',
                icon: 'mdi-text-long',
              },
              {
                label: 'Number',
                code: 'number',
                icon: 'mdi-numeric',
              },
              // {
              //   label: 'Multiple choice',
              //   code: 'multipleChoice',
              //   icon: 'mdi-radiobox-marked',
              // },
              {
                label: 'Checkboxes',
                code: 'checkboxes',
                icon: 'mdi-checkbox-marked',
              },
              {
                label: 'Dropdown',
                code: 'dropdown',
                icon: 'mdi-arrow-down-drop-circle',
              },
              // {
              //   label: 'File upload',
              //   code: 'fileUpload',
              //   icon: 'mdi-cloud-upload',
              // },
              {
                label: 'Date',
                code: 'date',
                icon: 'mdi-calendar',
              },
              {
                label: 'Time',
                code: 'time',
                icon: 'mdi-clock-outline',
              },
            ],
            customFilter: (item, queryText) => {
              const label = item.label.toLowerCase();
              const searchText = queryText.toLowerCase();
              return label.indexOf(searchText) > -1;
            },
            value: '',
          },
        ],
      },
      conditionalWidgets: {
        enableDateRange: {
          type: 'switch',
          name: 'isDateRange',
          label: 'Date Range',
          value: false,
        },
        minCharacters: {
          type: 'number',
          name: 'minCharacters',
          label: 'Minimum number of characters',
          placeholder: 'Enter minimum number of characters',
          value: 2,
          min: 2,
          max: 255,
          rules: [
            (v) => !!v || 'This field is required',
            (v) => v >= 2 || 'Must be greater than 1',
            (v) => v < 256 || 'Must be less than or equal to 255',
            // if true di lalabas
            (v) => (v * 1) <= (this.conditionalWidgets.maxCharacters.value * 1) || 'Minimum must not be greater than the maximum characters',
          ],
        },
        maxCharacters: {
          type: 'number',
          name: 'maxCharacters',
          label: 'Maximum number of characters',
          placeholder: 'Enter maximum number of characters',
          value: 25,
          min: 2,
          max: 510,
          rules: [
            (v) => !!v || 'This field is required',
            (v) => v >= 2 || 'Must be greater than 1',
            (v) => v < 511 || 'Must be less than or equal to 510',
            (v) => (v * 1) >= (this.conditionalWidgets.minCharacters.value * 1) || 'Maximum must not be lower than the minimum characters',
          ],
        },
        maxNumberOfFiles: {
          type: 'number',
          name: 'maxNumberOfFiles',
          label: 'Maximum number of files',
          placeholder: 'Enter max number of files',
          value: 1,
          min: 1,
          max: 10,
          rules: [
            (v) => !!v || 'Maximum number of files is required',
            (v) => v > 0 || 'Must be greater than 0',
            (v) => v < 11 || 'Must be less than or equal to 10',
          ],
        },
        maxFileSize: {
          type: 'number',
          name: 'maxFileSize',
          label: 'Maximum filze size',
          placeholder: 'Enter max number of files',
          value: 1,
          min: 1,
          max: 10,
          suffix: 'MB',
          rules: [
            (v) => !!v || 'Maximum filze size is required',
            (v) => v > 0 || 'Must be greater than 0',
            (v) => v < 11 || 'Must be less than or equal to 10',
          ],
        },
      },
      onChangeName: null,
      warning: {},
    };
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'formData.widgets.0.value'(newValue) {
      if (this.onChangeName) {
        clearTimeout(this.onChangeName);
        this.onChangeName = null;
      }
      this.onChangeName = setTimeout(() => {
        const code = TextUtil.camelize(newValue);
        this.formData.widgets[2].value = code;
      }, 500);

      const { _id, name } = this.question || {};
      if (_id) {
        if (newValue !== name) {
          this.$set(this.warning, 'name', 'Changing the question <b>name</b> can lead to <b>data loss</b>');
        } else {
          this.$delete(this.warning, 'name');
        }
      }
    },
    // eslint-disable-next-line object-shorthand
    'formData.widgets.3.value'(newValue) {
      const { _id, type } = this.question || {};
      if (_id) {
        if (newValue !== type) {
          this.$set(this.warning, 'type', 'Changing the question <b>type</b> can lead to <b>data loss</b>');
        } else {
          this.$delete(this.warning, 'type');
        }
      }
    },
  },
  created() {
    // this.addOption(1);

    if (this.formData.model) {
      this.formData.widgets.forEach((w) => {
        const widget = w;
        widget.value = this.formData.model[widget.name];
      });

      switch (this.formData.model.type) {
        case 'shortAnswer':
        case 'paragraph': {
          const { maxCharacters, minCharacters } = this.formData.model;
          this.conditionalWidgets.maxCharacters.value = maxCharacters;
          this.conditionalWidgets.minCharacters.value = minCharacters;
          break;
        }
        case 'multipleChoice':
        case 'checkboxes':
        case 'dropdown':
          this.formData.model.choices.forEach((choice, index) => {
            const createdOption = this.addOption(index + 1);
            createdOption.forEach((option) => {
              const refOption = option;
              if (/code/i.exec(option.name)) {
                refOption.value = choice.code;
              } else if (/option/i.exec(option.name)) {
                refOption.value = choice.option;
              }
            });
          });
          break;
        case 'fileUpload': {
          const { maxNumberOfFiles, maxFileSize } = this.formData.model;
          console.log(this.formData.model, 'lmao');
          this.conditionalWidgets.maxNumberOfFiles.value = maxNumberOfFiles;
          this.conditionalWidgets.maxFileSize.value = maxFileSize;
          break;
        }
        case 'date': {
          const { isDateRange } = this.formData.model;
          this.conditionalWidgets.enableDateRange.value = isDateRange;
          break;
        }
        default:
          break;
      }
    }
  },
  methods: {
    addOption(index) {
      const newMultipleChoiceOptionsItem = this.multipleChoiceOptionsItem.map((item) => {
        const copyItem = { ...item };
        copyItem.name += `${index}`;
        copyItem.label += ` ${index}`;
        return copyItem;
      });
      const [option, code] = newMultipleChoiceOptionsItem;
      option.onChange = (value) => {
        code.value = TextUtil.camelize(value);
      };
      this.choicesOptions.push(newMultipleChoiceOptionsItem);
      return newMultipleChoiceOptionsItem;
    },
    getWidgetValue(name) {
      return this.formData.widgets.find((w) => w.name === name).value;
    },
    deleteMultipleChoiceOptionItem(index) {
      this.choicesOptions.splice(index, 1);
      this.choicesOptions.forEach((itemList, i) => {
        const [option, code] = itemList;
        const indexName = i + 1;
        option.name = `option${indexName}`;
        option.label = `Option ${indexName}`;
        code.name = `code${indexName}`;
        code.label = `Code ${indexName}`;
      });
    },
    getQuestionJSON() {
      const question = FormService.dataAsJSON(this.formData);
      const { type } = question;
      const forChoices = ['multipleChoice', 'checkboxes', 'dropdown'];
      if (forChoices.includes(type)) {
        question.choices = [];
        this.choicesOptions.forEach((o) => {
          const [option, code] = o;
          question.choices.push({
            option: option.value,
            code: code.value,
          });
        });
      } else {
        delete question.choices;
      }

      if (['shortAnswer', 'paragraph'].includes(type)) {
        question.minCharacters = this.conditionalWidgets.minCharacters.value * 1;
        question.maxCharacters = this.conditionalWidgets.maxCharacters.value * 1;
      } else {
        delete question.minCharacters;
        delete question.maxCharacters;
      }

      if (type === 'date') {
        question.isDateRange = this.conditionalWidgets.enableDateRange.value;
      } else {
        delete question.isDateRange;
      }

      if (type === 'fileUpload') {
        question.maxNumberOfFiles = this.conditionalWidgets.maxNumberOfFiles.value;
        question.maxFileSize = this.conditionalWidgets.maxFileSize.value;
      } else {
        delete question.maxNumberOfFiles;
        delete question.maxFileSize;
      }

      return question;
    },
    onClickPreview() {
      this.questionPreview = this.getQuestionJSON();
    },
    onSave() {
      if (this.$refs.form.validate()) {
        const questionChanges = this.getQuestionJSON();
        const question = Object.assign(this.question || {}, questionChanges);
        this.onSubmit(question);
      }
    },
  },
};
</script>

<style scoped>
.create-permission-button {
  color: #6E0D1C;
  letter-spacing: 0;
}
</style>
