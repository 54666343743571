class ConsentDocumentService {
  constructor({ axios }) {
    this.axios = axios;
    this.url = '/api/medical/consent-documents';
  }

  async getList({ query = '', dates = [] } = {}) {
    const result = await this.axios.get(this.url, {
      params: {
        query,
        ...(Object.keys(dates).length > 0 && dates),
      },
    });
    const { data } = result;
    return data;
  }

  async getOne({ id }) {
    const { data } = await this.axios.get(`${this.url}/${id}`);
    return data;
  }

  async create({ consentDocument }) {
    const { data } = await this.axios.post(this.url, consentDocument);
    return data;
  }

  async upload({ id, formData }) {
    const { data } = await this.axios.put(`${this.url}/upload/${id}`, formData);
    return data;
  }

  async update({ consentDocument }) {
    const { data } = await this.axios.put(this.url, consentDocument);
    return data;
  }
}

export default ConsentDocumentService;
