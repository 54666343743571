<template>
  <v-container>
  <loader :isLoading="isLoading"></loader>
  <landing-create :content="content"></landing-create>
  <v-card>
    <v-row>
      <v-col
        cols="12"
        md="12"
        xl="12"
        class="role-section">
        <div style="background-color: rd">
          <role-form
            v-if="roleModel._id"
            :role-model="roleModel"
            @onSubmit="onSubmit"
          ></role-form>
        </div>
      </v-col>
    </v-row>
  </v-card>
  </v-container>
</template>

<script>
import RoleForm from './RoleForm.vue';
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import services from '../../../services';
import Loader from '../../../components/utilities/Loader.vue';

const { controlPanelRegistry } = services;
const { roleService } = controlPanelRegistry;

export default {
  name: 'RoleUpdate',
  components: {
    LandingCreate,
    RoleForm,
    Loader,
  },
  methods: {
    async onSubmit(dataChange) {
      const data = Object.assign(this.roleModel || {}, dataChange);

      this.isLoading = true;
      await roleService.update({ role: data });
      this.$snackbar.show({
        showClose: Boolean,
        text: 'Successfully updated a role!',
        timeout: 4000,
        closeColor: 'white',
        color: 'green',
      });
      this.isLoading = false;
      this.$router.replace({ name: 'role.list' });
    },
  },
  data() {
    return {
      roleDrawer: false,
      content: {
        pageTitle: 'Update Role',
        enableBackButton: true,
      },
      roleModel: {},
      isLoading: false,
    };
  },
  async created() {
    const { id } = this.$route.params;
    this.roleModel = await roleService.getOne({ id });
  },
};
</script>
<style scoped>

.role-form-subheader {
  padding-left: 20px;
}
.role-section{
  padding: 40px;
}
.create-permission-button {
  color: #6E0D1C;
  margin-right: 10px;
  letter-spacing: 0;
}
</style>
