var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "home-page"
  }, [_c('v-row', {
    staticClass: "text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "selection-outer-container"
  }, _vm._l(_vm.items, function (item) {
    return _c('v-col', {
      key: item.title,
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('v-card', {
      staticClass: "mx-auto my-12 v-btn selection-container",
      attrs: {
        "width": "300",
        "height": "300",
        "to": {
          name: item.link
        }
      }
    }, [_c('div', {
      staticClass: "selection-body"
    }, [_c('v-icon', {
      staticClass: "mb-5",
      style: {
        'font-size': '151px'
      }
    }, [_vm._v(" " + _vm._s(item.icon) + " ")]), _c('p', [_vm._v(_vm._s(item.title))])], 1)])], 1);
  }), 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }