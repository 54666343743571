<template>
  <v-container>
    <loader :isLoading="isLoading"></loader>
    <form-component
      :formData="formData"
    >
    </form-component>
  </v-container>
</template>

<!-- eslint-disable no-underscore-dangle -->
<script>
import Loader from '../../../components/utilities/Loader.vue';
import FormComponent from '../../../components/ui/FormComponent.vue';
// import WidgetComponent from '../../../components/ui/WidgetComponent.vue';
import services from '../../../services';

const { controlPanelRegistry } = services;
const { roleService } = controlPanelRegistry;

export default {
  name: 'UserForm',
  props: {
    userModel: Object,
  },
  components: {
    Loader,
    // WidgetComponent,
    FormComponent,
  },
  watch: {
    userModel(value) {
      this.formData.model = value;
    },
  },
  async created() {
    const result = await roleService.getList();
    this.formData.widgets[4].items = result.map((item) => {
      item.label = item.name;
      item.code = item._id;
      return item;
    });
  },
  data() {
    return {
      isLoading: false,
      formData: {
        onSubmit: this.onSubmit,
        model: this.userModel,
        widgets: [
          {
            type: 'text',
            name: 'email',
            label: 'Member email',
            placeholder: 'Member Email',
            value: '',
            counter: 50,
            maxLength: 50,
            rules: [
              (v) => !!v || 'Email is required',
              (v) => this.validateEmail(v) || 'Must be a valid email',
              (v) => (v == null || v.length <= 50) || 'Email must be less than or equal to 50 characters',
            ],
          },
          {
            type: 'text',
            name: 'firstName',
            label: 'First Name',
            placeholder: 'First Name',
            value: '',
            counter: 50,
            maxLength: 50,
            rules: [
              (v) => !!v || 'First Name is required',
              (v) => (v == null || v.length <= 50) || 'First name must be less than or equal to 50 characters',
            ],
          },
          {
            type: 'text',
            name: 'middleName',
            label: 'Middle Name',
            placeholder: 'Middle Name',
            value: '',
            counter: 50,
            maxLength: 50,
            rules: [
              (v) => !!v || 'Middle name is required',
              (v) => (v == null || v.length <= 50) || 'Middle name must be less than or equal to 50 characters',
            ],
          },
          {
            type: 'text',
            name: 'lastName',
            label: 'Last Name',
            placeholder: 'Last Name',
            value: '',
            counter: 50,
            maxLength: 50,
            rules: [
              (v) => !!v || 'Last Name is required',
              (v) => (v == null || v.length <= 50) || 'Last name must be less than or equal to 50 characters',
            ],
          },
          {
            type: 'select',
            name: 'roles',
            label: 'Roles',
            placeholder: 'Select Roles',
            multiple: true,
            rules: [
              (v) => !!v || 'Roles is required',
            ],
            items: [
              // {
              //   label: 'Admin Role',
              //   code: '63679380c62d0a2017e47b67',
              // },
            ],
            value: '',
          },
          {
            type: 'button',
            btnType: 'submit',
            name: 'save',
            label: 'Save',
            color: 'primary',
            value: null,
          },
        ],
      },
    };
  },
  methods: {
    async onSubmit(data) {
      this.$emit('onSubmit', data);
    },
    validateEmail(email) {
      const isValid = String(email)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      return isValid !== null;
    },
  },
};
</script>
