<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <template v-if="specimenType">
      <specimen-type-form
        :isLoading="isLoading"
        :specimenType="specimenType"
        :onSubmit="onSubmit">
      </specimen-type-form>
    </template>
  </v-container>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import SpecimenTypeForm from './SpecimenTypeForm.vue';
import services from '../../../services';

const { biobankRegistry } = services;
const { specimenTypeService } = biobankRegistry;

export default {
  name: 'SpecimenCreate',
  components: {
    SpecimenTypeForm,
    LandingCreate,
  },
  data: () => ({
    isLoading: false,
    content: {
      pageTitle: 'Update Specimen Type',
      enableBackButton: true,
    },
    specimenType: null,
  }),
  async created() {
    const { id } = this.$route.params;
    const specimenType = await specimenTypeService.getOne({ id });
    this.specimenType = specimenType;
  },
  methods: {
    async onSubmit(dataChange) {
      const data = Object.assign(this.specimenType || {}, dataChange);

      this.isLoading = true;
      try {
        await specimenTypeService.update({ specimenType: data });

        this.$snackbar.showOk({ text: 'Successfully updated specimen type!' });

        this.$router.push({ name: 'specimen-type.list' });
      } catch (e) {
        console.error(e);
      }
      this.isLoading = false;
    },
  },
};
</script>
