var render = function () {
  var _vm$formMetadata$butt, _vm$formMetadata$butt2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', [_c('v-card-title', [_vm._v(_vm._s(_vm.watchFormMetadata.name))]), _c('v-card-text', [_c('nested-display-form', {
    attrs: {
      "formMetaData": {
        questionList: _vm.formData.widgets
      }
    }
  }), _c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": ((_vm$formMetadata$butt = _vm.formMetadata.button) === null || _vm$formMetadata$butt === void 0 ? void 0 : _vm$formMetadata$butt.label) === 'save' ? _vm.isReadOnly : false
    },
    on: {
      "click": _vm.onClick
    }
  }, [_vm._v(" " + _vm._s(((_vm$formMetadata$butt2 = _vm.formMetadata.button) === null || _vm$formMetadata$butt2 === void 0 ? void 0 : _vm$formMetadata$butt2.label) || 'Save') + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }