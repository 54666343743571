var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('draggable', {
    staticClass: "dragArea",
    attrs: {
      "data-display": _vm.display,
      "list": _vm.questionsMetadata.widgets,
      "move": _vm.checkMove,
      "draggable": ".draggable",
      "component-data": _vm.getComponentData(),
      "group": {
        name: 'question'
      },
      "force-fallback": true,
      "scroll-sensitivity": 200
    }
  }, _vm._l(_vm.questionsMetadata.widgets, function (question, i) {
    var _question$parent;

    return _c('div', {
      key: question.name + i,
      staticClass: "draggable"
    }, [question.display === 'section' ? _c('v-card', {
      staticStyle: {
        "margin-bottom": "25px"
      }
    }, [_c('v-tabs', [_c('v-tab', [_vm._v("Section")]), _c('v-tab', [_vm._v("Condition")]), _c('v-tab-item', [_c('v-card-text', {
      staticStyle: {
        "margin-bottom": "-38px"
      }
    }, [_c('widget-component', {
      attrs: {
        "widgetMetadata": question
      }
    })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
      attrs: {
        "color": "red",
        "text": ""
      },
      on: {
        "click": function ($event) {
          return _vm.onClickRemove(question);
        }
      }
    }, [_vm._v("Remove")]), _c('v-btn', {
      attrs: {
        "color": "blue",
        "text": ""
      },
      on: {
        "click": function ($event) {
          return _vm.onClickAdd(question);
        }
      }
    }, [_vm._v("Add")])], 1), question.widgets ? [_c('nested-question', {
      attrs: {
        "display": 'display',
        "questionsMetadata": {
          questionRawList: _vm.questionsMetadata.questionRawList,
          questionList: _vm.questionsMetadata.questionList,
          widgets: question.widgets,
          parentWidget: question
        }
      }
    })] : _vm._e()], 2)], 1)], 1) : _vm._e(), question.display === 'question' ? _c('v-card', {
      staticStyle: {
        "margin-bottom": "25px"
      }
    }, [_c('v-tabs', [_c('v-tab', [_vm._v("Question")]), _c('v-tab', [_vm._v("Condition")]), _c('v-tab-item', [_c('v-card', [_c('v-card-text', [_c('div', {
      staticClass: "text--primary"
    }, [_c('widget-component', {
      attrs: {
        "widgetMetadata": question
      }
    }), _c('widget-component', {
      staticStyle: {
        "margin-top": "-10px"
      },
      attrs: {
        "widgetMetadata": question.conditionalWidgets.isRequired
      }
    }), _c('v-card', [_c('v-card-title', {
      staticClass: "text-overline"
    }, [_c('h4', [_vm._v("Preview")])]), _c('v-card-text', [_c('widget-component', {
      attrs: {
        "widgetMetadata": question.preview
      }
    })], 1)], 1)], 1)]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
      attrs: {
        "color": "red",
        "text": ""
      },
      on: {
        "click": function ($event) {
          return _vm.onClickRemove(question);
        }
      }
    }, [_vm._v("Remove")]), ((_question$parent = question.parent) === null || _question$parent === void 0 ? void 0 : _question$parent.display) !== 'section' ? _c('v-btn', {
      attrs: {
        "color": "orange",
        "text": ""
      },
      on: {
        "click": function ($event) {
          return _vm.onClickGroup(question);
        }
      }
    }, [_vm._v(" Group ")]) : _vm._e(), _c('v-btn', {
      attrs: {
        "color": "blue",
        "text": ""
      },
      on: {
        "click": function ($event) {
          return _vm.onClickAdd(question);
        }
      }
    }, [_vm._v("Add")])], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }