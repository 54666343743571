<template>
  <div>
    <v-card>
      <v-card-title>{{ watchFormMetadata.name }}</v-card-title>
      <v-card-text>
        <nested-display-form :formMetaData="{ questionList: formData.widgets }">
        </nested-display-form>
        <v-btn
          color="primary"
          :disabled="formMetadata.button?.label === 'save' ? isReadOnly : false"
          @click="onClick"
        >
        {{ formMetadata.button?.label || 'Save' }}
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import NestedDisplayForm from './NestedDisplayForm.vue';

import FormService from '../../../../services/medical_registry/form.service';

export default {
  name: 'display-form',
  components: {
    NestedDisplayForm,
  },
  props: {
    questionList: Array,
    formMetadata: {
      _id: String,
      name: String,
      questions: Array,
      button: {
        label: String,
        onClick: Function,
      },
    },
    isReadOnly: Boolean,
    isPreviewShown: Boolean,
  },
  data: () => ({
    formData: {
      id: '',
      widgets: [],
    },
    watchFormMetadata: null,
  }),
  watch: {
    formMetadata(newValue) {
      this.watchFormMetadata = newValue;
    },
    watchFormMetadata: {
      handler() {
        this.prepareSelectedQuestionsToDisplay();
      },
      deep: true,
    },
  },
  async created() {
    this.watchFormMetadata = this.formMetadata;
  },
  methods: {
    onClick() {
      if (this.formMetadata.button) {
        this.formMetadata.button.onClick();
      }
    },
    getData() {
      return {
        id: this.formData.id,
        answer: FormService.dataAsJSON(this.formData),
      };
    },
    prepareSelectedQuestionsToDisplay() {
      const mapQuestion = (question, index, parent) => {
        const { display } = question;
        if (display === 'question') {
          const selectedQuestion = this.questionList.find((q) => {
            const { _id } = q;
            const { _id: questionId } = question;
            return _id === questionId;
          });
          if (question.value) {
            selectedQuestion.value = question.value;
          }
          return {
            index,
            parent,
            display,
            ...FormService.getValidWidgetJSON(selectedQuestion),
          };
        } if (display === 'section') {
          const { questions, title } = question;
          const sectionWidget = {
            index,
            parent,
            display,
            title,
            name: `section${index}`,
          };
          return {
            ...sectionWidget,
            widgets: questions.map(
              (_question, _index) => mapQuestion(_question, _index, sectionWidget),
            ),
          };
        }
        return null;
      };
      // eslint-disable-next-line no-underscore-dangle
      this.formData.id = this.formMetadata._id;
      this.formData.widgets = this.formMetadata.questions.map(
        (question, index) => mapQuestion(question, index, this.formMetadata.questions),
      );
    },
  },
};
</script>
