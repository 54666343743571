class PermissionService {
  constructor({ axios }) {
    this.axios = axios;
  }

  async getList({ query, dates }) {
    const result = await this.axios.get('/api/permissions', {
      params: {
        query,
        ...(Object.keys(dates).length > 0 && dates),
      },
    });
    const { data } = result;
    return data;
  }

  async getOne({ id }) {
    const { data } = await this.axios.get(`/api/permissions/${id}`);
    return data;
  }

  async create({ permission }) {
    const { data } = await this.axios.post('/api/permissions', permission);
    return data;
  }

  async update({ permission }) {
    const { data } = await this.axios.put('/api/permissions', permission);
    return data;
  }
}

export default PermissionService;
