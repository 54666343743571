<template>
  <v-container>
    <loader :isLoading="isLoading"></loader>
    <landing-create :content="content"></landing-create>
    <v-card>
      <v-row>
        <v-col
          class="role-section">
          <div style="background-color: rd">
            <user-form
              v-if="userModel._id"
              :user-model="userModel"
              @onSubmit="onSubmit"
            ></user-form>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import UserForm from './UserForm.vue';
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import services from '../../../services';
import Loader from '../../../components/utilities/Loader.vue';

const { controlPanelRegistry } = services;
const { userService } = controlPanelRegistry;

export default {
  name: 'UserUpdate',
  components: {
    LandingCreate,
    UserForm,
    Loader,
  },
  methods: {
    async onSubmit(dataChange) {
      const data = Object.assign(this.userModel || {}, dataChange);

      this.isLoading = true;
      await userService.update({ user: data });
      this.$snackbar.show({
        showClose: Boolean,
        text: 'Successfully updated a user!',
        timeout: 4000,
        closeColor: 'white',
        color: 'green',
      });
      this.isLoading = false;
      this.$router.replace({ name: 'user.list' });
    },
  },
  data() {
    return {
      roleDrawer: false,
      userModel: {},
      isLoading: false,
      content: {
        pageTitle: 'Update User',
        enableBackButton: true,
      },
    };
  },
  async created() {
    const { id } = this.$route.params;
    this.userModel = await userService.getOne({ id });
  },
};
</script>
<style scoped>

.role-form-subheader {
  padding-left: 20px;
}
.role-section{
  padding: 40px;
}
.valueset-search {
  padding: 40px;
}
.create-permission-button {
  color: #6E0D1C;
  margin-right: 10px;
  letter-spacing: 0;
}
</style>
