<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <loader :isLoading="isLoading"></loader>
    <view-component v-if="attributes" :attributes="attributes"></view-component>
    <v-card width="500">
      <v-list>
        <v-list-group :value="true" v-for="category in categories"
          :key="category.category"
          sub-group
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ category.category }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-group
            :value="true"
            v-for="m in category.modules"
            :key="m.module"
            no-action
            sub-group
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ m.module }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="permission in m.permissions" :key="permission._id">
              <v-list-item-title class="redirect" @click="onClickReference(permission._id)">
                {{ permission.code }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list-group>
      </v-list>
    </v-card>
  </v-container>
</template>

<style>
  .redirect {
    color: #2196F3 !important;
    cursor: pointer;
  }
</style>

<!-- eslint-disable no-underscore-dangle -->
<script>
import Loader from '../../../components/utilities/Loader.vue';
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import ViewComponent from '../../../components/ViewComponent.vue';

import services from '../../../services';

const { controlPanelRegistry } = services;
const {
  roleService,
  permissionService,
  userService,
} = controlPanelRegistry;

export default {
  components: {
    Loader,
    LandingCreate,
    ViewComponent,
  },
  data: () => ({
    isLoading: false,
    attributes: null,
    content: {
      pageTitle: 'Role View',
      enableBackButton: true,
    },
    categories: [],
  }),
  async created() {
    this.isLoading = true;

    const { id } = this.$route.params;
    const role = await roleService.getOne({ id });
    const permissions = await Promise.all(
      role.permissions.map((pID) => permissionService.getOne({ id: pID })),
    );

    const toUniqueArray = (arr = []) => Array.from(new Set(arr));

    this.categories = toUniqueArray(
      permissions.map((p) => p.category),
    ).map((category) => ({
      category,
      modules: toUniqueArray(
        permissions.filter((p) => p.category === category)
          .map((p) => p.module),
      ).map((module) => ({
        module,
        permissions: permissions.filter((p) => p.category === category && p.module === module),
      })),
    }));

    const mapUser = (user) => ({ id: user._id, name: `${user.firstName}, ${user.lastName}` });

    const createdBy = await userService.getOne({ id: role.createdBy });
    const updatedBy = await userService.getOne({ id: role.updatedBy });

    this.attributes = this.prepareAttribute({
      ...role,
      createdBy: mapUser(createdBy),
      updatedBy: mapUser(updatedBy),
    });

    this.isLoading = false;
  },
  methods: {
    onClickReference(id) {
      console.log('testing');
      this.$router.push({ name: 'permission.view', params: { id } });
    },
    prepareAttribute({
      name, createdBy, createdAt, updatedBy, dateUpdated,
    }) {
      console.log(name, 'name');
      return [
        {
          label: 'Name',
          value: name,
        },
        {
          label: 'Created by',
          value: { id: createdBy.id, name: createdBy.name },
          reference: true,
          URLName: 'user.view',
        },
        {
          label: 'Created date',
          value: createdAt,
          type: 'datetime',
        },
        {
          label: 'Updated by',
          value: { id: updatedBy.id, name: updatedBy.name },
          reference: true,
          URLName: 'user.view',
        },
        {
          label: 'Updated date',
          value: dateUpdated,
          type: 'datetime',
        },
      ];
    },
  },
};
</script>
