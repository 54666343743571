<template>
<div class="login-page fill-height fluid no-gutters">
  <v-layout>
    <v-app-bar
      elevation="4"
    >
      <v-img
        :src="require('../assets/up-pgh-biobank.png')"
        class="my-3 header-image"
        contain
        height="100%"
      />
    </v-app-bar>
  </v-layout>
  <v-layout>
    <v-row style="padding: 20px" class="mt-5">
      <v-col lg="4" sm="1"></v-col>
      <v-col lg="4" sm="10">
        <div class="text-center mb-4">
          <div
            :class="['text-h3', 'mb-2']"
            class="transition-swing"
            v-text="'Welcome back!'"
          >
          </div>
          <div
            :class="['text-h5', 'mb-2']"
            class="transition-swing"
            v-text="'Clinical Information & Biospecimen Registry'"
          >
          </div>
        </div>
        <div>
          <validation-observer ref="observer" v-slot="{ invalid }">
            <form @submit.prevent="handleSubmit">
              <validation-provider
                v-slot="{ errors }"
                name="email"
                rules="required|email">
                <label  class="subtitle-1 font-weight-bold">Email</label>
                <v-text-field
                  v-model="email"
                  type="email"
                  :error-messages="errors"
                  outlined
                  dense
                  height="70"
                  class="login--input"
                ></v-text-field>
              </validation-provider>

              <label class="subtitle-1 font-weight-bold">Password</label>
              <v-text-field
                v-model="password"
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show3 ? 'text' : 'password'"
                name="input-10-1"
                @click:append="show3 = !show3"
                outlined
                dense
                height="70"
              ></v-text-field>

              <div class="text-right mb-4">
                <a
                  href="javascript:void(0)"
                  @click="$router.replace({ name: 'ForgotPassword' })"
                >
                  Forgot Password?
                </a>
              </div>

              <v-row>
                <v-spacer></v-spacer>
              </v-row>
              <br>
              <v-btn
                dense
                :disabled="invalid"
                style="width: 100%;"
                type="submit"
                class="login--button primary">
                Log In
              </v-btn>
            </form>
          </validation-observer>

          <div class="text-center mb-4">
            <br>
            {{ version }}
          </div>
          <hr class="my-10">

        </div>
      </v-col>
      <v-col lg="4" sm="1"></v-col>
    </v-row>
  </v-layout>

  <loader
    :is-loading="isLoading"
    :loader-style="'dots'"
  >
  </loader>

</div>
</template>

<script>
import { required, email } from 'vee-validate/dist/rules';
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from 'vee-validate';

import services from '../services';
import Loader from '../components/utilities/Loader.vue';

const { authService } = services;

setInteractionMode('eager');

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

extend('email', {
  ...email,
  message: 'Email must be valid',
});

export default {
  name: 'LoginPage',
  components: {
    ValidationProvider,
    ValidationObserver,
    Loader,
  },
  data() {
    return {
      version: null,
      show3: false,
      email: '',
      password: '',
      isLoading: false,
    };
  },
  async created() {
    this.version = process.env.VUE_APP_VERSION;
    const user = await authService.getSignedInUser();
    this.$store.dispatch('setLoggedInUser', user);
    if (user) {
      this.$router.replace({ name: 'HomePage' });
    }
  },
  methods: {
    async handleSubmit() {
      try {
        this.isLoading = true;
        const user = await authService.doSignIn({ email: this.email, password: this.password });

        this.$store.dispatch('setLoggedInUser', user);
        this.$router.replace({ name: 'HomePage' });
        this.$snackbar.show({ color: 'success', text: 'Signed in successfully!', closeColor: 'white' });
      } catch (e) {
        const { message } = e.response.data;
        this.$snackbar.show({ color: 'red', text: message, closeColor: 'white' });
      }

      this.isLoading = false;
    },
  },
};
</script>

<style scoped>

.header-image {
  flex: 0 0 auto;
}

.login--button {
  color: #fff !important;
  height: 50px !important;
}

.login-page {
  overflow: hidden auto;
  background: url(../assets/login-image.png) no-repeat 100% 100%,#f8eee7;
  /* background-color: rgb(87, 177, 142); */
  /* background-image: url("../assets/login.svg"); */
  background-attachment: fixed;
  overflow: hidden;
  /* background-size: cover; */
}

</style>
