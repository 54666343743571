var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "grid-list-lg": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Search",
      "required": ""
    },
    model: {
      value: _vm.query,
      callback: function ($$v) {
        _vm.query = $$v;
      },
      expression: "query"
    }
  })], 1), _vm._l(_vm.tableMetaData.tableFilters.selections, function (selection, index) {
    return _c('v-flex', {
      key: ("select" + index),
      attrs: {
        "xs3": ""
      }
    }, [_c('v-select', {
      attrs: {
        "label": selection.text,
        "items": selection.items,
        "item-text": selection.item.text,
        "item-value": selection.item.id
      },
      on: {
        "input": _vm.onSelect
      },
      model: {
        value: _vm.selections[selection.column],
        callback: function ($$v) {
          _vm.$set(_vm.selections, selection.column, $$v);
        },
        expression: "selections[selection.column]"
      }
    })], 1);
  }), _vm._l(_vm.tableMetaData.tableFilters.dates, function (row, index) {
    return _c('v-flex', {
      key: ("date" + index),
      attrs: {
        "xs3": ""
      }
    }, [_c('v-menu', {
      ref: "menu",
      refInFor: true,
      attrs: {
        "close-on-content-click": false,
        "return-value": _vm.dates[index].value,
        "transition": "scale-transition",
        "offset-y": "",
        "min-width": "auto"
      },
      on: {
        "update:returnValue": function ($event) {
          return _vm.$set(_vm.dates[index], "value", $event);
        },
        "update:return-value": function ($event) {
          return _vm.$set(_vm.dates[index], "value", $event);
        }
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
              attrs = _ref.attrs;
          return [_c('v-text-field', _vm._g(_vm._b({
            attrs: {
              "label": row.text,
              "prepend-icon": "mdi-calendar",
              "readonly": ""
            },
            model: {
              value: _vm.dates[index].value,
              callback: function ($$v) {
                _vm.$set(_vm.dates[index], "value", $$v);
              },
              expression: "dates[index].value"
            }
          }, 'v-text-field', attrs, false), on))];
        }
      }], null, true),
      model: {
        value: _vm.dates[index].menu,
        callback: function ($$v) {
          _vm.$set(_vm.dates[index], "menu", $$v);
        },
        expression: "dates[index].menu"
      }
    }, [_c('v-date-picker', {
      attrs: {
        "no-title": "",
        "range": "",
        "scrollable": ""
      },
      model: {
        value: _vm.dates[index].value,
        callback: function ($$v) {
          _vm.$set(_vm.dates[index], "value", $$v);
        },
        expression: "dates[index].value"
      }
    }, [_c('v-spacer'), _c('v-btn', {
      attrs: {
        "text": "",
        "color": "primary"
      },
      on: {
        "click": function ($event) {
          _vm.dates[index].menu = false;
        }
      }
    }, [_vm._v(" Cancel ")]), _c('v-btn', {
      attrs: {
        "text": "",
        "color": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.onSelectDate(_vm.$refs, index);
        }
      }
    }, [_vm._v(" OK ")])], 1)], 1)], 1);
  }), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "dense": "",
      "headers": _vm.tableMetaData.tableHeaders,
      "items": _vm.items,
      "footer-props": {
        'items-per-page-options': [15, 30, 50, 100, -1]
      },
      "loading": _vm.loading,
      "return-object": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableMetaData.tableRows || [], function (row) {
      return {
        key: ("item." + (row.key)),
        fn: function (_ref2) {
          var item = _ref2.item;
          return [_vm._v(" " + _vm._s(row.display(item)) + " ")];
        }
      };
    }), {
      key: "item.actions",
      fn: function (_ref3) {
        var _vm$tableMetaData, _vm$tableMetaData$act, _vm$tableMetaData$act2, _vm$tableMetaData2, _vm$tableMetaData2$ac, _vm$tableMetaData2$ac2, _vm$tableMetaData$act3, _vm$tableMetaData$act4, _vm$tableMetaData3, _vm$tableMetaData3$ac, _vm$tableMetaData3$ac2;

        var item = _ref3.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "disabled": (_vm$tableMetaData = _vm.tableMetaData) === null || _vm$tableMetaData === void 0 ? void 0 : (_vm$tableMetaData$act = _vm$tableMetaData.actionDisplay) === null || _vm$tableMetaData$act === void 0 ? void 0 : (_vm$tableMetaData$act2 = _vm$tableMetaData$act.view) === null || _vm$tableMetaData$act2 === void 0 ? void 0 : _vm$tableMetaData$act2.disabled(item),
            "small": "",
            "color": "blue"
          },
          on: {
            "click": function ($event) {
              return _vm.onView(item);
            }
          }
        }, [_vm._v(" mdi-eye ")]), _c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "disabled": (_vm$tableMetaData2 = _vm.tableMetaData) === null || _vm$tableMetaData2 === void 0 ? void 0 : (_vm$tableMetaData2$ac = _vm$tableMetaData2.actionDisplay) === null || _vm$tableMetaData2$ac === void 0 ? void 0 : (_vm$tableMetaData2$ac2 = _vm$tableMetaData2$ac.edit) === null || _vm$tableMetaData2$ac2 === void 0 ? void 0 : _vm$tableMetaData2$ac2.disabled(item),
            "small": "",
            "color": "pink"
          },
          on: {
            "click": function ($event) {
              return _vm.onEdit(item);
            }
          }
        }, [_vm._v(" mdi-pencil ")]), (_vm$tableMetaData$act3 = _vm.tableMetaData.actionDisplay) !== null && _vm$tableMetaData$act3 !== void 0 && (_vm$tableMetaData$act4 = _vm$tableMetaData$act3.delete) !== null && _vm$tableMetaData$act4 !== void 0 && _vm$tableMetaData$act4.visible && _vm.permissionCode.includes('MEDICAL_QUESTIONS_CAN_DELETE') ? _c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.onArchive(item);
            }
          }
        }, [_vm._v(" " + _vm._s(((_vm$tableMetaData3 = _vm.tableMetaData) === null || _vm$tableMetaData3 === void 0 ? void 0 : (_vm$tableMetaData3$ac = _vm$tableMetaData3.actionDisplay) === null || _vm$tableMetaData3$ac === void 0 ? void 0 : (_vm$tableMetaData3$ac2 = _vm$tableMetaData3$ac.delete) === null || _vm$tableMetaData3$ac2 === void 0 ? void 0 : _vm$tableMetaData3$ac2.icon(item)) || 'mdi-trash-can') + " ")]) : _vm._e()];
      }
    }], null, true)
  })], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }