var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('landing-create', {
    attrs: {
      "content": _vm.content
    }
  }), _c('list-component', {
    attrs: {
      "service": _vm.service,
      "tableMetaData": _vm.tableMetaData
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }