var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-form', {
    ref: "form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onSave.apply(null, arguments);
      }
    }
  }, [_c('loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _vm._l(_vm.widgets, function (widgetMetadata, index) {
    return _c('widget-component', {
      key: ("widget" + index),
      attrs: {
        "widgetMetadata": widgetMetadata
      }
    });
  }), _vm._t("default"), _vm._l(_vm.widgetButtons, function (widgetMetadata, index) {
    return _c('widget-component', {
      key: ("widget_btns" + index),
      attrs: {
        "widgetMetadata": widgetMetadata
      }
    });
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }