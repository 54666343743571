<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <template v-if="consentDocument">
      <consent-document-form
        :isLoading="isLoading"
        :onSubmit="onSubmit"
        :consentDocument="consentDocument"
      ></consent-document-form>
    </template>
  </v-container>
</template>

<!-- eslint-disable no-underscore-dangle -->
<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import ConsentDocumentForm from './ConsentDocumentForm.vue';
import services from '../../../services';

const { medicalRegistry } = services;
const { consentDocumentService } = medicalRegistry;

export default {
  name: 'ConsentDocumentUpdate',
  components: {
    LandingCreate,
    ConsentDocumentForm,
  },
  data: () => ({
    isLoading: false,
    content: {
      pageTitle: 'Consent Document Update',
      enableBackButton: true,
    },
    consentDocument: null,
  }),
  async created() {
    const { id } = this.$route.params;
    this.consentDocument = await consentDocumentService.getOne({ id });
  },
  methods: {
    async onSubmit(dataChanges) {
      const data = Object.assign(this.consentDocument || {}, dataChanges);

      this.isLoading = true;
      try {
        const { _id, _metadata } = this.consentDocument;
        const consentDocumentData = {
          _id,
          _metadata,
          name: data.name,
          description: data.description,
        };
        await consentDocumentService.update({ consentDocument: consentDocumentData });
        if (data.attachment) {
          const formData = new FormData();
          formData.append('attachment', data.attachment[0]);
          await consentDocumentService.upload({ id: _id, formData });
        }
        this.isLoading = false;

        this.$snackbar.showOk({ text: 'Successfully updated consent document!' });

        this.$router.push({ name: 'consent-document.list' });
      } catch (e) {
        this.isLoading = false;
        this.$snackbar.showBad({ text: 'Something weng wrong!' });
        console.error(e);
      }
      this.isLoading = false;
    },
  },
};
</script>
