<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <list-component
      :service="service"
      :tableMetaData="tableMetaData"
    ></list-component>
  </v-container>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import ListComponent from '../../../components/ListComponent.vue';
import services from '../../../services';

const { medicalRegistry } = services;
const { protocolNumberService } = medicalRegistry;

export default {
  name: 'ProtocolNumberList',
  components: {
    LandingCreate,
    ListComponent,
  },
  data: () => ({
    service: protocolNumberService,
    content: {
      pageTitle: 'Protocol Number',
      commands: [
        {
          link: 'protocol-number.create',
          icon: 'mdi-plus-box',
          buttonName: 'Create',
        },
      ],
    },
    tableMetaData: {
      tableFilters: {
        selections: [],
        dates: [
          {
            text: 'Date created',
            column: 'dateCreated',
          },
        ],
      },
      tableHeaders: [
        {
          text: 'Protocol Number',
          value: 'protocolNumber',
        },
        {
          text: 'Created by',
          value: '_metadata.created.user.fullName',
        },
        {
          text: 'Date created',
          value: '_metadata.created.date',
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ],
      actions: {
        view: false,
      },
      URLName: 'protocol-number',
    },
  }),
};
</script>
