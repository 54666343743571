<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <protocol-number-form
      v-if="protocolNumber"
      :isLoading="isLoading"
      :onSubmit="onSubmit"
      :protocolNumber="protocolNumber"
    ></protocol-number-form>
  </v-container>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import ProtocolNumberForm from './ProtocolNumberForm.vue';
import services from '../../../services';

const { medicalRegistry } = services;
const { protocolNumberService } = medicalRegistry;

export default {
  name: 'ProtocolNumberUpdate',
  components: {
    LandingCreate,
    ProtocolNumberForm,
  },
  data: () => ({
    isLoading: false,
    content: {
      pageTitle: 'Protocol Number Update',
      enableBackButton: true,
    },
    protocolNumber: null,
  }),
  async created() {
    const { id } = this.$route.params;
    this.protocolNumber = await protocolNumberService.getOne({ id });
  },
  methods: {
    async onSubmit(data) {
      this.isLoading = true;
      try {
        await protocolNumberService.update({ question: data });

        this.$snackbar.show({
          showClose: Boolean,
          text: 'Successfully updated protocol number!',
          timeout: 4000,
          closeColor: 'white',
          color: 'green',
        });

        this.$router.push({ name: 'protocol-number.list' });
      } catch (e) {
        console.error(e);
      }
      this.isLoading = false;
    },
  },
};
</script>
