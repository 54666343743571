var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('landing-create', {
    attrs: {
      "content": _vm.createQuestionContent
    }
  }), _vm.question ? _c('question-form', {
    attrs: {
      "isLoading": _vm.isLoading,
      "question": _vm.question,
      "onSubmit": _vm.onSubmit
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }