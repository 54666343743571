class CategoryService {
  constructor() {
    this.data = [
      {
        label: 'Medical Registry',
        code: 'Medical Registry',
      },
      {
        label: 'Biobank Registry',
        code: 'Biobank Registry',
      },
      {
        label: 'Control Panel',
        code: 'Control Panel',
      },
    ];
  }

  async getList() {
    return new Promise((res) => {
      res(this.data);
    });
  }

  getOne({ id }) {
    return new Promise((res) => {
      const dept = this.data.find((datum) => datum.id === id);
      res(dept);
    });
  }
}

export default CategoryService;
