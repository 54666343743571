import SidebarStandard from '../../components/sidebars/SidebarStandard.vue';

export default {
  computed: {
    sidebarContent() {
      return {
        title: {
          description: 'Biobank Registry',
          icon: 'mdi-flask-outline',
          link: 'MedicalCaseList',
        },
        items: [
          {
            title: 'Specimen State',
            icon: 'mdi-shape-outline',
            link: 'specimen-state.list',
          },
          {
            title: 'Specimen Type',
            icon: 'mdi-flask-outline',
            link: 'specimen-type.list',
          },
          {
            title: 'MTA Document',
            icon: 'mdi-file-document',
            link: 'mutual-transfer-agreement.list',
          },
          {
            title: 'Case',
            icon: 'mdi-stethoscope',
            link: 'bcase.list',
          },
        ],
      };
    },
  },
  components: {
    SidebarStandard,
  },
};
