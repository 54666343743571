var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "persistent": _vm.presistent,
      "max-width": "290"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h6"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  })]), _c('v-card-text', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.text)
    }
  })]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "blue",
      "dark": "",
      "text": ""
    },
    on: {
      "click": _vm.runCallback
    }
  }, [_vm._v(" " + _vm._s(_vm.okayText) + " ")]), !_vm.isMessage ? _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.cancelText) + " ")]) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }