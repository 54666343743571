<template>
  <v-container class="generic-container">
  <loader :isLoading="isLoading"></loader>
  <page-header :headerTitle="headerTitle"></page-header>
    <v-card>
      <v-row class="">
        <v-col
          cols="6"
          md="4"
          xl="3"
          class="role-section">
          <form style="background-color: rd" @submit.prevent="submit">
            <v-row>
              <h2 class="role-form-subheader">Change Password</h2>
            </v-row>
            <br>
            <v-text-field
              outlined
              dense
              label="Current Password"
              type="password"
              required
              name="passwordCurrent"
              v-model="passwordCurrent"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              label="New Password"
              type="password"
              required
              name="password"
              v-model="password"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              label="New Password"
              type="password"
              required
              name="passwordConfirmation"
              v-model="passwordConfirmation"
            ></v-text-field>
            <br>

            <v-btn
              type="submit"
              class="mr-4 primary">
                Submit
            </v-btn>
          </form>
        </v-col>
      </v-row>
    </v-card>
    </v-container>
</template>

<script>

import PageHeader from '../../components/ui/PageHeader.vue';
import services from '../../services';
import Loader from '../../components/utilities/Loader.vue';

const { controlPanelRegistry } = services;
const { userService } = controlPanelRegistry;

export default {
  name: 'ChangePassword',
  components: {
    PageHeader,
    Loader,
  },
  methods: {
    async submit() {
      try {
        this.isLoading = true;
        const { message } = await userService.changePassword({
          passwordConfirmation: this.passwordConfirmation,
          passwordCurrent: this.passwordCurrent,
          password: this.password,
        });
        this.$router.replace({ name: 'HomePage' });
        this.$snackbar.show({ color: 'success', text: message, closeColor: 'white' });
      } catch (e) {
        const { message } = e.response.data;
        this.$snackbar.show({ color: 'red', text: message, closeColor: 'white' });
      }

      this.isLoading = false;
    },
  },
  data() {
    return {
      headerTitle: 'User Account Settings',
      passwordConfirmation: '',
      passwordCurrent: '',
      password: '',
      isLoading: false,
    };
  },
};
</script>
<style scoped>

.role-form-subheader {
  padding-left: 20px;
}
.role-section{
  padding: 40px;
}
.valueset-search {
  padding: 40px;
}
.create-permission-button {
  color: #6E0D1C;
  margin-right: 10px;
  letter-spacing: 0;
}
</style>
