<template>
  <v-container>
    <landing-create :content="content"></landing-create>
    <template v-if="specimenState">
      <specimen-state-form
        :isLoading="isLoading"
        :specimenState="specimenState"
        :onSubmit="onSubmit">
      </specimen-state-form>
    </template>
  </v-container>
</template>

<script>
import LandingCreate from '../../../components/layouts/LandingCreate.vue';
import SpecimenStateForm from './SpecimenStateForm.vue';
import services from '../../../services';

const { biobankRegistry } = services;
const { specimenStateService } = biobankRegistry;

export default {
  name: 'SpecimenStateUpdate',
  components: {
    SpecimenStateForm,
    LandingCreate,
  },
  data: () => ({
    isLoading: false,
    content: {
      pageTitle: 'Update Specimen State',
      enableBackButton: true,
    },
    specimenState: null,
  }),
  async created() {
    const { id } = this.$route.params;
    const specimenState = await specimenStateService.getOne({ id });
    this.specimenState = specimenState;
  },
  methods: {
    async onSubmit(dataChange) {
      const data = Object.assign(this.specimenState || {}, dataChange);
      this.isLoading = true;
      try {
        await specimenStateService.update({ specimenState: data });

        this.$snackbar.showOk({ text: 'Successfully updated specimen state!' });

        this.$router.push({ name: 'specimen-state.list' });
      } catch (e) {
        console.error(e);
      }
      this.isLoading = false;
    },
  },
};
</script>
