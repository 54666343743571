<template>
  <v-container>
    <v-app-bar
      class="subheader-app-bar"
      style="margin-top: 49px; z-index: 2"
      app
      color="white"
      dense
      flat
    >
      <go-back-button v-if="content.enableBackButton" style="margin-right: 20px;"></go-back-button>
      <v-toolbar-title class="create-title" >
        <h5>
          {{content.pageTitle}}
        </h5>
      </v-toolbar-title>
      <div v-for="(item, index) in content.commands" :key="index" >
          <v-btn text color="primary" class="create-button">
            <router-link
              class="create-link"
              :to="{name: item.link}"
            >
            <h4>
            <v-icon>{{item.icon}}</v-icon>
            {{item.buttonName}}
            </h4>
            </router-link>
          </v-btn>
      </div>
      <template v-if="content?.exportData?.active">
        <v-list-item>
          <v-btn
            text
            color="primary"
            @click="onClickShowExport()"
            class="ml-auto"
          >
            <h4>
              <v-icon>{{content.exportData?.icon}}</v-icon>
              {{content.exportData?.buttonName}}
            </h4>
          </v-btn>
        </v-list-item>
      </template>
    </v-app-bar>

    <template v-if="content?.exportData?.active">
      <side-bar-export
        :exportData="content.exportData"
        :title="content.exportData.title"
      />
    </template>
  </v-container>
</template>

<script>
import GoBackButton from '../ui/GoBackButton.vue';
import SideBarExport from '../sidebars/SidebarExport.vue';

export default {
  name: 'LandingCreate',
  components: {
    GoBackButton,
    SideBarExport,
  },
  props: {
    content: {
      pageTitle: String,
      commands: [{
        name: String,
        link: String,
        buttonName: String,
      }],
      enableBackButton: Boolean,
      exportData: Object,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    // this is only called if export nav is true above
    onClickShowExport() {
      this.content.exportData.isNavOpen = true;
      this.content.exportData.getListRemotely();
    },
  },
};
</script>
<style scoped>
.subheader-app-bar {
  padding-left: 80px;
  border-bottom: 1px  solid #E0E0E0 !important;;
}
.create-button {
  color: white;
  margin-top: -3px;
  margin-left: 20px;
  border: none;
  background: none;
  padding-top: 20px;
  outline: none;
  letter-spacing: 0;
}
.create-link {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  display: flex;
  vertical-align: middle;
}
.create-title {
  padding-right: 30px;
}
</style>
