<template>
  <div>
    <v-row class="" style="">
      <v-col cols="12" style="background-color: ed">
        <v-card style="padding: 0px 5px;" class="rounded-sm">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="tablemetadata.tableHeaders"
            :items="tablecontent.tableContents.rows"
            item-key="roleId"
            :items-per-page="5"
            :search="search"
          >
            <template v-slot:item.option="{ item }">
              <a href="" class="descendant linkable">
                <div v-for="action in tablemetadata.tableActions" :key="action.icon">
                  <router-link
                    class="create-link"
                    :to="{name: action.route,
                    params: {id: item._id}}"
                  >
                    <v-btn color="#f0ad4e" class="table-action-button">
                      <v-icon text>
                          {{action.icon}}
                      </v-icon>
                      {{action.text}}
                    </v-btn>
                    &nbsp; &nbsp;
                  </router-link>
                </div>
              </a>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'LandingTableAction',
  props: {
    tablemetadata: {
      tableActions: [{
        text: String,
        icon: String,
        route: String,
      }],
      tableHeaders: [{
        text: String,
        align: String,
        sortable: Boolean,
        value: Boolean,
      }],
    },
    tablecontent: {
      tableContents: {
        rows: [{
          text: String,
          align: String,
          sortable: Boolean,
          value: Boolean,
        }],
      },
    },
  },
  data() {
    return {
      search: '',
    };
  },
};
</script>
<style scoped>
.create-action-button {
  /*color: white;*/
  border: none;
  background: none;
  outline: none;
  letter-spacing: 0;
}
.create-link {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  display: flex;
  vertical-align: middle;
}
</style>
